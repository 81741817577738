import React, { FC, useEffect, useCallback, useState, useContext, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios, { CancelTokenSource } from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  ButtonGroup,
  MenuItem,
  useMediaQuery,
  useScrollTrigger,
  Theme,
  IconButton,
  Paper,
  InputBase,
  useTheme,
  Zoom,
  ListItemText,
  List,
  ListItem
} from '@material-ui/core';
import { AddBox, CloseRounded, SearchRounded } from '@material-ui/icons';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { PRODUCT_BASE_URL, CATEGORY_BASE_URL, PRODUCT_SEARCH } from 'constants/url';
import { dummyCategory, dummyPartner, dummyProduct } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRouter from 'hooks/useRouter';
import useDebounce from 'hooks/useDebounce';
import ProductModal from './components/ProductModal';
import { WHITE } from 'constants/colors';
import useRole from 'hooks/useRole';
import { Autocomplete, AutocompleteInputChangeReason, Pagination } from '@material-ui/lab';
import CardItem from './components/CardItem';
import Search from '@material-ui/icons/Search';
import { ArrowUpward } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from 'components/ImageModal';
import TypeUser from 'typings/enum/TypeUser';
import ModalCatalog from './components/ModalCatalog ';
import ROUTE_PATHS from 'constants/routePaths';

const useStyles = makeStyles((theme: Theme) => ({
  containerCenter: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',

      flexDirection: 'column-reverse',
      justifyContent: 'center'
    }
  },
  search: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  containerSugestSearch: {
    position: 'absolute',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    borderRadius: '0 0 0.5em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    top: 50,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: '40vh'
    },
    [theme.breakpoints.up('md')]: {
      width: '98%',
      maxHeight: '30vh'
    }
  },

  textContentMobile: {
    fontSize: '0.875rem'
  },

  CardContainer: {
    margin: '0.6em 0'
  },
  searchingContainer: {
    marginTop: theme.spacing(1),
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',

    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    [theme.breakpoints.up('md')]: {
      flexGrow: 0.97
    }
  },
  sectionProduct: {
    marginTop: theme.spacing(1),
    maxHeight: '100vh',
    overflowX: 'scroll',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  sectionProductMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'center'
  },

  paginationContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  buttonBottom: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      right: 0,
      backgroundColor: WHITE,
      borderTop: '1px solid rgba(0, 0, 0, 0.08)',
      left: 0,
      padding: '0.6em 0'
    }
  },
  rootContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: '-2em'
    }
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  upButton: {
    position: 'fixed',
    zIndex: 1,
    bottom: 60,
    right: 16,
    backgroundColor: WHITE,
    borderRadius: '2rem',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  category: {
    border: 0,
    backgroundColor: '#FFF'
  },
  upButtonArrow: {
    border: '1px solid rgb(0, 0, 0, 0.12)'
  },
  centerFilter: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.3em'
    }
  }
}));

const ProductPage: FC = () => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { history } = useRouter();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openSnackbarProduct, setOpenSnackbarProduct] = useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);
  const [isDownload, setDownload] = useState<boolean>(false);
  const [loadCategory, setLoadCategory] = useState<boolean>(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(false);
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [currentSortProduct, setCurrentSortProduct] = useState<string>('id-desc');
  const [currentSortPackage, setCurrentSortPackage] = useState<string>('id-desc');
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [currentPagePackage, setCurrentPagePackage] = useState<number>(1);
  const [currentPageProduct, setCurrentPageProduct] = useState<number>(1);
  const [currentPageSearch, setCurrentPageSearch] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [sort, setSort] = useState<string>('');
  const [from, setFrom] = useState<number>(0);
  const [categoryDownload, setCategoryDownload] = useState<string>('');
  const [totalProduct, setTotalProduct] = useState<number>(0);
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);
  const [categoriesDownload, setCategoriesDownload] = useState<CategoryModel[]>([]);
  const [category, setCategory] = useState<CategoryModel>();
  const [downloadCatalog, setDownloadCatalog] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [productResource, setProductResource] = useState<ProductModel[]>([]);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [pageNumberInfinity, setPageNumberInfinity] = useState<number>(2);
  const [queryString, setQueryString] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const [searchTemp, setSearchTemp] = useState('');
  const [keyWordResource, setKeyWordResource] = useState<{ productName: string }[]>([]);
  const [isKeyWord, setIsKeyWord] = useState<boolean>(false);
  const [keyWord, setKeyWord] = useState<string>('');
  const isCanCreate = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN]
  });
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const handleCloseDownload = () => {
    setDownloadCatalog(false);
    setDownload(false);
    setCategoryDownload('');
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push(ROUTE_PATHS.SalesOrder.path);
  };
  const handleConfirmSnackbarProduct = (): void => {
    setOpen(false);
    setQueryString('');

    setOpenSnackbar(false);
    setOpenSnackbarProduct(false);
    history.push(ROUTE_PATHS.Product.path);
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };

  const handleDownload = async () => {
    setDownload(true);
    const params = new URLSearchParams();
    const getQueryParams = () => {
      params.append('CategoryId', categoriesDownload.filter(val => val.name === categoryDownload)[0].id.toString());
      return params;
    };

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}/download?${getQueryParams()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'katalog.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setDownload(false);
    }
  };

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 600
  });

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleChangeCategory = (category: CategoryModel) => {
    setCurrentPageSearch(1);
    setCurrentPagePackage(1);
    setCurrentPageProduct(1);
    setCategory(category);
  };

  const handleSnackBarProduct = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbarProduct(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
    setOpenSnackbarProduct(false);
  };

  const handleDispatchProduct = useCallback(async () => {
    setProductLoading(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (search) {
        params.append('keyword', search);
      }

      if (category && category.id > 0) {
        params.append('CategoryId', String(category.id));
      }

      const [orderby, order] = currentSortProduct.split('-');
      params.append('orderBy', orderby);
      params.append('ordered', order);

      if (queryString === '') {
        params.append('page', String(currentPageProduct));
      } else {
        params.append('page', String(currentPageSearch));
      }

      setSort(currentSortProduct);
      return params.toString();
    };
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${getQueryParams()}`);
      setProductResource(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setCurrentPage(data.meta.current_page);
      setTotalProduct(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log(error);
    } finally {
      setProductLoading(false);
    }
  }, [queryString, category, currentPageProduct, currentPagePackage, currentSortProduct, currentSortPackage, currentPageSearch, search]);

  const handleCancelSearch = () => {
    setSearchTemp('');
    setSearch('');
    setKeyWord('');
    setIsKeyWord(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setSearch(searchTemp);
      setIsKeyWord(false);
    }
  };

  const handleSearchProduct = () => {
    setSearch(searchTemp);
    setIsKeyWord(false);
  };

  const debouncedSearchTerm = useDebounce(keyWord, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      dispatchProductSearch();
    }
  }, [debouncedSearchTerm]);

  const dispatchProductSearch = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (keyWord) {
        params.append('keyword', keyWord);
      }

      return params;
    };
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });

      data.data.length > 0 && setIsKeyWord(true);

      setKeyWordResource(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = async () => {
    setPageNumberInfinity(pageNumberInfinity + 1);
    const params = new URLSearchParams();
    if (search) {
      params.append('keyword', search);
    }
    if (category && category.id > 0) {
      params.append('CategoryId', String(category.id));
    }

    const [orderby, order] = currentSortProduct.split('-');
    params.append('orderBy', orderby);
    params.append('ordered', order);
    params.append('page', pageNumberInfinity.toString());
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`);
      setProductResource(prevData => [...prevData, ...data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleDispatchProduct();
  }, [handleDispatchProduct]);

  const handleBackTop = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('product', 'true');
    params.append('keyword', value);
    setLoadCategory(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
      setLoadCategory(false);
    } catch (error) {
      console.log('error :', error);
      setLoadCategory(false);
    }
  };

  const fetchCategory = async () => {
    setIsLoadingCategories(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?product=true`);
      setCategoriesDownload(data.data);
      setIsLoadingCategories(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <Page title={translate('product')}>
      <Container className={classes.rootContainer}>
        <Grid container direction='row' spacing={1}>
          <Grid container direction='row' className={classes.hideMobile}>
            <Typography variant='h1' component='h1'>
              {translate('product')}
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} style={{ margin: showComponent ? '1.2em 0 0 1.2em' : 0 }}>
            <Breadcrumb />
          </Grid>

          <Grid container direction='row' item lg={6} md={6} sm={12} xs={12} spacing={1} justify='flex-end'>
            {isCanCreate && (
              <ButtonGroup variant='contained'>
                <Button color='primary' onClick={handleOpenForm}>
                  <AddBox fontSize='small' /> &nbsp; {translate('addProduct')}
                </Button>
                {/* <Button onClick={() => setDownloadCatalog(true)}>Download PDF</Button> */}
              </ButtonGroup>
            )}
          </Grid>
        </Grid>

        <PaperCustom>
          <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />
          <Grid container direction='row' spacing={1}>
            <Grid xs={12}>
              <Typography variant='h6'>{translate('allProducts')}</Typography>

              <Grid xs={12} container alignItems='center' className={classes.containerCenter}>
                <Grid xs={12} lg={5} xl={5} container>
                  <Grid lg={6} xl={6} xs={6} sm={6} md={6} container className={classes.centerFilter}>
                    <Grid style={{ flex: 0.96 }}>
                      <Autocomplete
                        id='category'
                        fullWidth
                        options={categories}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event: any, value: any) => value && handleChangeCategory(value)}
                        onOpen={() => handleSearchCategory('')}
                        onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                          if (reason === 'clear') {
                            setCategory(dummyCategory);
                            setCategories([dummyCategory]);
                          }
                        }}
                        loading={loadCategory}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label={translate('category')}
                            onChange={e => handleSearchCategory(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Fragment>
                                  {loadCategory && <CircularProgress color='inherit' size={20} />}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              )
                            }}
                            className={classes.category}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid lg={6} xl={6} xs={6} sm={6} md={6} container justify='flex-end' className={classes.centerFilter}>
                    <Grid style={{ flex: 0.96 }}>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: translate('sortBy')
                        }}
                        value={sort}
                        onChange={e => {
                          setCurrentSortProduct(e.target.value as string);
                        }}
                        select
                      >
                        <MenuItem key={1} value='productName-asc'>
                          {translate('sortByAtoZ')}
                        </MenuItem>
                        <MenuItem key={3} value='id-desc'>
                          {translate('sortByLatest')}
                        </MenuItem>
                        <MenuItem key={3} value='restock-desc'>
                          Re-Stock
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid lg={7} xl={7} xs={12} md={12} sm={12} className={classes.search}>
                  <Paper className={classes.searchingContainer}>
                    <InputBase
                      classes={{
                        input: classes.textContentMobile
                      }}
                      style={{ flex: 1 }}
                      placeholder={translate('searchProductName')}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchTemp}
                      onKeyDown={handleKeyDown}
                      onChange={e => {
                        setSearchTemp(e.target.value);
                        setKeyWord(e.target.value);
                      }}
                    />
                    {searchTemp !== '' && (
                      <IconButton type='submit' aria-label='search' onClick={() => handleCancelSearch()}>
                        <CloseRounded fontSize='small' />
                      </IconButton>
                    )}
                    <IconButton type='submit' aria-label='search' onClick={() => handleSearchProduct()} disabled={searchTemp === ''}>
                      <Search fontSize='small' />
                    </IconButton>
                  </Paper>

                  {isKeyWord && keyWordResource.length > 0 && (
                    <Grid className={classes.containerSugestSearch}>
                      <List>
                        {keyWordResource.map((value, key) => (
                          <ListItem
                            button
                            key={key}
                            onClick={() => {
                              handleSearchProduct();
                              setIsKeyWord(false);
                              setSearchTemp(value.productName);
                              setSearch(value.productName);
                            }}
                          >
                            <SearchRounded style={{ paddingRight: '0.3em', color: 'grey' }} fontSize='default' />
                            <ListItemText primary={value.productName} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid xs={12} className={classes.paginationContainer} container justify='space-between'>
                <Typography>
                  {`${translate('showing')} ${totalProduct} ${translate('product')} ${
                    category && category.id > 0 ? `untuk "${category.name}"` : ''
                  }  (${from || '0'} - ${to || '0'} ${translate('from')} ${totalProduct})`}
                </Typography>

                {productResource.length > 0 && (
                  <Pagination
                    count={count}
                    boundaryCount={2}
                    variant='outlined'
                    shape='rounded'
                    onChange={(event, page) => {
                      if (queryString !== '') {
                        setCurrentPageSearch(page);
                      } else {
                        setCurrentPageProduct(page);
                      }
                    }}
                    page={currentPage === -1 ? 1 : currentPage}
                    disabled={productLoading}
                  />
                )}
              </Grid>
              <Grid xs={12} className={classes.sectionProduct} container>
                {productLoading
                  ? [0, 1, 2, 3, 4, 5, 6, 7].map(key => (
                      <Grid xs={6} md={6} sm={6} xl={3} lg={3} className={classes.CardContainer}>
                        <CardItem handleOpenImage={() => console.log('')} productLoading={productLoading} productResource={dummyProduct} />
                      </Grid>
                    ))
                  : productResource.map((val, key) => (
                      <Grid xs={6} md={6} sm={6} xl={3} lg={3} className={classes.CardContainer} key={key}>
                        <CardItem handleOpenImage={handleOpenImage} productLoading={productLoading} productResource={val} />
                      </Grid>
                    ))}
              </Grid>
              {showComponent && (
                <>
                  <Grid id='back-to-top' />
                  <InfiniteScroll
                    dataLength={productResource.length}
                    next={() => fetchMoreData()}
                    hasMore={pageNumberInfinity <= count}
                    loader={
                      <Typography align='center'>
                        <CircularProgress color='primary' size={30} style={{ margin: '2em 0' }} />
                      </Typography>
                    }
                  >
                    <Grid className={classes.sectionProductMobile}>
                      {productLoading
                        ? [0, 1, 2, 3].map(key => (
                            <Grid style={{ width: '43vw' }} className={classes.CardContainer}>
                              <CardItem handleOpenImage={() => console.log('')} productLoading={productLoading} productResource={dummyProduct} />
                            </Grid>
                          ))
                        : productResource.map((val, key: number) => (
                            <Grid style={{ width: '43vw' }} className={classes.CardContainer} key={key}>
                              <CardItem handleOpenImage={handleOpenImage} productLoading={productLoading} productResource={val} />
                            </Grid>
                          ))}
                    </Grid>
                  </InfiniteScroll>
                  <Zoom in={trigger}>
                    <Grid className={classes.upButton}>
                      <IconButton color='primary' aria-label='up icon' component='span' className={classes.upButtonArrow} onClick={handleBackTop}>
                        <ArrowUpward />
                      </IconButton>
                    </Grid>
                  </Zoom>
                </>
              )}
            </Grid>

            <ModalCatalog
              open={downloadCatalog}
              category={categoryDownload}
              categories={categoriesDownload}
              isLoadingCategories={isLoadingCategories}
              handleDownload={handleDownload}
              isLoadingPrint={isDownload}
              handleCloseDownload={handleCloseDownload}
              setCategory={setCategoryDownload}
            />
            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbarProduct}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbarProduct : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbarProduct}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>

        <ProductModal open={open} setOpen={setOpen} handleSnackBar={handleSnackBarProduct} setProducts={setProducts} />
      </Container>
    </Page>
  );
};

export default ProductPage;
