import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import EditItem from 'images/icon/EditItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';

interface Props {
  item: PurchaseOrderItemModel;
  isLoading: boolean;
  index: number;
  currentIndex: number;
  handleUpdateItem: (index: number) => React.MouseEventHandler;
  handleDeleteItem: (index: number) => React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { translate } = useLanguage();
  const { item, index, isLoading, handleUpdateItem, handleDeleteItem } = props;

  return (
    <TableRow key={index}>
      <TableCell>{isLoading ? <Skeleton variant='text' width='100%' /> : item.productName || '-'}</TableCell>
      <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.totalItem + ' ' + item.typeUnit}</TableCell>
      <TableCell align='right'>
        {isLoading ? (
          <Skeleton variant='text' width='100%' />
        ) : (
          <Fragment>
            <Tooltip title='Ubah'>
              <IconButton onClick={handleUpdateItem(index)} size='small'>
                <img src={EditItem} />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('delete')}>
              <IconButton onClick={handleDeleteItem(index)} size='small'>
                <img src={CloseCircleItem} />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
