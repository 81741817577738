import languages from 'constants/languages';
import { useLocalStorage } from 'hooks/useLocalStorage';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { TLanguageKey, TLanguages } from 'typings/Language';

/**
 * The LanguageProviderProps type is an object with a single property called children, which is of type
 * ReactNode.
 * @property {ReactNode} children - ReactNode
 */
type LanguageProviderProps = {
  children: ReactNode;
};

/**
 * LanguageContextProps is an object with a language property that is a key of the TLanguages type,
 * a translate property that is a function that takes a TLanguageKey and returns a string, and a
 * handleChangeLanguage property that is a function that takes a key of the TLanguages type and returns
 * void.
 * @property language - keyof TLanguages;
 * @property translate - (key: TLanguageKey) => string;
 * @property handleChangeLanguage - (language: keyof TLanguages) => void;
 */
type LanguageContextProps = {
  languageCode: keyof TLanguages;
  translate: (key: TLanguageKey) => string;
  handleChangeLanguage: (language: keyof TLanguages) => void;
};

/* Creating a context with an empty object. */
const LanguageContext = createContext({} as LanguageContextProps);
/**
 * It returns the value of the LanguageContext
 */
export const useLanguage = () => useContext(LanguageContext);

/* Exporting the LanguageProvider component. */
export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  /* Setting the language to English. */
  const [languageCode, setLanguageCode] = useLocalStorage<keyof TLanguages>('language', 'en');

  // /**
  //  * It takes a key as an argument, and returns the value of that key in the language object
  //  * @param {TLanguageKey} key - TLanguageKey - This is the key of the language you want to translate.
  //  * @returns The function translate is returning the value of the key that is passed in.
  //  */
  const translate = (key: TLanguageKey) => {
    /* Returning the value of the key that is passed in. */
    const language = languages[key][languageCode];
    /* Returning the value of the key that is passed in. */
    return language;
  };

  /**
   * Const handleChangeLanguage = (language: keyof TLanguages) => {
   *     setLanguage(language);
   *   };
   * @param language - keyof TLanguages
   */
  const handleChangeLanguage = (language: keyof TLanguages) => {
    /* Setting the languageCode to the language that is passed in. */
    setLanguageCode(language);
  };

  /* Returning the value of the LanguageContext. */
  return <LanguageContext.Provider value={{ languageCode, translate, handleChangeLanguage }}>{children}</LanguageContext.Provider>;
};
