import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { dummyProductItem } from 'utils/dummy';

interface Props {
  product: ProductModel;
  isLoading: boolean;
  checked: ProductItemModel[];
  handleIndividualCheck: (item: ProductItemModel) => void;
}

const BodyRowProduct: FC<Props> = props => {
  const { product, isLoading, checked, handleIndividualCheck } = props;
  const { id } = product;

  let isChecked;
  const checkedCheckbox = checked.filter(checkedValue => checkedValue.ProductId === id).length;

  if (checkedCheckbox === 0) {
    isChecked = false;
  } else {
    isChecked = true;
  }

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck({ ...dummyProductItem, ProductId: id });
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            onChange={handleSetId}
          />
        )}
      </TableCellStart>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productCode}</TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productName}</TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.Category ? product.Category.name : '-'}
      </TableCellMiddle>
      <TableCellEnd align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.typeUnit}</TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRowProduct;
