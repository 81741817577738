import React, { FC, useState, useEffect } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Container, Grid, makeStyles, Theme, Typography, CircularProgress, Button, Menu, MenuItem } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb } from 'components';
import { GET_SALES_ORDER_DETAIL_BASE_URL, COMPANY_BASE_URL, SALES_ORDER_BASE_URL, INVOICE_BASE_URL } from 'constants/url';
import { dummySalesOrder, dummyCompany, dummyPartner } from 'utils/dummy';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import Content from './components/content';
import Alert from '@material-ui/lab/Alert';
import ImageModal from 'components/ImageModal';
import ModalSuccess from './components/Modal/ModalSuccess';
import { Create, DeleteForever, Description, ExpandMore } from '@material-ui/icons';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import ConvetConfirm from './components/Modal/ConvetConfirm';
import ROUTE_PATHS from 'constants/routePaths';
const useStyles = makeStyles((theme: Theme) => ({
  containerAction: {
    gap: '0 0.5em'
  },

  containerAaction: {
    marginTop: theme.spacing(6)
  }
}));

const SalesOrderDetailPage: FC = () => {
  const { match, history } = useRouter();
  const params = match.params.id;
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const [salesOrder, setSalesOrder] = useState<SalesOrderModel>(dummySalesOrder);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [salesOrderItem, setSalesOrderItem] = useState<SalesOrderItemModel[]>([]);
  const [invoiceId, setInvoceId] = useState<number>(0);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [isAction, setIsAction] = useState<null | HTMLElement>(null);
  const [isDeny, setDeny] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [loadingConvetSuccess, setLoadingConvetSuccess] = useState<boolean>(false);
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [convetConfirm, setConvetConfirm] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(GET_SALES_ORDER_DETAIL_BASE_URL(Number(params)));

      setSalesOrder(data.data);
      setSalesOrderItem(data.data.SalesOrderItem);

      setHasInvoice(data.data.statusOrder === 'CONFIRMATION');
      setIsLoadingData(false);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleClickAction = (event: React.MouseEvent<HTMLButtonElement>) => setIsAction(event.currentTarget);

  const handleCloseAction = () => setIsAction(null);

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleCloseSnackbar = () => {
    history.push(ROUTE_PATHS.SalesOrder.path);
  };

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
    if (invoiceId > 0) {
      window.open(`${ROUTE_PATHS.SalesOrderInvoice.path}/${invoiceId}`, '_blank');
      setInvoceId(0);
    }

    if (isDelete) {
      setDelete(false);
      history.push(ROUTE_PATHS.SalesOrder.path);
    }
  };

  useEffect(() => {
    const partner: PartnerModel = salesOrder.Partner || dummyPartner;
    const totalBill = partner.totalBill && partner.totalBill > 0 ? salesOrder.totalPrice + partner.totalBill : 0;
    const plafon = partner.plafon || 0;

    if (totalBill > plafon && plafon > 0) {
      setPartnerMessage('Customer ini memiliki jumlah total tagihan belum terbayarkan.');
      setDeny(true);
    }
  }, [salesOrder]);

  useEffect(() => {
    fetchData();
    fetchCompany();
  }, []);

  const handleDeleteSalesOrder = async () => {
    handleCloseAction();
    setLoading(true);
    try {
      await axios.delete(`${SALES_ORDER_BASE_URL}/${Number(params)}`);
      setLoading(false);
      history.push(ROUTE_PATHS.SalesOrder.path);
    } catch (error) {
      console.log(console.error);
      setConvetConfirm(false);
      setLoading(false);
      const err = error as { data: any };
      handleSnackBar(true, 'error', `${err.data && err.data.message}`);
    }
  };
  const handleUpdateSalesOrder = () => history.push(`${ROUTE_PATHS.SalesOrder.path}${ROUTE_PATHS.Add.path}`, { id: params });

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConvetSalesOrder = async () => {
    handleCloseAction();
    setLoading(true);
    const getItems = salesOrderItem.map((value: SalesOrderItemModel) => ({
      id: 0,
      SalesOrderItemId: value.id,
      ProductId: value.ProductId,
      typeUnit: value.typeUnit,
      discountUnit: 0,
      upPrice: false,
      totalItem: value.totalItem,
      price: value.price
    }));
    try {
      const { data } = await axios.post(INVOICE_BASE_URL, {
        SalesOrderId: +params,
        items: getItems,
        wareHouse: 'VISONSHOP',
        shippingFee: salesOrder.shippingFee,
        subTotal: salesOrder.subTotal,
        deduction: salesOrder.deduction,
        totalPrice: salesOrder.totalPrice,
        totalDiscount: salesOrder.totalDiscount
      });
      setConvetConfirm(false);
      setLoading(false);
      setLoadingConvetSuccess(true);
    } catch (error) {
      setConvetConfirm(false);
      setLoading(false);
      const err = error as { data: any };
      handleSnackBar(true, 'error', `${err.data && err.data.message}`);
    }
  };

  return (
    <Page title='Sales Order'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> {translate('selling')} </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} container justify='space-between'>
            <Breadcrumb />
            <Grid item>
              <Button
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClickAction}
                endIcon={<ExpandMore />}
                disabled={
                  loading ||
                  salesOrder.statusOrder.includes(SalesOrderStatus.HAS_INVOICE) ||
                  salesOrder.statusOrder.includes(SalesOrderStatus.CONFIRM)
                }
              >
                {loading ? <CircularProgress size={20} color='primary' /> : translate('chooseAction')}
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={isAction}
                className={classes.containerAaction}
                keepMounted
                open={Boolean(isAction)}
                onClose={handleCloseAction}
              >
                <MenuItem onClick={() => setConvetConfirm(true)} className={classes.containerAction}>
                  <Description /> Convert INV & SJ
                </MenuItem>

                <MenuItem onClick={handleUpdateSalesOrder} className={classes.containerAction}>
                  <Create /> {translate('editSalesOrders')}
                </MenuItem>

                <MenuItem onClick={handleDeleteSalesOrder} className={classes.containerAction}>
                  <DeleteForever />
                  {translate('deleteSalesOrders')}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row' justify='space-between'>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            {isDeny && !hasInvoice && (
              <Alert variant='filled' severity='warning'>
                {partnerMessage}
              </Alert>
            )}
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={2} justify='space-between'>
          <Content
            salesOrder={salesOrder}
            salesOrderItem={salesOrderItem}
            company={company}
            handleOpenImage={handleOpenImage}
            isLoadingData={isLoadingData}
          />
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <ModalSuccess open={loadingConvetSuccess} />
        <ConvetConfirm open={convetConfirm} isLoading={loading} handleClose={() => setConvetConfirm(false)} handleSubmit={handleConvetSalesOrder} />
        <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />
      </Container>
    </Page>
  );
};

export default SalesOrderDetailPage;
