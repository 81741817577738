import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { makeStyles, Table, TableBody, TableRow, withStyles, Typography, Divider, Grid, Link } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import ROUTE_PATHS from 'constants/routePaths';

interface Props {
  product: ProductModel;
  isLoading: boolean;
}

const useStyles = makeStyles({
  cell: {
    width: '40%',
    paddingLeft: 0
  },
  label: {
    color: '#828282',
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 4
  },
  text: {
    fontWeight: 700,
    color: '#000000',
    fontSize: 15
  },
  price: {
    fontWeight: 700,
    color: '#F2994A',
    fontSize: 15
  },
  tableDetail: {
    marginTop: 10
  },
  titleDetail: {
    marginTop: 20
  }
});

const TableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);

const ProductDetail: FC<Props> = props => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { product, isLoading } = props;
  const { history } = useRouter();

  const handleLink = (id: number) => {
    history.push(`${ROUTE_PATHS.Product.path}/${id}`);
  };

  return (
    <Fragment>
      <Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('productName')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.productName}
              </Typography>
            </TableCell>

            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                {translate('unit')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.typeUnit}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('productCode')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.productCode}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                {translate('productCategory')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {product.isProductPackage && 'Paket'}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell size='small' colSpan={2} className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('description')}
              </Typography>

              {isLoading ? (
                <Skeleton variant='text' width='100%' />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: product.description }} className={classes.text}></div>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Divider />

      <Grid container spacing={1}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography component='p' variant='h5' className={classes.titleDetail}>
            Details Harga
          </Typography>

          <Table size='small' className={classes.tableDetail}>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton variant='text' width='100%' />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell size='small' className={classes.cell}>
                    <Typography component='p' className={classes.label}>
                      {product.ProductPackage && product.ProductPackage.Zone && product.ProductPackage.Zone.name}
                    </Typography>
                    <Typography component='p' className={classes.price}>
                      {isLoading ? (
                        <Skeleton variant='text' width='100%' />
                      ) : (
                        <NumberFormat
                          value={product.ProductPackage && product.ProductPackage.totalPrice}
                          prefix={'Rp'}
                          thousandSeparator={true}
                          displayType='text'
                        />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>

        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Typography component='p' variant='h5' className={classes.titleDetail}>
            Keterangan Item
          </Typography>

          <Table size='small' className={classes.tableDetail}>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton variant='text' width='100%' />
                  </TableCell>
                </TableRow>
              ) : (
                product.ProductPackage &&
                product.ProductPackage.ProductItem &&
                product.ProductPackage.ProductItem.map((value, index) => (
                  <TableRow key={index}>
                    <TableCell size='small' className={classes.cell}>
                      {isLoading ? (
                        <Skeleton variant='text' width='100%' />
                      ) : (
                        <Link href='#' onClick={() => handleLink(value.ProductId)}>
                          {`${value.Product && value.Product.productName} (${value.Product && value.Product.productCode})`}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell size='small' align={'right'} className={classes.cell}>
                      {isLoading ? (
                        <Skeleton variant='text' width='100%' />
                      ) : (
                        `${value.minimumItem || 0}  ${value.bonusItem! > 0 ? '(+' + value.bonusItem + ')' : ''}  ${value.Product &&
                          value.Product.typeUnit}`
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProductDetail;
