import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { createStyles, Theme, Typography, IconButton, WithStyles, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import CreateWareHouseForm from './components/CreateWareHouseForm';

interface Props {
  wareHouses: WareHouseModel[];
  wareHouse: WareHouseModel | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWareHouses: React.Dispatch<React.SetStateAction<WareHouseModel[]>>;
  setWareHouse: React.Dispatch<React.SetStateAction<WareHouseModel | null>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CreateWareHouse: FC<Props> = props => {
  const { translate } = useLanguage();
  const { wareHouses, open, setOpen, wareHouse, setWareHouses, setWareHouse, handleSnackBar } = props;

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle id='customized-dialog-title'>
        <Typography id='modal-title' variant='h1' component='h1'>
          {wareHouse ? translate('updateWarehouse') : translate('addWarehouse')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CreateWareHouseForm
          wareHouse={wareHouse}
          wareHouses={wareHouses}
          setWareHouses={setWareHouses}
          setWareHouse={setWareHouse}
          setOpen={setOpen}
          handleSnackBar={handleSnackBar}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateWareHouse;
