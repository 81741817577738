import { Button, CircularProgress, Container, Divider, Grid, LinearProgress, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Breadcrumb, Page, PaperCustom } from 'components';
import FlexBox from 'components/FlexBox';
import InfoModal from './components/InfoModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { dummyCourierSalary } from 'utils/dummy';
import ContentHistory from './components/ContentHistory';
import axios from 'axios';
import { COURIER_BASE_URL, COURIER_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import useRouter from 'hooks/useRouter';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { EventRounded } from '@material-ui/icons';
import clsx from 'clsx';
import ROUTE_PATHS from 'constants/routePaths';
const useStyles = makeStyles((theme: Theme) => ({
  gap: {
    gap: '1em'
  },
  containerInput: {
    flex: '.49'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
}));

export interface Tresource<T> {
  isLoading: boolean;
  data: T[];
}
export interface TcourierSalary {
  isOpen: boolean;
  isLoadingFetch: boolean;
  isLoadingSubmit: boolean;
  isSuccess: boolean;
  data: CourierSalary;
}

const CourierSalaryCalculation = () => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { history } = useRouter();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [courierResource, setCourierResource] = useState<Tresource<{ id: number; firstName: string }>>({
    isLoading: false,
    data: []
  });
  const [deliveryResource, setDeliveryResource] = useState<Tresource<DeliveryOrders>>({
    isLoading: false,
    data: []
  });
  const [courierSalary, setCourierSalary] = useState<TcourierSalary>({
    isOpen: false,
    isLoadingFetch: false,
    isLoadingSubmit: false,
    isSuccess: false,
    data: { ...dummyCourierSalary, startDate: startOfMonth(new Date()) }
  });

  const validation = courierSalary.data.idUser === 0 || deliveryResource.data.length < 1;

  const fetchCourirResource = useCallback(async () => {
    setCourierResource(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(`${COURIER_URL}`);
      setCourierResource(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(console.log(error));
    }
  }, []);

  useEffect(() => {
    fetchCourirResource();
  }, [fetchCourirResource]);

  const handleSubmit = async (action: 'submit' | 'cancel') => {
    if (action.includes('submit')) {
      setCourierSalary(prev => ({
        ...prev,
        isLoadingSubmit: true
      }));

      const dataFinal = {
        ...courierSalary.data,
        startDate: format(courierSalary.data.startDate, 'yyyy-MM-dd'),
        endDate: format(courierSalary.data.endDate, 'yyyy-MM-dd')
      };
      try {
        axios.post(COURIER_BASE_URL, dataFinal);
        history.push(ROUTE_PATHS.CourierCommission.path);
      } catch (error) {
        console.log(error);
      }
    } else {
      setCourierSalary(prev => ({ ...prev, isLoading: false, isOpen: false }));
    }
  };

  const fetchDelivery = useCallback(async () => {
    setDeliveryResource(prev => ({
      ...prev,
      isLoading: true
    }));
    const params = new URLSearchParams();
    params.append('partner', courierSalary.data.idUser.toString());
    params.append('startDate', format(courierSalary.data.startDate, 'yyyy-MM-dd'));
    params.append('endDate', format(courierSalary.data.endDate, 'yyyy-MM-dd'));
    try {
      const { data } = await axios.get(`${COURIER_BASE_URL}/courier?${params}`, {
        cancelToken
      });
      setDeliveryResource(prev => ({
        ...prev,
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  }, [courierSalary.data.idUser, courierSalary.data.startDate, courierSalary.data.endDate]);

  useEffect(() => {
    if (courierSalary.data.idUser === 0) return;
    fetchDelivery();
    return () => source.cancel('Cancel Unmounted');
  }, [fetchDelivery]);

  const fetchCalculateSalary = async () => {
    setCourierSalary(prev => ({
      ...prev,
      isLoadingFetch: true
    }));

    const params = new URLSearchParams();
    params.append('partner', courierSalary.data.idUser.toString());
    params.append('startDate', format(courierSalary.data.startDate, 'yyyy-MM-dd'));
    params.append('endDate', format(courierSalary.data.endDate, 'yyyy-MM-dd'));
    try {
      const { data } = await axios.get(`${COURIER_BASE_URL}/calculation?${params}`, {
        cancelToken
      });

      setCourierSalary(prev => ({
        ...prev,
        isOpen: true,
        isLoadingFetch: false,
        data: { ...prev.data, totalDelivery: data.data.totalDelivery, totalNominal: data.data.totalNominal }
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title={translate('courierCommission')}>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            {translate('courierCommission')}
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid container className={classes.gap} xs={12}>
            <Grid xs={12} container justify='space-between' alignItems='center'>
              <Grid className={classes.containerInput}>
                <Autocomplete
                  loading={courierResource.isLoading}
                  loadingText={<LinearProgress />}
                  disableListWrap
                  options={courierResource.data}
                  getOptionLabel={option => option.firstName}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setCourierSalary(prev => ({
                        ...prev,
                        ['data']: { ...prev.data, idUser: value.id, name: value.firstName }
                      }));
                    }
                  }}
                  PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
                  renderInput={params => <TextField {...params} label={translate('selectCourier')} variant='outlined' required fullWidth />}
                />
              </Grid>
              <Grid className={clsx(classes.containerInput, classes.gap)} container alignItems='center' justify='flex-end'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format='dd/MM/yyyy'
                    margin='normal'
                    label='Tanggal Mulai'
                    keyboardIcon={<EventRounded fontSize='small' />}
                    value={courierSalary.data.startDate}
                    onChange={date => {
                      if (date) {
                        setCourierSalary(prev => ({
                          ...prev,
                          data: { ...prev.data, startDate: date }
                        }));
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <KeyboardDatePicker
                    format='dd/MM/yyyy'
                    margin='normal'
                    label='Tanggal Akhir'
                    keyboardIcon={<EventRounded fontSize='small' />}
                    value={courierSalary.data.endDate}
                    onChange={date => {
                      if (date) {
                        setCourierSalary(prev => ({
                          ...prev,
                          data: { ...prev.data, endDate: date }
                        }));
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <ContentHistory deliveryResource={deliveryResource} courierSalary={courierSalary} />

            <FlexBox columnGap={1} xs={12} container justify='flex-end'>
              <Button variant='outlined' color='primary' onClick={() => history.push(ROUTE_PATHS.CourierCommission.path)}>
                Batalkan
              </Button>
              <Button variant='contained' color='primary' onClick={fetchCalculateSalary} disabled={courierSalary.isLoadingFetch || validation}>
                {courierSalary.isLoadingFetch ? <CircularProgress color='primary' size={20} /> : translate('commissionCalculation')}
              </Button>
            </FlexBox>
          </Grid>
          <InfoModal courierSalary={courierSalary} handleSubmit={handleSubmit} />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CourierSalaryCalculation;
