import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import clx from 'clsx';
import { Grid, makeStyles, Theme, Typography, Table, TableRow, TableCell, TableBody, Hidden, Divider } from '@material-ui/core';
import { format } from 'date-fns';
import Skeleton from '@material-ui/lab/Skeleton';
import Logo from 'images/logo-blue.png';
import { BLACK, YELLOW, WHITE, PRIMARY, RED } from 'constants/colors';
import clsx from 'clsx';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';

interface Props {
  salesOrder: SalesOrderModel;
  isLoadingData: boolean;
  company: CompanyModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',

    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },

  gridContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 10 + 'px'
    }
  },
  logo: {
    objectFit: 'cover',
    height: '8em'
  },
  statusOrder: {
    padding: '.1em 1em',
    color: WHITE,
    marginBottom: '1em',
    borderRadius: '1em'
  },
  statusOrderPending: {
    background: YELLOW
  },
  statusOrderConfirmation: {
    background: PRIMARY
  },
  statusOrderReject: {
    background: RED
  }
}));

const SalesOrderInfo: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { salesOrder, isLoadingData, company } = props;
  return (
    <Fragment>
      <Hidden smDown xsDown>
        <Grid item lg={8} sm={8} md={8} xs={8}>
          <img src={Logo} alt='company' className={classes.logo} />
        </Grid>
      </Hidden>

      <Grid container item lg={4} sm={12} md={4} xs={12} justify='flex-end' className={classes.gridContainer}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align='right' colSpan={2} className={classes.cellOrder}>
                <Typography variant='h1' className={classes.titleOrder}>
                  {translate('salesOrders')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                {<Typography variant='subtitle2'>{translate('salesOrdersID')}</Typography>}
              </TableCell>
              <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                {isLoadingData ? (
                  <Skeleton variant='text' width='100%' />
                ) : company.id === 0 ? (
                  <Skeleton variant='text' width='100%' />
                ) : (
                  salesOrder.orderNumber || '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                {<Typography variant='subtitle2'>Tanggal Order</Typography>}
              </TableCell>
              <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                {isLoadingData ? (
                  <Skeleton variant='text' width='100%' />
                ) : company.id === 0 ? (
                  <Skeleton variant='text' width='100%' />
                ) : salesOrder.orderDate ? (
                  format(new Date(salesOrder.orderDate), 'dd-MM-yyyy')
                ) : '' ? (
                  <Skeleton variant='text' width='100%' />
                ) : (
                  salesOrder.orderNumber || '-'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid xs={12}>
        <Grid xs={12} container justify='flex-end'>
          {!isLoadingData && (
            <Typography
              variant='h6'
              className={clsx(
                classes.statusOrder,
                salesOrder.statusOrder.includes(SalesOrderStatus.PENDING) && classes.statusOrderPending,
                (salesOrder.statusOrder.includes(SalesOrderStatus.HAS_INVOICE) || salesOrder.statusOrder.includes(SalesOrderStatus.CONFIRM)) &&
                  classes.statusOrderConfirmation,
                salesOrder.statusOrder.includes(SalesOrderStatus.REJECT) && classes.statusOrderReject
              )}
            >
              {salesOrder.statusOrder}
            </Typography>
          )}
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SalesOrderInfo;
