import React, { FC, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios from 'axios';
import { Button, CircularProgress, Grid, IconButton, makeStyles, Modal, Paper, Theme, Typography } from '@material-ui/core';
import { PARTNER_BASE_URL } from 'constants/url';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean;
  file?: File;
  handleCancel: () => void;
  setPartners: React.Dispatch<React.SetStateAction<PartnerModel[]>>;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4,
    width: 400
  },
  headerFlex: {
    display: 'flex'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    color: '#FFFFFF'
  },
  closeButton: {
    marginRight: theme.spacing(-1)
  },
  icon: {
    fontSize: 20
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const MemberUploadModal: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { open, handleCancel, setPartners, setFile, file, handleSnackBar, setCount } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (file) {
      setLoading(true);

      const form = new FormData();
      form.append('file', file, file.name);

      try {
        const { data } = await axios.post(`${PARTNER_BASE_URL}/import`, form, { headers: { 'Content-Type': 'multipart/form-data' } });
        setPartners(data.data);
        setCount(data.meta.last_page);
        setFile(undefined);
        handleCancel();
        handleSnackBar(true, 'success', 'Mitra Berhasil diunggah');
      } catch (err) {
        console.log(err);
        handleSnackBar(true, 'error', 'Mitra gagal diunggah');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container direction='row' justify='flex-start' alignItems='center'>
              <div className={classes.headerFlex}>
                <div className={classes.otherTextCell}>
                  <Typography variant='h5' color='primary'>
                    Unggah Mitra
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='row' justify='flex-end' alignItems='center'>
              <IconButton size='small' onClick={handleCancel} className={classes.closeButton}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <form noValidate onSubmit={handleOnSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <input
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                id='raised-button-file'
                type='file'
                onChange={e => {
                  if (e.target.files) {
                    setFile(e.target.files[0]);
                  }
                }}
              />
            </Grid>
            <Grid container item justify='flex-end'>
              <Button color='secondary' className={classes.cancelButton} onClick={handleCancel} disabled={isLoading}>
                {translate('cancelled')}
              </Button>
              <Button type='submit' color='primary' disabled={isLoading}>
                Unggah
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
};

export default MemberUploadModal;
