import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { GREEN } from 'constants/colors';
import useRouter from 'hooks/useRouter';
import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';

interface Props {
  open: boolean;
  message: string;
  handleClick: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const ModalSuccess: FC<Props> = ({ open, message, handleClick }) => {
  const classes = useStyles();
  const { translate } = useLanguage();

  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <CheckCircle fontSize='large' style={{ color: GREEN, fontSize: '3.5rem' }} />
          <Typography className={classes.titleContainer}>{translate('success')}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Typography align='center'>{message}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleClick}>
              {translate('close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSuccess;
