import { Dialog, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CancelOutlined } from '@material-ui/icons';
import { WHITE } from 'constants/colors';
import React, { FC } from 'react';
interface Props {
  open: boolean;
  handleClose: () => void;
  imageUrl: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative'
  },
  top: {
    background: 'rgba(0, 0, 0, 0.5)',
    padding: '1.2em 1em',
    position: 'absolute',
    zIndex: 1
  },
  closeIcon: {
    color: WHITE,
    cursor: 'pointer'
  },
  noImage: {
    backgroundColor: '#e0e0e0',
    width: '70vw',
    minHeight: '50vh',
    display: 'grid',
    placeItems: 'center'
  }
}));
const ImageModal: FC<Props> = ({ open, handleClose, imageUrl }) => {
  const classes = useStyles();

  return (
    <Dialog aria-labelledby='image' open={open}>
      <Grid xs={12} container justify='flex-end' className={classes.top}>
        <CancelOutlined className={classes.closeIcon} fontSize='small' onClick={() => handleClose()} />
      </Grid>

      {!imageUrl && (
        <Grid xs={12} className={classes.noImage}>
          <Typography variant='h6' style={{ color: '#bdbdbd' }}>
            No Image
          </Typography>
        </Grid>
      )}
      {imageUrl && <img src={imageUrl} alt='Product Image' loading='lazy' />}
    </Dialog>
  );
};

export default ImageModal;
