import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableRow, IconButton, Button, Typography, makeStyles, Tooltip, Table, TableBody, TableCell, TableHead } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCollapse } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import CheckMarkItem from 'images/icon/CheckMarkItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import DontIcon from 'images/icon/DontIcon.svg';
import { RED, WHITE, GREEN, ORANGE, BLACK } from 'constants/colors';
import ItemStatus from 'typings/enum/ItemStatus';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clx from 'clsx';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ROUTE_PATHS from 'constants/routePaths';

interface Props {
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel;
  isLoading: boolean;
  handleConfirm: React.MouseEventHandler;
  handleReject: React.MouseEventHandler;
  tab: number;
  index: number;
  hasInvoice: boolean;
  openCollapse: boolean;
  openTooltip: boolean;
  indexCollapse: number;
  indexTooltip: number;
  isConvertAllowed: boolean;
  currentWareHouse: string;
  handleOpenCollapse: React.MouseEventHandler;
  handleOpenTooltip: React.MouseEventHandler;
  handleCloseTooltip: () => void;

  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: React.MouseEventHandler;
  handleCloseAlertPrice: () => void;

  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles({
  confirm: {
    background: WHITE,
    color: GREEN
  },
  reject: {
    background: WHITE,
    color: RED
  },
  discount: {
    background: WHITE,
    color: ORANGE
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  },
  table: {
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: '12pt',
    border: '1px solid #dadde9'
  },
  iconPrice: {
    verticalAlign: 'middle',
    marginLeft: 8
  },
  highlight: {
    boxShadow: '0px 2px 4px rgba(0, 136, 186, 0.4)',
    borderRadius: 4
  },
  done: {
    backgroundColor: '#e0e0e0'
  },
  productImage: {
    width: '100%',
    height: '100%'
  },
  defaultImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: '#fff'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const {
    salesOrderItem,
    isLoading,
    index,
    isConvertAllowed,
    handleReject,
    handleConfirm,
    handleOpenCollapse,
    handleOpenTooltip,
    handleCloseTooltip,
    openCollapse,
    indexCollapse,
    indexTooltip,
    openTooltip,
    tab,
    salesOrder,
    currentWareHouse,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount
  } = props;

  const warehouse = salesOrderItem.warehouse || 'NO_WAREHOUSE';
  const currentPrice = salesOrderItem.price;
  const productPrice =
    salesOrderItem.Product &&
    salesOrderItem.Product.ProductPrice &&
    salesOrderItem.Product.ProductPrice.find(value => value.ZoneId === salesOrder.Partner!.Zone!.id);
  const totalProductPrice = productPrice ? productPrice.price * salesOrderItem.totalItem : 0;

  const handleInvoice = (id: number) => {
    window.open(`${ROUTE_PATHS.SalesOrderInvoice.path}/${id}`, '_blank');
  };

  const handleAction = () => {
    if (tab === 0) {
      if (salesOrderItem.status === ItemStatus.PENDING && isConvertAllowed) {
        return (
          <Fragment>
            <Tooltip title={translate('confirm')}>
              <IconButton disabled={currentWareHouse !== '' && warehouse !== currentWareHouse} size='small' onClick={handleConfirm}>
                <img src={currentWareHouse !== '' && warehouse !== currentWareHouse ? DontIcon : CheckMarkItem} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Tolak'>
              <IconButton disabled={currentWareHouse !== '' && warehouse !== currentWareHouse} size='small' onClick={handleReject}>
                <img src={currentWareHouse !== '' && warehouse !== currentWareHouse ? DontIcon : CloseCircleItem} />
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      } else {
        if (salesOrderItem.status === ItemStatus.CONFIRM) {
          return <Typography variant='body2'>Disetujui</Typography>;
        } else if (salesOrderItem.status === ItemStatus.REJECT) {
          return <Typography variant='body2'>Ditolak</Typography>;
        } else if (salesOrderItem.status === ItemStatus.HAS_INVOICE) {
          return (
            <Typography variant='body2' style={{ color: BLACK }}>
              <Button variant='text' onClick={() => handleInvoice((salesOrderItem.invoice && salesOrderItem.invoice.id) || 0)}>
                Lihat Invoice
              </Button>
            </Typography>
          );
        } else {
          return <Typography variant='body2'>Menunggu</Typography>;
        }
      }
    } else if (tab === 1 && isConvertAllowed) {
      return (
        salesOrder.statusOrder !== 'CONFIRMATION' && (
          <Button
            disabled={currentWareHouse !== '' && warehouse !== currentWareHouse}
            variant='text'
            size='small'
            onClick={handleReject}
            className={classes.reject}
          >
            Tolak Barang
          </Button>
        )
      );
    } else {
      return (
        isConvertAllowed &&
        salesOrder.statusOrder !== 'CONFIRMATION' && (
          <Button
            disabled={currentWareHouse !== '' && warehouse !== currentWareHouse}
            variant='text'
            size='small'
            onClick={handleConfirm}
            className={classes.confirm}
          >
            Setujui Barang
          </Button>
        )
      );
    }
  };

  return (
    <Fragment>
      <TableRow
        className={clx(
          currentWareHouse !== '' && warehouse === currentWareHouse && classes.highlight,
          salesOrderItem.status === ItemStatus.HAS_INVOICE && classes.done
        )}
      >
        <TableCellStart style={{ minWidth: 20 }}>
          <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
            {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCellStart>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              open={openTooltip && indexTooltip == index}
              onClose={handleCloseTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <>
                  {isLoading ? (
                    <Skeleton variant='rect' width={'100%'} height={196} />
                  ) : salesOrderItem.Product && salesOrderItem.Product.isProductPackage ? (
                    salesOrderItem.Product && salesOrderItem.Product.ProductImage && salesOrderItem.Product.ProductImage.url ? (
                      <img src={salesOrderItem.Product && salesOrderItem.Product.ProductImage.url} className={classes.productImage} />
                    ) : (
                      <div className={classes.defaultImage}>
                        <div>
                          <h3 style={{ textAlign: 'center' }}>{salesOrderItem.Product && salesOrderItem.Product.ProductPackage!.name}</h3>
                          {salesOrderItem.Product &&
                            salesOrderItem.Product.ProductPackage &&
                            salesOrderItem.Product.ProductPackage.ProductItem &&
                            salesOrderItem.Product.ProductPackage.ProductItem.map((value, index) => (
                              <div key={index}>
                                <p style={{ textAlign: 'center' }}>
                                  {value.Product ? `${value.Product.productName} ${value.minimumItem} ${value.Product.typeUnit}` : '-'}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                  ) : salesOrderItem.Product && salesOrderItem.Product.ProductImage && salesOrderItem.Product.ProductImage.url ? (
                    <img src={salesOrderItem.Product && salesOrderItem.Product.ProductImage.url} className={classes.productImage} />
                  ) : (
                    <div className={classes.defaultImage}>
                      <div>
                        <h3 style={{ textAlign: 'center' }}>{salesOrderItem.Product && salesOrderItem.Product.productName}</h3>
                      </div>
                    </div>
                  )}
                </>
              }
            >
              <Button variant='text' onClick={handleOpenTooltip}>
                {salesOrderItem.Product ? salesOrderItem.Product.productName : ''}
              </Button>
            </Tooltip>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <>
              <NumberFormat value={salesOrderItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              {isConvertAllowed && productPrice && currentPrice !== productPrice.price && (
                <Tooltip
                  open={openAlertPrice && indexAlertPrice == index}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement='bottom'
                  title={
                    <>
                      <Table size='small' className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>Harga Jual Rute</TableCell>
                            <TableCell>
                              <NumberFormat value={productPrice ? productPrice.price : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Harga Ditawarkan</TableCell>
                            <TableCell>
                              <NumberFormat value={salesOrderItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Ket</TableCell>
                            <TableCell>
                              {productPrice && currentPrice < productPrice.price && 'Harga yang ditawarkan dibawah dari harga jual rute saat ini.'}
                              {productPrice && currentPrice > productPrice.price && 'Harga yang ditawarkan diatas dari harga jual rute saat ini.'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  }
                >
                  <IconButton onClick={handleOpenAlertPrice} size='small'>
                    <ErrorOutlineIcon fontSize='small' color='error' className={classes.iconPrice} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrderItem.totalItem + ' ' + salesOrderItem.typeUnit}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrderItem.warehouse && salesOrderItem.warehouse}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat
              value={salesOrderItem.discount}
              prefix={'Rp'}
              thousandSeparator={true}
              displayType='text'
              className={clx(salesOrderItem.discount > 0 && classes.discount)}
            />
          )}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <>
              <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              {isConvertAllowed && totalProductPrice !== salesOrderItem.totalPrice && (
                <Tooltip
                  open={openAlertDiscount && indexAlertDiscount == index}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement='bottom'
                  title={
                    <>
                      <Table size='small' className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography>Total Harga Jual Rute</Typography>
                              <NumberFormat value={totalProductPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography>{translate('totalPrice')}</Typography>
                              <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              {totalProductPrice > salesOrderItem.totalPrice &&
                                'Total Harga yang ditawarkan dibawah dari Total harga jual rute saat ini.'}
                              {totalProductPrice < salesOrderItem.totalPrice &&
                                'Total Harga yang ditawarkan diatas dari Total harga jual rute saat ini.'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  }
                >
                  <IconButton onClick={handleOpenAlertDiscount} size='small'>
                    <ErrorOutlineIcon fontSize='small' color='error' className={classes.iconPrice} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </TableCellMiddle>

        <TableCellEnd align='center'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : handleAction()}</TableCellEnd>
      </TableRow>

      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={8}>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>Total Stok</TableCell>
              <TableCell colSpan={6} align='right'>
                {salesOrderItem.Product ? salesOrderItem.Product.totalStock + ' ' + salesOrderItem.Product.typeUnit : '-'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>Total Stok Tertahan</TableCell>
              <TableCell colSpan={6} align='right'>
                {salesOrderItem.Product ? (salesOrderItem.Product.holdItem || 0) + ' ' + salesOrderItem.Product.typeUnit : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {salesOrderItem.Product && salesOrderItem.Product.isProductPackage && (
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Nama</TableCell>
                <TableCell align='center'>{translate('quantity')}</TableCell>
                <TableCell align='center'>Bonus Kuantitas</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {salesOrderItem.Product &&
                salesOrderItem.Product.ProductPackage &&
                salesOrderItem.Product.ProductPackage.ProductItem &&
                salesOrderItem.Product.ProductPackage.ProductItem.length > 0 &&
                salesOrderItem.Product.ProductPackage.ProductItem.map((value, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cellCollapse}>{value.Product ? value.Product.productName : '-'}</TableCell>
                    <TableCell align='center' className={classes.cellCollapse}>{`${value.minimumItem || 0}/${
                      value.Product ? value.Product.typeUnit : ''
                    }`}</TableCell>
                    <TableCell align='center' className={classes.cellCollapse}>
                      {`${value.bonusItem || 0}/${value.Product ? value.Product.typeUnit : ''}`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
