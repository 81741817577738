import { Grid, makeStyles, Typography } from '@material-ui/core';
import { BLUE, GREY, PRIMARY_SECONDARY } from 'constants/colors';
import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';
import ROUTE_PATHS from 'constants/routePaths';

interface IItems {
  id: number;
  path: string;
  title: string;
  description: string;
  iconList: React.ReactNode;
}
interface Props {
  items: IItems[];
}

const useStyles = makeStyles({
  container: {
    columnGap: '1em'
  },
  wrapper: {
    boxShadow: '0px 4px 24px rgba(50, 50, 50, 0.16)',
    borderRadius: '5px',
    padding: '1em'
  },
  link: {
    display: 'flex',
    textDecoration: 'none'
  },
  title: {
    transform: 'all .2sec',
    '&:hover': {
      color: PRIMARY_SECONDARY
    }
  }
});

const ReportList = ({ items }: Props) => {
  const classes = useStyles();
  const { languageCode } = useLanguage();
  return (
    <Grid xs={12} container className={classes.container}>
      {items.map((item, key) => (
        <Grid xs={4} className={classes.wrapper} key={key} container>
          <Link to={`${ROUTE_PATHS.Report.path}/${item.path}`} className={classes.link}>
            <Grid xs={10}>
              <Typography variant='h5' className={classes.title} color='primary'>
                {item.title}
              </Typography>
              <Typography color='textSecondary' align='justify'>
                {item.description}
              </Typography>
            </Grid>
            <Grid xs={2}>{item.iconList}</Grid>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportList;
