import React, { FC, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableBody, TableHead, TableRow, TableCell, TableContainer, makeStyles, Theme, Grid, CardMedia, Typography } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { BLACK, RED, WHITE, GREEN, PRIMARY } from 'constants/colors';
import { TableCustom } from 'components/Table';

const useStyles = makeStyles((theme: Theme) => ({
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },
  imgContainer: {},

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  fontMobile: {
    fontSize: 0.67 + 'rem',
    color: BLACK,
    lineHeight: 1 + 'rem'
  }
}));
interface Props {
  isLoadingData: boolean;
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  hasInvoice: boolean;
  currentWareHouse: string;
  indexTooltip: number;
  openTooltip: boolean;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleCloseTooltip: () => void;
  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;

  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
  tab: number;
  isConvertAllowed: boolean;
}

const ListProduct: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const {
    isLoadingData,
    salesOrderItem,
    tab,
    hasInvoice,
    isConvertAllowed,
    handleConfirm,
    handleReject,
    salesOrder,
    currentWareHouse,
    indexTooltip,
    openTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount
  } = props;

  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
  };

  return (
    <TableContainer>
      <TableCustom size='small' className={classes.desktop}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>{translate('productName')}</TableCell>
            <TableCell>Harga</TableCell>
            <TableCell>{translate('quantity')}</TableCell>
            <TableCell>{translate('warehouse')}</TableCell>
            <TableCell>Diskon</TableCell>
            <TableCell>{translate('totalPrice')}</TableCell>
            <TableCell align='center'>{translate('action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesOrderItem.length > 0 &&
            salesOrderItem.map((value, index) => (
              <BodyRow
                key={index}
                tab={tab}
                isLoading={isLoadingData}
                salesOrderItem={value}
                hasInvoice={hasInvoice}
                handleReject={handleReject(value.id)}
                handleConfirm={handleConfirm(value.id)}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index)}
                index={index}
                isConvertAllowed={isConvertAllowed}
                salesOrder={salesOrder}
                currentWareHouse={currentWareHouse}
                openTooltip={openTooltip}
                indexTooltip={indexTooltip}
                handleOpenTooltip={handleOpenTooltip(index)}
                handleCloseTooltip={handleCloseTooltip}
                openAlertPrice={openAlertPrice}
                indexAlertPrice={indexAlertPrice}
                handleOpenAlertPrice={handleOpenAlertPrice(index)}
                handleCloseAlertPrice={handleCloseAlertPrice}
                openAlertDiscount={openAlertDiscount}
                indexAlertDiscount={indexAlertDiscount}
                handleOpenAlertDiscount={handleOpenAlertDiscount(index)}
                handleCloseAlertDiscount={handleCloseAlertDiscount}
              />
            ))}
        </TableBody>
      </TableCustom>
      <Grid container item xs={12} md={12} sm={12}>
        <Grid item xs={4}>
          <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
            {translate('productName')}
          </Typography>
          <Typography className={classes.fontMobile}>{translate('productName')}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
            Qty
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
            Harga
          </Typography>
        </Grid>
        <Grid item xs={2} justify='flex-end'>
          <img src='https://static.bmdstatic.com/pk/product/medium/5ebe38e4cbf28.jpg' alt='Item Image' width='46px' height='46px' />
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
            {translate('productName')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
            Qty
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
            Harga
          </Typography>
        </Grid>
        <Grid item xs={2} justify='flex-end'>
          Button
        </Grid>
      </Grid>
    </TableContainer>
  );
};

export default ListProduct;
