import React, { memo, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { DialogContent, Dialog, Grid, Typography, IconButton, TextField, makeStyles, Button, CircularProgress } from '@material-ui/core';
import { OPERATIONAL_BASE_URL } from 'constants/url';
import { Close, CloudDownload } from '@material-ui/icons';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY, WHITE } from 'constants/colors';
import { dummyOperasional } from 'utils/dummy';
import axios from 'axios';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  }
});
interface Props {
  open: boolean;
  handleCloseCreate: () => void;
  reFetchData: () => void;
}

const OperasionalCreate = ({ open, handleCloseCreate, reFetchData }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [operasional, setOperasional] = useState<Operasional>(dummyOperasional);
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const disabled = operasional.itemName === '' || operasional.nominal === 0;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setImageUrl([]);
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setOperasional(prev => ({ ...prev, [id]: value }));
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    const dataFinal = {
      ...operasional,
      image
    };

    try {
      const { data } = await axios.post(OPERATIONAL_BASE_URL, dataFinal);

      data.data.image.map(async (value: any, index: number) => {
        if (image.some(img => img.path === value.path)) {
          const newImageKey = value.path;
          const fileExtension = newImageKey.split('.').pop();
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', `image/${fileExtension}`);

          const config: RequestInit = {
            method: 'PUT',
            headers: myHeaders,
            body: imageBlob[index]
          };

          await fetch(value.url, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }
      });

      reFetchData();
      reMounted();
    } catch (error) {
      console.log(error);
    }
  };

  const reMounted = () => {
    setOperasional(dummyOperasional);
    setIsSubmit(false);
    setImage([]);
    setImageBlob([]);
    setImageUrl([]);
  };
  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl([...imageUrl, URL.createObjectURL(value)]);
      setImage([...image, { path: `${operasional.itemName}${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob([...imageBlob, value]);
    });
  };
  const handleDeleteImage = (index: number) => {
    setImageUrl(imageUrl.filter((val, key) => index !== key));
    setImage(image.filter((val, key) => index !== key));
    setImageBlob(imageBlob.filter((val, key) => index !== key));
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>{translate('addOperationalCost')}</Typography>
          <IconButton
            // aria-label='close' ' onClick={() => setOpen(false)}
            onClick={handleCloseCreate}
            size='small'
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid xs={12}>
            <TextField id='itemName' required fullWidth autoComplete='off' label='Nama Item' value={operasional.itemName} onChange={handleChange} />

            <TextField
              id='nominal'
              required
              fullWidth
              name='nominal'
              autoComplete='off'
              label='Masukan Nominal'
              value={operasional.nominal}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOperasional(prev => ({ ...prev, ['nominal']: +event.target.value }))}
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />

            <TextField
              id='description'
              label={translate('descriptions')}
              multiline
              rows={4}
              variant='outlined'
              fullWidth
              value={operasional.description}
              onChange={handleChange}
            />

            <Grid xs={12} container className={classes.containerImage}>
              <Grid xs={12} container direction='row' className={classes.uploadImage} alignItems='center'>
                <Grid xs={2}>
                  <CloudDownload color='primary' className={classes.iconLarge} />
                </Grid>
                <Grid xs={10} {...getRootProps()}>
                  <Typography variant='h6'>Drag and drop image here</Typography>
                  <input {...getInputProps()} />
                  <Typography color='textSecondary'>
                    Drop image here or click <u className={classes.browser}> browser</u> to upload your image max 3 photos
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} container className={classes.wrapperImage}>
                {imageUrl.map((image, index) => (
                  <Grid className={classes.containerBoxImage} key={index}>
                    <img className={classes.image} src={image} alt='images-product' />
                    <Grid container className={classes.closeImage} justify='center' alignItems='center' xs={2}>
                      <Close className={classes.closeIcon} fontSize='small' onClick={() => handleDeleteImage(index)} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={handleCloseCreate}>
              {translate('cancel')}
            </Button>

            <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
              {isSubmit ? <CircularProgress color='primary' size={20} /> : translate('save')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(OperasionalCreate);
