import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TypeCourierSalaryResource } from 'pages/CourierSalary';
import { Divider, Grid, makeStyles, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components';
import { Skeleton } from '@material-ui/lab';
import ContentBody from './ContentBody';

interface Props {
  courierSalaryResource: TypeCourierSalaryResource;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTableSecond: {
    width: '5%'
  },
  container: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  }
}));
const ContentTable = ({ courierSalaryResource }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            headers={[
              { id: 'id', label: 'Id', sort: true },
              { id: 'name', label: translate('courierName'), sort: true },
              { id: 'startDate', label: 'Tanggal Mulai', sort: true },
              { id: 'endDate', label: 'Tanggal Akhir', sort: true },
              { id: 'history', label: 'Riwayar Pengantaran', sort: true },
              { id: 'price', label: translate('totalAmount'), sort: true }
            ]}
          />
        </TableHead>

        <TableBody>
          {courierSalaryResource.isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Grid xs={12} container>
                  {[...new Array(5)].map((val, key) => (
                    <Grid xs={12}>
                      <Skeleton variant='text' animation='wave' height={60} key={key} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ) : courierSalaryResource.data.length > 0 ? (
            courierSalaryResource.data.map((courierSalaryResource, key) => <ContentBody courierSalaryResource={courierSalaryResource} />)
          ) : (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                <Grid xs={12} className={classes.container} container alignItems='center' justify='center'>
                  <Typography variant='h6'>{translate('dataNotAvailable')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ContentTable;
