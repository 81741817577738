import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DialogTitle } from 'components/Dialog';
import { HeaderRow, TableCustom } from 'components/Table';
import BodyRowProduct from './BodyRowProduct';
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dummyProduct } from 'utils/dummy';

interface Props {
  checked: ProductItemModel[];
  openProduct: boolean;
  queryString: string;
  categories: CategoryModel[];
  packageMessageError: string;
  order: 'asc' | 'desc';
  orderBy: string;
  isLoadingData: boolean;
  productOriginals: ProductModel[];
  count: number;
  currentPage: number;
  setCategory: React.Dispatch<React.SetStateAction<CategoryModel>>;
  handleClose: () => void;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  handleSearchCategory: (value: string) => void;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  handleIndividualCheck: (item: ProductItemModel) => void;
  handleCancelProduct: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  bordered: {
    border: `1px solid ${red[500]}`
  }
});

const ProductModal: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const {
    checked,
    openProduct,
    setCategory,
    handleClose,
    queryString,
    setQueryString,
    categories,
    handleSearchCategory,
    packageMessageError,
    order,
    orderBy,
    handleRequestSort,
    isLoadingData,
    productOriginals,
    count,
    setCurrentPage,
    currentPage,
    handleCancelProduct,
    handleIndividualCheck
  } = props;

  return (
    <Dialog open={openProduct} onClose={handleClose} maxWidth={false} classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Pilih Produk</DialogTitle>
      <DialogContent>
        <Grid direction='row' container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField id='query' label='Cari Barang disini' value={queryString} onChange={event => setQueryString(event.target.value)} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Autocomplete
              id='category'
              options={categories}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event: any, value: any) => {
                if (value) {
                  setCategory(value);
                }
              }}
              onOpen={e => handleSearchCategory('')}
              renderInput={params => <TextField {...params} label={translate('category')} onChange={e => handleSearchCategory(e.target.value)} />}
            />
          </Grid>
          <Grid item lg={12}>
            <TableContainer className={packageMessageError && classes.bordered}>
              <TableCustom>
                <TableHead>
                  <HeaderRow
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headers={[
                      { label: 'CheckBox', pR: '10px', pT: '7px', verticalAlign: 'top' },
                      { id: 'productCode', label: translate('productCode'), sort: true },
                      { id: 'productName', label: translate('name'), sort: true },
                      { id: 'CategoryId', label: translate('category'), sort: true },
                      { id: 'typeUnit', label: translate('unit'), sort: true, align: 'center' },
                      { label: '' }
                    ]}
                  />
                </TableHead>
                <TableBody>
                  {isLoadingData ? (
                    [0, 1, 2, 3].map(index => (
                      <BodyRowProduct
                        key={index}
                        product={dummyProduct}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={handleIndividualCheck}
                      />
                    ))
                  ) : productOriginals && productOriginals.length > 0 ? (
                    productOriginals.map((value, index) => (
                      <BodyRowProduct
                        key={index}
                        product={value}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={handleIndividualCheck}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align='center'>
                        {queryString ? 'No matching result' : translate('dataNotAvailable')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
          </Grid>

          <Grid container justify='center' item xl={12} md={12} sm={12}>
            {productOriginals && productOriginals.length > 0 && (
              <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleCancelProduct}>
          {translate('cancelled')}
        </Button>
        <Button onClick={handleClose}>{translate('save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductModal;
