import React, { FC, useContext } from 'react';
import { List, makeStyles } from '@material-ui/core';
import {
  mdiCubeOutline,
  mdiAccountMultipleOutline,
  mdiLogout,
  mdiFileDocumentEditOutline,
  mdiTruckDeliveryOutline,
  mdiFileChartOutline,
  mdiCurrencyUsd
} from '@mdi/js';
import { WHITE } from 'constants/colors';
import DrawerItem from './DrawerItem';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import { hasAccess } from 'selectors';
import TypeUser from 'typings/enum/TypeUser';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import { modules } from 'constants/modules';
import { useLanguage } from 'contexts/LanguageContext';
import ROUTE_PATHS from 'constants/routePaths';
interface Props {
  currentUserData: CurrentUser | undefined;
  handleDrawerClose(): void;
  useNotification?: boolean;
  totalNotification?: number;
}

const useStyles = makeStyles({
  textIcon: {
    color: WHITE,
    fontSize: 50,
    fontWeight: 'bold'
  }
});

const DrawerList: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { handleDrawerClose, currentUserData, totalNotification } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isSupervisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const products = [
    { name: 'Product', path: ROUTE_PATHS.Product.path },
    { name: 'Category', path: ROUTE_PATHS.Category.path },
    { name: 'Stock', path: ROUTE_PATHS.AllStocks.path }
  ];

  const orders = [
    { name: 'Sales Order', path: ROUTE_PATHS.SalesOrder.path },
    { name: 'Purchase Order', path: ROUTE_PATHS.PurchaseOrder.path }
  ];

  const costs = [
    { name: 'Operational Cost', path: ROUTE_PATHS.OperationalCost.path },
    { name: 'Courier Commission', path: ROUTE_PATHS.CourierCommission.path }
  ];

  const invoices = [{ name: 'Sales Order Invoice', path: ROUTE_PATHS.SalesOrderInvoice.path }];

  if (isSuperAdmin) {
    invoices.push({ name: 'Purchase Order Invoice', path: ROUTE_PATHS.PurchaseOrderInvoice.path });
  }

  return (
    <List className={classes.textIcon}>
      {hasAccess(currentUserData, modules.PARTNER) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          iconMdi={mdiAccountMultipleOutline}
          path={ROUTE_PATHS.Partners.path}
          label={translate('partners')}
          child={false}
        />
      )}

      {(hasAccess(currentUserData, modules.PRODUCT) ||
        hasAccess(currentUserData, modules.CATEGORIES) ||
        hasAccess(currentUserData, modules.STOCK)) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          iconMdi={mdiCubeOutline}
          path='#'
          label={translate('productStok')}
          child={true}
          childData={products.filter(product => {
            if (product.name === 'Product') {
              return hasAccess(currentUserData, modules.PRODUCT);
            }

            if (product.name === 'Category' && !!currentUserData && currentUserData.type !== TypeUser.SALES) {
              return hasAccess(currentUserData, modules.CATEGORIES);
            }
            if (product.name === 'Stock') {
              return hasAccess(currentUserData, modules.STOCK);
            }
          })}
        />
      )}

      <DrawerItem
        handleDrawerClose={handleDrawerClose}
        Icon={ShoppingCartIcon}
        path='#'
        label={translate('orders')}
        child={true}
        childData={orders.filter(order => {
          switch (order.name) {
            case 'Sales Order':
              return hasAccess(currentUserData, modules.SALES_ORDER);
            case 'Purchase Order':
              return hasAccess(currentUserData, modules.PURCHASE_ORDER);
          }
        })}
      />

      {(isSuperAdmin || isSupervisor || isAdmin) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          iconMdi={mdiFileDocumentEditOutline}
          path='#'
          label={translate('invoices')}
          child={true}
          childData={invoices.filter(invoice => {
            switch (invoice.name) {
              case 'Sales Order Invoice':
                return hasAccess(currentUserData, modules.INVOICE);
              case 'Purchase Order Invoice':
                return !isAdmin && hasAccess(currentUserData, modules.PURCHASE_INVOICE);
            }
          })}
        />
      )}

      <DrawerItem
        handleDrawerClose={handleDrawerClose}
        iconMdi={mdiTruckDeliveryOutline}
        path={ROUTE_PATHS.DeliveryOrder.path}
        label={translate('deliveryOrder')}
        child={false}
      />

      <DrawerItem
        handleDrawerClose={handleDrawerClose}
        iconMdi={mdiCurrencyUsd}
        path='#'
        label={translate('expenses')}
        child={true}
        childData={costs.filter(cost => {
          switch (cost.name) {
            case 'Operational Cost':
              return hasAccess(currentUserData, modules.OPERATIONAL);
            case 'Courier Commission':
              return hasAccess(currentUserData, modules.OPERATIONAL);
          }
        })}
      />

      <DrawerItem
        handleDrawerClose={handleDrawerClose}
        iconMdi={mdiFileChartOutline}
        path={ROUTE_PATHS.Report.path}
        label={translate('report')}
        child={false}
      />

      {(!isAdmin && hasAccess(currentUserData, modules.USERS)) ||
      (!isAdmin && hasAccess(currentUserData, modules.COMPANY)) ||
      (!isAdmin && hasAccess(currentUserData, modules.WAREHOUSE)) ||
      (!isAdmin && hasAccess(currentUserData, modules.ZONE))
        ? !!currentUserData &&
          currentUserData.type !== TypeUser.SALES && (
            <DrawerItem
              handleDrawerClose={handleDrawerClose}
              Icon={SettingsIcon}
              path={ROUTE_PATHS.Setting.path}
              label={translate('setting')}
              child={false}
            />
          )
        : ''}
      {!isAdmin && hasAccess(currentUserData, modules.APPLOG) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          Icon={ExitToAppIcon}
          path={ROUTE_PATHS.ApplicationLogs.path}
          label={translate('applicationLogs')}
          child={false}
        ></DrawerItem>
      )}
      <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiLogout} path='logout' label={translate('logout')} child={false} />
    </List>
  );
};

export default DrawerList;
