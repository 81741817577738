import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Grid, TextField, Button, Theme, withStyles, makeStyles, MenuItem } from '@material-ui/core';

interface Props {
  okLabel: 'Add' | 'Edit';

  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  password: string;
  passwordMessage: string;
  type: string;
  typeError: string;
  roles: RoleModel[];

  setType: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;

  emailError: string;
  firstNameError: string;
  lastNameError: string;
  contactNumberError: string;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const AddButton = withStyles(theme => ({
  root: {
    color: 'primary'
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    color: '#FFFFFF'
  },
  root: {
    flexGrow: 1,
    maxWidth: 700
  },
  gridRolesHidden: {
    display: 'none'
  }
}));

const EditUserForm: React.FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();

  const { type, typeError, setType, roles } = props;
  const { email, setEmail, emailError } = props;
  const { firstName, setFirstName, firstNameError } = props;
  const { lastName, setLastName, lastNameError } = props;
  const { contactNumber, setContactNumber, contactNumberError } = props;
  const { isSubmitting, onSubmit, onCancel, okLabel } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='firstName'
            placeholder='Nama Depan'
            error={firstNameError !== ''}
            helperText={firstNameError}
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='lastName'
            placeholder='Nama Belakang'
            error={lastNameError !== ''}
            helperText={lastNameError}
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            autoComplete='off'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            placeholder='Email'
            error={emailError !== ''}
            helperText={emailError}
            value={email}
            onChange={event => setEmail(event.target.value)}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='contact'
            placeholder='Contact '
            error={contactNumberError !== ''}
            helperText={contactNumberError}
            value={contactNumber}
            onChange={event => setContactNumber(event.target.value)}
            autoComplete='off'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            id='typeUser'
            required
            label='Tipe'
            value={type as string}
            error={typeError !== ''}
            helperText={typeError}
            onChange={e => setType(e.target.value as string)}
            select
            fullWidth
          >
            <MenuItem key={'other'}>Pilih Tipe</MenuItem>
            {roles.length > 0 &&
              roles.map((value, index) => (
                <MenuItem key={index} value={value.label}>
                  {value.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        {/* {modulesHady().length && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TreeView
                className={classes.root}
                defaultExpanded={['1']}
                expanded={expanded}
                onNodeToggle={handleChangeExpanded}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {}
                <TreeItem nodeId='1' label='Roles'>
                  {modulesHady().map(value => (
                    <TreeItemCustom
                      nodeId='2'
                      label={`- ${value.module} (${permissions.filter(permission => permission.module === value.module).length}/${
                        [...userPermissions].filter(x => x === 1).length
                          ? permissions.filter(permission => permission.module === value.module).length
                          : [...userPermissions].filter(up => filterPermission(value.module).includes(up)).length
                      })`}
                      onLabelClick={event => setSelectRole(permissions.filter(permission => permission.module === value.module))}
                    />
                  ))}
                </TreeItem>
              </TreeView>
            </Grid>
            <Grid item xs={12} sm={6} className={!expanded.length ? classes.gridRolesHidden : ''}>
              {selectRole.map((permission, index) => (
                <Grid key={index} item xs={12}>
                  <FormControl component='fieldset'>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => handleChange(permission.id)}
                            checked={userPermissions.includes(1) ? true : userPermissions.includes(permission.id)}
                            disabled={permission.id !== 1 ? userPermissions.includes(1) && true : userPermissions.includes(1) && false}
                            color='primary'
                            name={permission.module}
                          />
                        }
                        label={`${permission.module} - ${permission.accessLevel}`.toLowerCase()}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )} */}

        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button color='secondary' onClick={onCancel} disabled={isSubmitting}>
            {translate('cancel')}
          </Button>
          <AddButton type='submit' color='primary' disabled={isSubmitting}>
            {okLabel}
          </AddButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditUserForm;
