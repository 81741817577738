import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableRow } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';

interface Props {
  invoiceItem: PurchaseInvoiceItemModel;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { invoiceItem, isLoading } = props;

  return (
    <TableRow>
      <TableCellStart>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoiceItem.Product ? invoiceItem.Product.productName : ''}
      </TableCellStart>

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          <NumberFormat value={invoiceItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoiceItem.totalItem + ' ' + invoiceItem.typeUnit}
      </TableCellMiddle>

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          <NumberFormat value={invoiceItem.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          <NumberFormat value={invoiceItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellEnd>
    </TableRow>
  );
};

export default BodyRow;
