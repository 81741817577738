import { Button, CircularProgress, Grid, LinearProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import { ArrowBack, Create, Delete, Save } from '@material-ui/icons';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import axios from 'axios';
import { PaperCustom } from 'components';
import FlexBox from 'components/FlexBox';
import { GRAY_3 } from 'constants/colors';
import { WILAYAH_KABUPATEN_BASE_URL, WILAYAH_KECAMATAN_BASE_URL, WILAYAH_PROVINSI_BASE_URL } from 'constants/url';
import React, { memo, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import ReactQuill from 'react-quill';
import { dummyRegion } from 'utils/dummy';
const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  },
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  EditorNotes: {
    PaddingTop: 12,
    marginTop: 12
  }
});
interface Props {
  resellerResource: {
    isLoading: boolean;
    data: PartnerModel[];
  };

  resellerDelete: { isLoading: boolean; id: number; isOpen: boolean };
  handleSubmitUpdateReseller: () => void;
  setResellerResource: React.Dispatch<React.SetStateAction<{ isLoading: boolean; data: PartnerModel[] }>>;
  handleUpdateReseller: (index: number, isEdit: boolean) => void;
  handleDeleteReseller: (id: number) => void;
}

const ReselllerDetails = ({
  resellerResource,
  handleUpdateReseller,
  setResellerResource,
  handleDeleteReseller,
  handleSubmitUpdateReseller,
  resellerDelete
}: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const [loadProvinsi, setLoadProvinsi] = useState<boolean>(false);
  const [loadKabupaten, setLoadKabupaten] = useState<boolean>(false);
  const [loadKecamatan, setLoadKecamatan] = useState<boolean>(false);
  const [region, setRegion] = useState<RegionModel[]>([dummyRegion]);

  const hanldeChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = resellerResource.data.map((value, key) => {
      if (key === index) {
        return { ...value, [event.target.id]: event.target.value };
      } else {
        return { ...value };
      }
    });
    setResellerResource({ ...resellerResource, ['data']: temp });
  };

  const handleSearchProvinsi = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadProvinsi(true);
    try {
      const { data } = await axios.get(`${WILAYAH_PROVINSI_BASE_URL}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadProvinsi(false);
    }
  };

  const handleSearchKabupaten = async (provinsiCode: string = '', value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadKabupaten(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KABUPATEN_BASE_URL(provinsiCode)}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadKabupaten(false);
    }
  };

  const handleSearchKecamatan = async (kabupatenCode: string = '', value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadKecamatan(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KECAMATAN_BASE_URL(kabupatenCode)}?${params.toString()}`);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadKecamatan(false);
    }
  };
  return (
    <Grid xs={12} container>
      {resellerResource.isLoading ? (
        <PaperCustom>
          <Grid xs={12} container className={classes.container}>
            <Grid xs={12}>
              <LinearProgress />
            </Grid>
            <Grid xs={12} container justify='space-between'>
              <Grid xs={6}>
                <Typography variant='h5' color='primary'>
                  Loading...
                </Typography>
              </Grid>
              <FlexBox xs={6} container justify='flex-end' columnGap={1}>
                <Button variant='text' color='primary' disabled size='small' startIcon={<Delete />}>
                  Hapus Customer Reseller
                </Button>
                <Button variant='text' color='primary' disabled size='small' startIcon={<Create />}>
                  Edit Customer Reseller
                </Button>
              </FlexBox>
            </Grid>

            <Grid className={classes.underline} xs={12} />
            <Grid xs={12} container>
              <Grid xs={6} container alignItems='baseline'>
                <Grid xs={11} container className={classes.container}>
                  <Grid xs={12}>
                    <Typography variant='h6'>Informasi Umum</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography color='textSecondary'>{translate('partnerID')}</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>{translate('partnersName')} </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />{' '}
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Tipe Mitra </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>No Handphone </Typography>
                  </Grid>

                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />{' '}
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Email </Typography>
                  </Grid>

                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />{' '}
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                </Grid>
              </Grid>
              <Grid xs={6} container>
                <Grid xs={11} container className={classes.container}>
                  <Grid xs={12}>
                    <Typography variant='h6'>{translate('addressInformation')}</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Alamat Lengkap</Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Propinsi </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />{' '}
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Kabupaten </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />{' '}
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Kecamatan </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>{translate('postalCode')}</Typography>
                  </Grid>

                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                  <Grid xs={4}>
                    <Typography color='textSecondary'>Catatan </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography>
                      <Skeleton variant='text' width='100%' height={30} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.underline} xs={12} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PaperCustom>
      ) : resellerResource.data.length > 0 ? (
        resellerResource.data.map((reseller, key) => (
          <PaperCustom key={key}>
            <Grid xs={12} container className={classes.container}>
              <Grid xs={12} container justify='space-between'>
                <Grid xs={6}>
                  <Typography variant='h5' color='primary'>
                    Customer {key + 1}
                  </Typography>
                </Grid>
                <Grid xs={6} container justify='flex-end' spacing={1}>
                  {reseller.isEdit ? (
                    <>
                      <Grid item>
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          startIcon={<ArrowBack />}
                          onClick={() => handleUpdateReseller(key, false)}
                        >
                          {translate('cancelled')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          startIcon={<Save />}
                          onClick={handleSubmitUpdateReseller}
                          disabled={
                            resellerResource.data.filter((val, index) =>
                              index === key ? val.name === '' || val.address === '' || !val.Provinsi || !val.Kabupaten || !val.Kecamatan : false
                            ).length > 0
                          }
                        >
                          {translate('save')}
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <Button
                          variant='text'
                          style={{ color: GRAY_3 }}
                          size='small'
                          disabled={resellerDelete.isLoading}
                          startIcon={resellerDelete.isLoading && resellerDelete.id === reseller.id ? <CircularProgress size={20} /> : <Delete />}
                          onClick={() => handleDeleteReseller(reseller.id)}
                        >
                          Hapus Customer Reseller
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant='text' color='primary' size='small' startIcon={<Create />} onClick={() => handleUpdateReseller(key, true)}>
                          Edit Customer Reseller
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.underline} xs={12} />
              <Grid xs={12} container>
                <Grid xs={6} container alignItems='baseline'>
                  <Grid xs={11} container className={classes.container}>
                    <Grid xs={12}>
                      <Typography variant='h6'>Informasi Umum</Typography>
                    </Grid>
                    <Grid xs={4} container>
                      <Typography color='textSecondary'>{translate('partnerID')}</Typography>
                    </Grid>
                    <Grid xs={8}>
                      {reseller.isEdit ? (
                        <TextField id='partnerId' required fullWidth placeholder={translate('partnerID')} value={reseller.partnerId} disabled />
                      ) : (
                        <Typography>{reseller.partnerId}</Typography>
                      )}
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>{translate('partnersName')} </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <TextField
                            id='name'
                            name='name'
                            label={translate('partnersName')}
                            required
                            fullWidth
                            placeholder={translate('name')}
                            value={reseller.name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => hanldeChange(key, event)}
                          />
                        ) : (
                          reseller.name
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Tipe Mitra </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <TextField id='partnerId' required fullWidth placeholder={translate('partnerID')} value={reseller.partnerId} disabled />
                        ) : (
                          'Customer Turunan'
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>No Handphone </Typography>
                    </Grid>

                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <TextField
                            id='phoneNumber'
                            fullWidth
                            placeholder='No Telepon'
                            value={reseller.phoneNumber}
                            inputProps={{
                              maxLength: 13
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => hanldeChange(key, event)}
                          />
                        ) : (
                          reseller.phoneNumber || `-`
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Email </Typography>
                    </Grid>

                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <TextField
                            id='email'
                            name='email'
                            required
                            fullWidth
                            placeholder='Email '
                            value={reseller.email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => hanldeChange(key, event)}
                          />
                        ) : (
                          reseller.email || `-`
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                  </Grid>
                </Grid>
                <Grid xs={6} container>
                  <Grid xs={11} container className={classes.container}>
                    <Grid xs={12}>
                      <Typography variant='h6'>{translate('addressInformation')}</Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Alamat Lengkap</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <TextField
                            id='address'
                            required
                            fullWidth
                            placeholder={translate('address')}
                            value={reseller.address}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => hanldeChange(key, event)}
                          />
                        ) : (
                          reseller.address
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Propinsi </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <>
                            <Autocomplete
                              id='provinsi'
                              fullWidth
                              value={resellerResource.data[key].Provinsi}
                              options={region}
                              getOptionLabel={option => option.name}
                              getOptionSelected={(option, value) => option.code === value.code}
                              onChange={(event: any, value: any) => {
                                if (value) {
                                  setResellerResource({
                                    ...resellerResource,
                                    ['data']: resellerResource.data.map((val, index) => {
                                      if (key === index) {
                                        return { ...val, Provinsi: value };
                                      } else {
                                        return { ...val };
                                      }
                                    })
                                  });
                                }
                              }}
                              loading={loadProvinsi}
                              onOpen={e => handleSearchProvinsi('')}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label='Provinsi *'
                                  onChange={e => handleSearchProvinsi(e.target.value)}
                                  variant='outlined'
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loadProvinsi && <CircularProgress color='inherit' size={20} />}
                                        {params.InputProps.endAdornment}
                                      </>
                                    )
                                  }}
                                />
                              )}
                            />
                          </>
                        ) : (
                          reseller.Provinsi && reseller.Provinsi.name
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Kabupaten </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <Autocomplete
                            id='kabupaten'
                            fullWidth
                            value={resellerResource.data[key].Kabupaten}
                            options={region}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.code === value.code}
                            onChange={(event: any, value: any) => {
                              if (value) {
                                setResellerResource({
                                  ...resellerResource,
                                  ['data']: resellerResource.data.map((val, index) => {
                                    if (key === index) {
                                      return { ...val, Kabupaten: value };
                                    } else {
                                      return { ...val };
                                    }
                                  })
                                });
                              }
                            }}
                            loading={loadKabupaten}
                            onOpen={e => handleSearchKabupaten(resellerResource.data[key].Provinsi!.code, '')}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                label='Kabupaten *'
                                onChange={e => handleSearchKabupaten(resellerResource.data[key].Provinsi!.code, e.target.value)}
                                variant='outlined'
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadKabupaten && <CircularProgress color='inherit' size={20} />}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                        ) : (
                          reseller.Kabupaten && reseller.Kabupaten.name
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Kecamatan </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <Autocomplete
                            id='kecamatan'
                            fullWidth
                            options={region}
                            value={resellerResource.data[key].Kecamatan}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.code === value.code}
                            onChange={(event: any, value: any) => {
                              if (value) {
                                setResellerResource({
                                  ...resellerResource,
                                  ['data']: resellerResource.data.map((val, index) => {
                                    if (key === index) {
                                      return { ...val, Kecamatan: value };
                                    } else {
                                      return { ...val };
                                    }
                                  })
                                });
                              }
                            }}
                            loading={loadKecamatan}
                            onOpen={e => handleSearchKecamatan(resellerResource.data[key].Kabupaten!.code, '')}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Kecamatan *'
                                size='small'
                                onChange={e => handleSearchKecamatan(resellerResource.data[key].Kabupaten!.code, e.target.value)}
                                variant='outlined'
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadKecamatan && <CircularProgress color='inherit' size={20} />}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                        ) : (
                          reseller.Kecamatan && reseller.Kecamatan.name
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>{translate('postalCode')}</Typography>
                    </Grid>

                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <>
                            <TextField
                              id='postalCode'
                              fullWidth
                              placeholder={translate('postalCode')}
                              value={reseller.postalCode}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => hanldeChange(key, event)}
                            />
                          </>
                        ) : (
                          reseller.postalCode
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                    <Grid xs={4}>
                      <Typography color='textSecondary'>Catatan </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography>
                        {reseller.isEdit ? (
                          <>
                            <ReactQuill
                              className={classes.EditorNotes}
                              id='notes'
                              value={reseller.notes}
                              onChange={(value: any) =>
                                setResellerResource({
                                  ...resellerResource,
                                  ['data']: resellerResource.data.map((val, index) => {
                                    if (key === index) {
                                      return { ...val, notes: value };
                                    } else {
                                      return { ...val };
                                    }
                                  })
                                })
                              }
                              placeholder={translate('notes')}
                            />
                          </>
                        ) : (
                          <span dangerouslySetInnerHTML={{ __html: reseller.notes || '-' }} />
                        )}
                      </Typography>
                    </Grid>
                    <Grid className={classes.underline} xs={12} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PaperCustom>
        ))
      ) : (
        <PaperCustom>
          <Grid xs={12}>
            <Typography variant='h6'>Data reseller tidak ada</Typography>
          </Grid>
        </PaperCustom>
      )}
    </Grid>
  );
};

export default memo(ReselllerDetails);
