import React, { FC, useEffect, useCallback, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, TextField, CircularProgress, Button } from '@material-ui/core';
import { Page, PaperCustom, Breadcrumb } from 'components';
import {
  STOCK_ALL_BASE_URL,
  WAREHOUSE_BASE_URL,
  CATEGORY_BASE_URL,
  PRODUCT_SEARCH,
  STOCK_WAREHOUSE_BASE_URL,
  STOCK_UPDATE,
  DETAIL_STOCK
} from 'constants/url';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dummyWareHouse, dummyCategory } from 'utils/dummy';
import useDebounce from 'hooks/useDebounce';
import AllStockTable from './components/AllStockTable';
import Pagination from '@material-ui/lab/Pagination';
import StockDamaged from './components/Modals/StockDamaged';
import StockSales from './components/Modals/StockSales';
import Success from './components/Modals/Success';

const AllStockPage: FC = () => {
  const [count, setCount] = useState<number>(0);
  const { translate } = useLanguage();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allStocks, setAllStocks] = useState<StockAllItemModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [searchTemp, setSearchTemp] = useState<string>('');
  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([dummyWareHouse]);
  const [warehouse, setWarehouse] = useState<WareHouseModel>(dummyWareHouse);
  const [productName, setProductName] = useState<string>('');
  const [loadWarehouse, setLoadWarehouse] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);
  const [loadCategory, setLoadCategory] = useState<boolean>(false);
  const [isKeyWord, setIsKeyWord] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [stockSales, setStockSales] = useState<boolean>(false);
  const [stockDamaged, setStockDamaged] = useState<boolean>(false);
  const [stockUpdateLoading, setStockUpdateLoading] = useState<boolean>(false);
  const [stockWareHouse, setStockWareHouse] = useState<{ total: number; name: string }[]>([]);
  const [keyWordResource, setKeyWordResource] = useState<{ productName: string }[]>([]);
  const [stockId, setStockId] = useState<number>(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [keyWord, setKeyWord] = useState<string>('');
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openOptions = Boolean(anchorEl);
  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      if (productName) {
        params.append('productName', productName);
      }
      if (warehouse && warehouse.id > 0) {
        params.append('WareHouseId', warehouse.id.toString());
      }
      if (category && category.id > 0) {
        params.append('CategoryId', category.id.toString());
      }
      params.append('page', String(currentPage));
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${STOCK_ALL_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });

      setAllStocks(data.data);
      setCount(data.meta.last_page);

      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  }, [orderBy, order, currentPage, warehouse, category, productName]);

  const handleSearchWarehouse = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadWarehouse(true);
    try {
      const { data } = await axios.get(`${WAREHOUSE_BASE_URL}?${params.toString()}`);
      setWarehouses(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWarehouse(false);
    }
  };

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('product', 'true');
    params.append('keyword', value);
    setLoadCategory(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadCategory(false);
    }
  };

  const handleOpenOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const handleStockSales = () => {
    setStockSales(true);
    handleCloseOption();
  };
  const handleStockDamaged = () => {
    setStockDamaged(true);
    handleCloseOption();
  };

  const fetchStockWarehouse = async () => {
    setLoadWarehouse(true);
    try {
      const { data } = await axios.get(STOCK_WAREHOUSE_BASE_URL);
      setStockWareHouse(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWarehouse(false);
    }
  };

  const debouncedSearchTerm = useDebounce(keyWord, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      dispatchProductSearch();
    }
  }, [debouncedSearchTerm]);

  const dispatchProductSearch = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (keyWord) {
        params.append('keyword', keyWord);
      }

      return params;
    };
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${getQueryParams()}`);
      setKeyWordResource(data.data);

      data.data.length > 0 && setIsKeyWord(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitStock = async (
    ProductId: number,
    salesStock: number,
    salesStockInput: number,
    damageStock: number,
    remark: string,
    isStock: boolean
  ) => {
    setStockUpdateLoading(true);
    const dataTempStock = {
      ProductId,
      salesStock,
      salesStockInput,
      remark
    };
    const dataTempDemege = {
      ProductId,
      salesStock,
      damageStock,
      remark
    };

    try {
      const { data } = await axios.post(STOCK_UPDATE, isStock ? dataTempStock : dataTempDemege);
      setIsSuccess(true);
      setStockUpdateLoading(false);
      setStockSales(false);
      setStockDamaged(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // fetchWarehouse();
    fetchStockWarehouse();
  }, []);

  const getData = async (id: number) => {
    setLoadingItem(true);

    try {
      const { data } = await axios.get(DETAIL_STOCK(id));
      setLoadingItem(false);

      setAllStocks(
        allStocks.map(value => {
          if (value.id === id) {
            value.stockEdit = data.data.stockEdit;
          }
          return value;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  return (
    <Page title='Stok'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> {translate('allStocks')} </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                id='warehouse'
                fullWidth
                options={warehouses}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event: any, value: any, reason: string) => {
                  value && setWarehouse(value);
                  reason === 'clear' && setWarehouse(dummyWareHouse);
                }}
                onOpen={e => handleSearchWarehouse('')}
                loading={loadWarehouse}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={translate('allWarehouses')}
                    fullWidth
                    onChange={e => handleSearchWarehouse(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadWarehouse && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justify='flex-end' alignItems='center'>
              <Grid item>
                <Button disabled>Export</Button>
              </Grid>
            </Grid>

            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AllStockTable
                isLoadingData={isLoadingData}
                allStocks={allStocks}
                order={order}
                setSearchTemp={setSearchTemp}
                searchTemp={searchTemp}
                orderBy={orderBy}
                handleCloseOption={handleCloseOption}
                openOptions={openOptions}
                anchorEl={anchorEl}
                handleOpenOption={handleOpenOption}
                category={category}
                categories={categories}
                isKeyWord={isKeyWord}
                handleStockSales={handleStockSales}
                handleStockDamaged={handleStockDamaged}
                setKeyWord={setKeyWord}
                keyWord={keyWord}
                stockId={stockId}
                setStockId={setStockId}
                keyWordResource={keyWordResource}
                setIsKeyWord={setIsKeyWord}
                productName={productName}
                loadCategory={loadCategory}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setProductName={setProductName}
                setCategory={setCategory}
                handleSearchCategory={handleSearchCategory}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                handleOpenCollapse={handleOpenCollapse}
                loadingItem={loadingItem}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12}>
              {allStocks && allStocks.length > 0 && (
                <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
              )}
            </Grid>

            <StockDamaged
              open={stockDamaged}
              setOpen={setStockDamaged}
              isLoading={stockUpdateLoading}
              stockResource={allStocks.filter(val => val.id === stockId)[0]}
              handleSubmitStock={handleSubmitStock}
            />

            <StockSales
              open={stockSales}
              setOpen={setStockSales}
              isLoading={stockUpdateLoading}
              stockResource={allStocks.filter(val => val.id === stockId)[0]}
              handleSubmitStock={handleSubmitStock}
            />

            <Success open={isSuccess} />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default AllStockPage;
