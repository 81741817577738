import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { makeStyles, createStyles, Theme, IconButton, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusPayment, TableRowCollapse } from 'components';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  invoice: InvoiceModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  handleOpenCollapse: React.MouseEventHandler;
  loadingItem: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    }
  })
);

const BodyRow: FC<Props> = props => {
  const { loadingItem, invoice, isLoading, openCollapse, index, indexCollapse, handleOpenCollapse } = props;
  const classes = useStyles();
  const { translate } = useLanguage();

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>
        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={60} height={25} /> : invoice.partnerName}</TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <StatusPayment
              status={invoice.statusPayment}
              overdue={invoice.paymentDue ? new Date().getTime() > new Date(invoice.paymentDue).getTime() : false}
              totalPrice={invoice.totalPrice}
              totalPay={invoice.totalPay}
            />
          )}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : invoice.paymentDue ? (
            format(new Date(invoice.paymentDue), 'dd MMM yyyy')
          ) : (
            ''
          )}
        </TableCellMiddle>

        <TableCellEnd>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellEnd>
      </TableRowCustom>
      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={9}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='center'>{translate('quantity')}</TableCell>
              <TableCell align='center'>{translate('warehouse')}</TableCell>
              <TableCell align='right'> {translate('totalPrice')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              invoice.InvoiceItem &&
              invoice.InvoiceItem.map((value: InvoiceItemModel, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellCollapse}>{value.Product ? value.Product.productName : '-'}</TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.totalItem + ' ' + value.typeUnit}
                  </TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.warehouse || '-'}
                  </TableCell>

                  <TableCell align='right' className={classes.cellCollapse}>
                    <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
