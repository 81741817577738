import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  Button,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  InputLabel
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { GREEN, WHITE } from 'constants/colors';

interface Props {
  openExport: boolean;
  date: string;
  dateStart: string;
  dateEnd: string;
  status: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  setDateStart: React.Dispatch<React.SetStateAction<string>>;
  setDateEnd: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  handleExport: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    padding: '24px'
  },
  next: {
    color: WHITE,
    backgroundColor: GREEN
  }
});

const ExportModal: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { openExport, date, dateStart, dateEnd, status, setDate, setDateStart, setDateEnd, setStatus, handleClose, handleExport } = props;

  return (
    <Dialog open={openExport} onClose={handleClose} maxWidth='sm' classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Export SO Penjualan</DialogTitle>
      <DialogContent>
        <Grid direction='row' container justify='space-between' spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <TextField
              fullWidth
              label={translate('date')}
              value={date}
              type='date'
              InputLabelProps={{ shrink: true }}
              onChange={e => setDate(e.target.value)}
              disabled={dateStart !== '' || dateEnd !== ''}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <TextField
              id='status'
              fullWidth
              label={translate('selectStatus')}
              value={status}
              onChange={e => setStatus(e.target.value as string)}
              select
            >
              <MenuItem key={0} selected>
                {translate('selectStatus')}
              </MenuItem>

              <MenuItem value={'PENDING'} key={1}>
                {translate('pending')}
              </MenuItem>

              <MenuItem value={'CONFIRMATION'} key={2}>
                {translate('confirm')}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <TextField
              fullWidth
              label='Tanggal Mulai'
              value={dateStart}
              InputLabelProps={{ shrink: true }}
              type='date'
              onChange={e => setDateStart(e.target.value)}
              disabled={date !== ''}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <TextField
              fullWidth
              label='Tanggal Berakhit'
              value={dateEnd}
              type='date'
              InputLabelProps={{ shrink: true }}
              onChange={e => setDateEnd(e.target.value)}
              disabled={date !== ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          {translate('cancelled')}
        </Button>
        <Button disabled={dateStart === '' && dateEnd === '' && date === '' && status === ''} onClick={handleExport} className={classes.next}>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
