import React, { FC } from 'react';
import { Breadcrumbs, Typography, makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import useRouter from 'hooks/useRouter';
import { useLanguage } from 'contexts/LanguageContext';
import ROUTE_PATHS from 'constants/routePaths';

const useStyles = makeStyles({
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  },
  link: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px'
  },
  breadCrumb: {
    marginTop: '3px'
  }
});

const Breadcrumb: FC = () => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { location, history } = useRouter();
  const path = location.pathname.split('/').filter(x => x);

  const handleClick = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };

  /**
   * It takes a string, replaces all underscores and dashes with spaces, trims the string, capitalizes
   * the first letter of each word, and then adds a space between words that are camel cased
   * @param {string} text - string - The text to be converted to title case.
   * @returns A function that takes a string and returns a string.
   */
  const toTitleCase = (text: string) => {
    const temp = text
      .replace(/(_|-)/g, ' ')
      .trim()
      .replace(/\w\S*/g, function(str) {
        return str.charAt(0).toUpperCase() + str.substr(1);
      })
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    return temp;
  };

  /**
   * It takes a string, converts it to title case, replaces all spaces with nothing, and then uses that
   * string as a key to get a value from an object.
   * @param {string} path - string - the path of the current route
   * @returns The return value is a string.
   */

  const getBreadcrumb = (path: string) => {
    /* Getting the value of the key of the object ROUTE_PATHS. */
    if (ROUTE_PATHS[toTitleCase(path).replace(/ /g, '') as keyof typeof ROUTE_PATHS]) {
      const pathNama = ROUTE_PATHS[toTitleCase(path).replace(/ /g, '') as keyof typeof ROUTE_PATHS][languageCode];
      return pathNama;
    }

    return path;
  };

  return (
    <Breadcrumbs key={1} aria-label='breadcrumb' className={classes.breadCrumb}>
      <Link key={99} color='inherit' href='/' onClick={handleClick('/')}>
        {translate('home')}
      </Link>
      {path.map((value, index) =>
        index === path.length - 1 ? (
          <Typography key={index} className={classes.active} color='primary'>
            {getBreadcrumb(value)}
          </Typography>
        ) : (
          <Link
            key={index + 1}
            color='inherit'
            href={`/${path.slice(0, index + 1).join('/')}`}
            onClick={handleClick(`/${path.slice(0, index + 1).join('/')}`)}
            className={classes.link}
          >
            {getBreadcrumb(value)}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
