import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import { PRIMARY_SECONDARY, WHITE } from 'constants/colors';
import React, { memo } from 'react';

const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  },
  refreshButton: {
    backgroundColor: PRIMARY_SECONDARY,
    color: WHITE,
    padding: '.3em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: PRIMARY_SECONDARY
    }
  }
});

interface Props {
  dateLabel: string[];
  onClick: { isLoadingDownload: boolean; handleRefresh: () => void };
}
const HeaderSection = ({ dateLabel, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={12}>
        <FlexBox columnGap={1} alignItems='center' alignContent='flex-start'>
          <IconButton aria-label='refresh' className={classes.refreshButton} disableRipple={false} size='small' onClick={onClick.handleRefresh}>
            <RefreshOutlined />
          </IconButton>

          <Grid item>
            <Typography variant='h6'>{dateLabel[0]}</Typography>
            <Typography color='textSecondary'>{dateLabel[1]}</Typography>
          </Grid>
        </FlexBox>
      </Grid>
    </Grid>
  );
};

export default memo(HeaderSection);
