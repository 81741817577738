import React, { FC, useEffect, useState, useContext } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, makeStyles, Button, Tooltip } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { PARTNER_BASE_URL, ZONE_BASE_URL } from 'constants/url';
import { Pagination } from '@material-ui/lab';
import { WHITE, PRIMARY, GREEN } from 'constants/colors';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
import PartnerTable from './components/PartnerTable';
import PartnerModal from './components/PartnerModal';
import PublishIcon from '@material-ui/icons/Publish';
import PartnerUploadModal from './components/PartnerUploadModal';
import RefreshIcon from '@material-ui/icons/Refresh';
import useRole from 'hooks/useRole';
import useDebounced from 'hooks/useDebounced';

const useStyles = makeStyles({
  fourthGrid: {
    marginTop: 20
  },
  btnImport: {
    background: WHITE,
    color: PRIMARY
  }
});

const PartnerPage: FC = () => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [partners, setPartners] = useState<PartnerModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [partnerId, setPartnerId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('ALL');
  const [email, setEmail] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openUploadForm, setOpenUploadForm] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const { currentUser } = useContext(CurrentUserContext);

  const [zone, setZone] = useState<number[]>([]);
  const [zones, setZones] = useState<ZoneModel[]>([]);

  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });
  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });
  const fetchData = async () => {
    setIsLoadingData(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('keyword', name);
      }

      if (email) {
        params.append('email', email);
      }

      if (cellPhoneNumber) {
        params.append('number', cellPhoneNumber);
      }

      if (partnerId) {
        params.append('partnerId', partnerId);
      }

      if (isSuperVisor || isSales) {
        if (currentUser) {
          params.append('Zone', (currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');
        }
      } else {
        if (zone.length > 0) {
          if (zone.some(value => value === 0)) {
            params.append('new', 'true');
          } else {
            params.append('Zone', zone.join(','));
          }
        }
      }
      if (!type.includes('ALL')) {
        params.append('partnerType', type);
      } else {
        params.append('partnerType', 'CUSTOMER,RESELLER,SUPPLIER');
      }

      params.append('orderBy', orderBy);
      params.append('order', order);
      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setPartners(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fecthZone = async () => {
    const params = new URLSearchParams();
    if (isSuperVisor || isSales) {
      params.append('route', (currentUser && currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');
    }
    params.append('orderBy', 'name');
    params.append('ordered', 'asc');
    params.append('perPage', '1000');
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setZones(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deletePartner = async () => {
    try {
      await axios.delete(`${PARTNER_BASE_URL}/${selectedId}`);
      setPartners(partners.filter(value => value.id !== selectedId));
      handleSnackBar(true, 'success', 'Mitra berhasil dihapus.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Mitra gagal dihapus.');
    } finally {
      setConfirmationDelete(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmationSnackbar = () => {
    setOpenSnackbar(false);
    setOpen(false);
  };

  const handleOpenUploadForm = () => {
    setOpenUploadForm(true);
  };

  const handleCancelOpenUploadForm = () => {
    setOpenUploadForm(false);
  };

  const handleRefresh = () => {
    setCurrentPage(1);
    setOrder('desc');
    setOrderBy('id');
    setPartnerId('');
    setName('');
    setEmail('');
    setCellPhoneNumber('');
    setType(type);
    setZone([]);
  };

  useEffect(() => {
    if (!name && !email && !partnerId && !cellPhoneNumber && !type) {
      return;
    }

    setCurrentPage(1);
  }, [name, email, partnerId, cellPhoneNumber, type]);

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    fecthZone();
  }, []);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, name, email, partnerId, cellPhoneNumber, type, isSales, isSuperVisor, currentUser, zone]);

  return (
    <Page title={translate('partners')}>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1' component='h1'>
              {translate('allPartners')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title={translate('refresh')}>
                <Button onClick={handleRefresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <Typography variant='h6'>{`${translate('showing')} ${total || 0} ${translate('partners')} (${from || 0} - ${to || 0} ${translate(
                'from'
              )} ${total || 0})`}</Typography>
            </Grid>

            <Grid item container justify='flex-end' alignItems='center' spacing={1} xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item>
                {(isAdmin || isSuperAdmin) && (
                  <Tooltip title='Import (.xlsx)'>
                    <Button color='inherit' onClick={handleOpenUploadForm} className={classes.btnImport}>
                      <PublishIcon />
                    </Button>
                  </Tooltip>
                )}
              </Grid>

              <Grid item>
                <Button onClick={() => setOpen(true)}>
                  <AddBox fontSize='small' /> &nbsp; {translate('addPartner')}
                </Button>
              </Grid>
            </Grid>

            <PartnerTable
              isLoadingData={isLoadingData}
              partners={partners}
              order={order}
              orderBy={orderBy}
              name={name}
              isDelete={isAdmin || isSuperAdmin}
              zone={zone}
              setZone={setZone}
              zones={zones}
              setName={setName}
              type={type}
              isAdmin={isAdmin}
              isSuperAdmin={isSuperAdmin}
              setType={setType}
              partnerId={partnerId}
              setPartnerId={setPartnerId}
              cellPhoneNumber={cellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              handleConfirmationDelete={handleConfirmationDelete}
            />

            <Grid container justify='center' item xl={12} md={12} sm={12} className={classes.fourthGrid}>
              <Grid item>
                {partners.length > 0 && (
                  <Pagination
                    count={count}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={currentPage}
                    boundaryCount={2}
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>

            <PartnerModal
              partners={partners}
              open={open}
              typeUser={(currentUser && currentUser.type) || TypeUser.SALES || TypeUser.SUPERVISOR}
              setOpen={setOpen}
              setPartners={setPartners}
              handleSnackBar={handleSnackBar}
            />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmationSnackbar : handleCloseSnackbar}
              onConfirm={handleCloseSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={'danger'}
              titleMessage={'Delete'}
              message={translate('areYouSureYouDeleteThisData')}
              open={confirmationDelete}
              handleClose={handleCloseConfirmationDelete}
              onConfirm={deletePartner}
            />

            <PartnerUploadModal
              open={openUploadForm}
              handleCancel={handleCancelOpenUploadForm}
              setPartners={setPartners}
              handleSnackBar={handleSnackBar}
              setFile={setFile}
              file={file}
              setCount={setCount}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default PartnerPage;
