import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableRow } from '@material-ui/core';
import { TableCellEnd, TableCellStart } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  purchaseOrderItem: PurchaseOrderItemModel;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { purchaseOrderItem, isLoading } = props;

  return (
    <TableRow>
      <TableCellStart>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : purchaseOrderItem.productName || ''}</TableCellStart>
      <TableCellEnd>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : purchaseOrderItem.totalItem + ' ' + purchaseOrderItem.typeUnit}
      </TableCellEnd>
    </TableRow>
  );
};

export default BodyRow;
