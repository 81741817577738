import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableBody, TableHead, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import HeaderRow from 'components/HeaderRow';
import { dummyUser } from 'utils/dummy';
import { TableCustom } from 'components/Table';

interface Props {
  isLoadingData: boolean;
  isUpdateStatus: boolean;
  users: UserDetailsModel[];
  handleOpenEditUser: (userIndex: number) => React.MouseEventHandler;
  handleCancelEditUser: () => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  updateIndividualUser: (userIndex: number) => (updatedUserProperties: Partial<UserDetailsModel>) => void;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleStatus: (id: number) => void;
}

const UserTable: FC<Props> = props => {
  const { translate } = useLanguage();
  const { isLoadingData, isUpdateStatus, users, setOpenSnackbar, setSnackbarVarient, handleOpenEditUser, handleStatus } = props;
  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            headers={[
              { label: 'ID', pL: '25', pR: '25px', verticalAlign: 'top' },
              { label: translate('level'), pL: '25px', pR: '25px', verticalAlign: 'top' },
              { label: 'USERNAME', pL: '25px', pR: '25px', verticalAlign: 'top' },
              { label: 'NAMALENGKAP', pL: '25px', pR: '25px', verticalAlign: 'top' },
              { label: 'EMAIL', pL: '25px', pR: '25px', verticalAlign: 'top' },
              { label: translate('handphoneNo'), pL: '25px', pR: '25px', verticalAlign: 'top' },
              { label: '', pL: '25px', pR: '25px', verticalAlign: 'top' }
            ]}
          />
        </TableHead>
        <TableBody>
          {isLoadingData
            ? [1, 2, 3, 4, 5, 6].map(index => (
                <BodyRow
                  key={index}
                  user={dummyUser}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  isLoadingData={isLoadingData}
                  handleStatus={handleStatus}
                  isUpdateStatus={isUpdateStatus}
                  onEditUser={handleOpenEditUser(index)}
                />
              ))
            : users.map((user, index) => (
                <BodyRow
                  key={user.id}
                  user={user}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  isLoadingData={isLoadingData}
                  onEditUser={handleOpenEditUser(index)}
                  handleStatus={handleStatus}
                  isUpdateStatus={isUpdateStatus}
                />
              ))}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default UserTable;
