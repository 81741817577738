import { Grid, makeStyles, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, Theme, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { a11yProps, TabPanel } from 'components';
import { BLACK } from 'constants/colors';
import React, { FC, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import ListProduct from './ListProduct';
import PartnerModal from './PartnerModal';

interface Props {
  salesOrder: SalesOrderModel;
  company: CompanyModel;
  isLoadingData: boolean;
  salesOrderItem: SalesOrderItemModel[];
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },

  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK,
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  gridContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 10 + 'px'
    }
  }
}));

const SalesOrderList: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { salesOrder, isLoadingData, company, salesOrderItem, handleOpenImage } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item lg={6} sm={12} md={6} xs={12} className={classes.gridContainer}>
        <Typography variant='h5' align='left'>
          {translate('companyName')}
        </Typography>

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.address || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : `${translate('phone')}: ` + company.phoneNumber}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (company.cellPhoneNumber || '-')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} sm={12} md={6} xs={12}>
        <Typography variant='h5' align='right'>
          {translate('salesOrdersFrom')}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  align='right'
                  style={{ fontWeight: 500, color: BLACK }}
                  className={classes.cellOrderRightUnderline}
                  onClick={() => setOpen(true)}
                >
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : salesOrder.partnerName ? salesOrder.partnerName : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : salesOrder.Partner ? (
                    `${salesOrder.Partner.address} ${salesOrder.Partner.remarkAddress || '-'}`
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : salesOrder.Partner ? (
                    `${translate('phone')}: ` + (salesOrder.Partner.phoneNumber || '-')
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : salesOrder.Partner ? (
                    'No Hp: ' + (salesOrder.Partner.cellPhoneNumber || '-')
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : salesOrder.Partner ? 'Email: ' + (salesOrder.Partner.email || '-') : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid xs={12}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <Tabs
            value={0}
            indicatorColor='primary'
            textColor='inherit'
            variant='scrollable'
            scrollButtons='auto'
            style={{ borderBottom: '1px solid #E0E0E0' }}
          >
            <Tab label={`${translate('allProducts')} (${salesOrderItem.length})`} {...a11yProps(0)} />
          </Tabs>

          <TabPanel value={0} index={0}>
            <ListProduct salesOrderItem={salesOrderItem} handleOpenImage={handleOpenImage} isLoadingData={isLoadingData} />
          </TabPanel>
        </Grid>
      </Grid>

      <PartnerModal partners={salesOrder} open={open} setOpen={setOpen} onCancel={handleOnClose} />
    </>
  );
};

export default SalesOrderList;
