import { Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import axios from 'axios';
import { Breadcrumb, Page } from 'components';
import { COURIER_BASE_URL } from 'constants/url';
import useRouter from 'hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { dummyUser } from 'utils/dummy';
import ContentDelivery from './components/ContentDelivery';
import ContentDetail from './components/ContentDetail';
import ContentInfomation from './components/ContentInfomation';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  }
}));
export interface TcourierSalary<T, P> {
  isLoading: boolean;
  data: T & { courierSalaryItem: P };
}
const CourierSalaryDetail = () => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { match } = useRouter();
  const params = match.params.id;

  const [courierSalaryResource, setCourierSalaryResource] = useState<TcourierSalary<UserDetailsModel, CourierSalary[]>>({
    isLoading: true,
    data: { ...dummyUser, courierSalaryItem: [] }
  });

  const [isAction, setIsAction] = useState<{ isOpen: boolean; id: number }>({
    isOpen: false,
    id: 0
  });

  const fetchData = async () => {
    setCourierSalaryResource(prev => ({
      ...prev,
      isLoading: true
    }));
    try {
      const { data } = await axios.get(`${COURIER_BASE_URL}/courierSalaryId/${+params}`);

      setCourierSalaryResource(prev => ({
        ...prev,
        isLoading: false,
        data: {
          ...prev.data,
          courierSalaryItem: data.data.data.map((value: any) => ({
            ...value,
            courierDelivery: value.courierDelivery.data
          })),
          contactNumber: data.contactNumber,
          email: data.email,
          isActive: data.isActive,
          name: data.name
        }
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDelivery = (id: number) => setIsAction(prev => ({ ...prev, isOpen: true, id }));

  const handleClose = () => setIsAction(prev => ({ ...prev, isOpen: false }));
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title='Komisi Kurir detail'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            {translate('courierCommission')}
          </Typography>
          <Breadcrumb />
        </Grid>
        <Grid xs={12}>
          <ContentInfomation courierSalaryResource={courierSalaryResource} />
          <ContentDetail courierSalaryResource={courierSalaryResource} handleOpenDelivery={handleOpenDelivery} />
          <ContentDelivery
            isAction={isAction}
            deliveryResource={
              courierSalaryResource.data.courierSalaryItem.filter(value => value.id === isAction.id).length > 0
                ? courierSalaryResource.data.courierSalaryItem.filter(value => value.id === isAction.id)[0].courierDelivery
                : []
            }
            handleClose={handleClose}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default CourierSalaryDetail;
