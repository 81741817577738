import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Tooltip, IconButton } from '@material-ui/core';
import { EditOutlined, DeleteOutline } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';

interface Props {
  wareHouse: WareHouseModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
  onUpdate: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { translate } = useLanguage();
  const { wareHouse, isLoading, onDelete, onUpdate } = props;

  return (
    <TableRowCustom>
      <TableCellStart>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : wareHouse.name}</TableCellStart>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : wareHouse.address}</TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : wareHouse.description}</TableCellMiddle>
      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <>
            <Tooltip title={translate('deleteWarehouse')}>
              <IconButton size='small' onClick={onDelete}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('updateWarehouse')}>
              <IconButton size='small' onClick={onUpdate}>
                <EditOutlined fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
