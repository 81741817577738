import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { YELLOW } from 'constants/colors';
import useRouter from 'hooks/useRouter';
import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const ModalPermission: FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles();
  const { translate } = useLanguage();

  const handleClick = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <WarningRounded style={{ color: YELLOW, fontSize: '4rem' }} />
          <Typography className={classes.titleContainer}>Informasi Penting</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Typography align='center'>Mohon maaf, Anda tidak mempunyai akses ini.</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => handleClick()}>
              {translate('close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPermission;
