import React, { FC, Fragment, useState, useEffect } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios from 'axios';
import {
  makeStyles,
  TextField,
  MenuItem,
  Tabs,
  Tab,
  Button,
  Typography,
  Grid,
  Input,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Chip,
  CircularProgress,
  FormHelperText,
  Link
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {
  PRODUCT_BASE_URL,
  CATEGORY_BASE_URL,
  GET_CODE_BASE_URL,
  ZONE_BASE_URL,
  WAREHOUSE_BASE_URL,
  PRODUCT_IMAGE_DELETE_BASE_URL
} from 'constants/url';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { dummyCategory } from 'utils/dummy';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TypeUnit from 'typings/enum/TypeUnit';
import NumberFormatMask from 'components/NumberFormatMask';
import ActionSnackBar from 'components/ActionSnackBar';
import ErrorIcon from '@material-ui/icons/Error';
import ReactQuill from 'react-quill';
import { BLACK, GREY, GREY_6, PRIMARY, RED, WHITE } from 'constants/colors';
import { dynamicSort } from 'utils';
import FlexBox from 'components/FlexBox';
import { Close, CloudDownload } from '@material-ui/icons';

interface Props {
  product: ProductModel;
  setProduct: React.Dispatch<React.SetStateAction<ProductModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string, link?: any) => void;
}

const useStyles = makeStyles({
  notesGrid: {
    marginTop: 10
  },
  buttonGrid: {
    marginTop: 10
  },
  tabGrid: {
    marginBottom: 10
  },
  tabUpload: {
    width: '100%'
  },
  formControlRoute: {
    minWidth: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  menuItem: {
    width: 200,
    float: 'left'
  },
  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  }
});

const ProductWizard: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { product, setOpen, handleSnackBar, setProduct } = props;
  const [productName, setProductName] = useState<string>('');
  const [productCode, setProductCode] = useState<string>('');
  const [purchasePrice, setPurchasePrice] = useState<number>(0);
  const [typeUnit, setTypeUnit] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [loadCategory, setLoadCategory] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [productNameMessage, setProductNameMessage] = useState<string>('');
  const [productCodeMessage, setProductCodeMessage] = useState<string>('');
  const [typeUnitMessage, setTypeUnitMessage] = useState<string>('');
  const [categoryMessage, setCategoryMessage] = useState<string>('');
  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [imageUrl, setImageUrl] = useState<ProductImageModel[]>([]);
  const [warehouse, setWarehouse] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [priceReseller, setPriceReseller] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setImageUrl([]);
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });

  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl([...imageUrl, { id: 0, path: '', url: URL.createObjectURL(value) }]);
      setImage([...image, { path: `${productCode}${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob([...imageBlob, value]);
    });
  };

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadCategory(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?product=true&${params.toString()}`);
      setCategories(data.data);
      setLoadCategory(false);
    } catch (error) {
      console.log('error :', error);
      setLoadCategory(false);
    }
  };

  const handleCheckCode = async (submitValidation?: boolean) => {
    setProductCodeMessage('');
    try {
      const { data } = await axios.post(`${PRODUCT_BASE_URL}/validate-code`, {
        id: product.id,
        code: productCode
      });

      if (data.data.validate) {
        setProductCodeMessage('Kode Product sudah ada.');

        if (submitValidation) {
          setSubmit(false);
          setOpenSnackbar(true);
          return;
        }
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (productName === '' || !productName) {
      setProductNameMessage(translate('productnamecannotbeempty'));
      valid = false;
    }

    if (typeUnit === '' && !typeUnit) {
      setTypeUnitMessage(translate('productunitcannotbeempty'));
      valid = false;
    }

    if (!category) {
      setCategoryMessage(translate('categorycannotbeempty'));
      valid = false;
    }

    if (productCode === '' || !productCode) {
      setProductCodeMessage(translate('productcodecannotbeempty'));
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setProductNameMessage('');
    setProductCodeMessage('');
    setTypeUnitMessage('');
    setCategoryMessage('');
  };

  useEffect(() => {
    setProductName(product.productName);
    setProductCode(product.productCode);
    setPurchasePrice(product.purchasePrice);
    setTypeUnit(product.typeUnit);
    setDescription(product.description);
    setCategory(product.Category || dummyCategory);
    setImageUrl(product.ProductImages || []);
    setWarehouse(product.WareHouseId || 0);
    setPrice(product.customerPrice);
    setPriceReseller(product.resellerPrice);
  }, [product]);
  const handleOnSubmit = async () => {
    resetValidation();

    if (!validation()) {
      setSubmit(false);
      return;
    }

    setSubmit(true);

    await handleCheckCode(true);

    try {
      const { data } = await axios.post(PRODUCT_BASE_URL, {
        id: product.id,
        productName,
        productCode,
        purchasePrice,
        description,
        typeUnit,
        WareHouseId: warehouse,
        isProductPackage: false,
        CategoryId: category!.id,
        image: image || [],
        customerPrice: price,
        resellerPrice: priceReseller
      });

      if (data.data.ProductImages.length) {
        let i = 0;
        data.data.ProductImages.map(async (value: any, index: number) => {
          if (image.filter(img => img.path === value.path).length) {
            // @ts-ignore
            const newImageKey = value.path;
            const fileExtension = newImageKey.split('.').pop();

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', `image/${fileExtension}`);

            const config: RequestInit = {
              method: 'PUT',
              headers: myHeaders,
              body: imageBlob[i]
            };

            await fetch(value.url, config)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));

            i++;
          }
        });
      }

      setProduct(data.data);
      setOpen(false);
      handleSnackBar(true, 'success', 'Produk berhasil diperbaharui');
    } catch (error) {
      handleSnackBar(true, 'error', 'Produk gagal diperbaharui');
    } finally {
      setSubmit(false);
    }
  };

  const handleDeleteImage = async (id: number, index: number) => {
    if (id === 0) {
      setImageUrl(imageUrl.filter((val, key) => index !== key));
      setImage(image.filter((val, key) => index !== key));
      setImageBlob(imageBlob.filter((val, key) => index !== key));
    } else {
      try {
        await axios.delete(PRODUCT_IMAGE_DELETE_BASE_URL(id));
        setImageUrl(imageUrl.filter(value => value.id !== id));
      } catch (error) {
        console.log('error :', error);
      }
    }
  };

  return (
    <Grid container direction='row'>
      <Grid xs={12}>
        <Autocomplete
          id='category'
          value={category}
          options={categories}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event: any, value: any) => setCategory(value)}
          onOpen={e => handleSearchCategory('')}
          loading={loadCategory}
          renderInput={params => (
            <TextField
              {...params}
              required
              label={translate('selectProductCategory')}
              size='small'
              onChange={e => handleSearchCategory(e.target.value)}
              variant='outlined'
              error={categoryMessage !== ''}
              helperText={categoryMessage}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadCategory && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </Fragment>
                )
              }}
            />
          )}
        />

        <TextField
          id='productName'
          required
          fullWidth
          label={translate('productName')}
          value={productName}
          onChange={event => setProductName(event.target.value)}
          error={productNameMessage !== ''}
          helperText={productNameMessage}
        />
        <Grid xs={12} container justify='space-between'>
          <FlexBox flex='0.49'>
            <TextField
              id='productCode'
              required
              fullWidth
              label={translate('productCode')}
              value={productCode}
              onChange={event => setProductCode(event.target.value)}
              error={productCodeMessage !== ''}
              helperText={productCodeMessage}
              onBlur={event => handleCheckCode(false)}
            />
          </FlexBox>
          <FlexBox flex='0.49'>
            <TextField
              id='type'
              required
              fullWidth
              select
              value={typeUnit as string}
              label={translate('selectProductUnit')}
              helperText={typeUnitMessage}
              onChange={event => setTypeUnit(event.target.value as string)}
              error={typeUnitMessage !== ''}
            >
              {Object.entries(TypeUnit).map(([value, key]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </FlexBox>
        </Grid>
        <Grid xs={12} container justify='space-between'>
          <FlexBox flex='0.49'>
            <TextField
              id={'productPrice'}
              required
              fullWidth
              label={translate('customersPrice')}
              value={price > 0 ? price : null}
              onChange={event => setPrice(+event.target.value)}
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />
          </FlexBox>
          <FlexBox flex='0.49'>
            <TextField
              id={'productPrice'}
              required
              fullWidth
              label={translate('resellersPrice')}
              value={priceReseller > 0 ? priceReseller : null}
              onChange={event => setPriceReseller(+event.target.value)}
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />
          </FlexBox>
        </Grid>

        <Grid xs={12} container className={classes.containerImage}>
          <Grid xs={12} container direction='row' className={classes.uploadImage} alignItems='center'>
            <Grid xs={2}>
              <CloudDownload color='primary' className={classes.iconLarge} />
            </Grid>
            <Grid xs={10} {...getRootProps()}>
              <Typography variant='h6'>Drag and drop image here</Typography>
              <input {...getInputProps()} />
              <Typography color='textSecondary'>
                Drop image here or click <u className={classes.browser}>browser</u> to upload your image max 3 photos
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} container className={classes.wrapperImage}>
            {imageUrl.map((image, index) => (
              <Grid className={classes.containerBoxImage} key={index}>
                <img className={classes.image} src={image.url} alt='images-product' />
                <Grid container className={classes.closeImage} justify='center' alignItems='center' xs={2}>
                  <Close className={classes.closeIcon} fontSize='small' onClick={() => handleDeleteImage(image.id, index)} />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid xs={12}>
            <ReactQuill id='notes' value={description} onChange={(value: any) => setDescription(value)} placeholder={translate('notes')} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.buttonGrid}>
        <Button color='secondary' disabled={isSubmit} onClick={() => setOpen(false)}>
          {translate('cancelled')}
        </Button>
        &nbsp; &nbsp;
        <Button value={value} onClick={event => handleOnSubmit()} disabled={productCodeMessage !== ''}>
          {isSubmit ? <CircularProgress color='inherit' size={20} /> : translate('save')}
        </Button>
      </Grid>

      <ActionSnackBar
        variant={'error'}
        message={'Kode Produk sudah ada.'}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        Icon={ErrorIcon}
      />
    </Grid>
  );
};

export default ProductWizard;
