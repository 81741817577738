import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { DialogContent, Dialog, Grid, Typography, IconButton } from '@material-ui/core';

import ProductWizard from './components/ProductWizard';
import { Close } from '@material-ui/icons';
import { DialogTitle } from 'components';

interface Props {
  open: boolean;
  setProducts: React.Dispatch<React.SetStateAction<ProductModel[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string, link?: any) => void;
}

const ProductModal: FC<Props> = props => {
  const { translate } = useLanguage();
  const { open, setOpen, handleSnackBar, setProducts } = props;

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>{translate('addNewProduct')}</Typography>
          <IconButton aria-label='close' size='small' onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ProductWizard setOpen={setOpen} handleSnackBar={handleSnackBar} setProducts={setProducts} />
      </DialogContent>
    </Dialog>
  );
};

export default ProductModal;
