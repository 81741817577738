import { TLanguageKey, TLanguages } from 'typings/Language';

/* A type assertion. It is telling the compiler that the value of languages is a const. */
const languages: Record<TLanguageKey, TLanguages> = {
  allPartners: { en: 'All Partners', id: 'Semua Mitra' },
  home: { en: 'Home', id: 'Beranda' },
  partners: { en: 'Partners', id: 'Mitra' },
  partnersName: { en: 'Partners Name', id: 'Nama Mitra' },
  partnersType: { en: 'Partners Type', id: 'Tipe Mitra' },
  handphoneNo: { en: 'Handphone No', id: 'No.Handphone' },
  action: { en: 'Action', id: 'Aksi' },
  allTypes: { en: 'All Types', id: 'Semua tipe' },
  selectPartnerType: { en: 'Select Partner Type', id: 'Pilih tipe' },
  selectPayment: { en: 'Select Payment', id: 'Pilih Pembayaran' },
  delete: { en: 'Delete', id: 'Hapus' },
  addNewPartner: { en: 'Add New Partner', id: 'Tambah mitra baru' },
  choosePartnerType: { en: 'Choose Partner Type', id: 'Pilih tipe mitra' },
  generalInformation: { en: 'General Information', id: 'Informasi umum' },
  name: { en: 'Name', id: 'Nama' },
  contactNo: { en: 'Contact No.', id: 'Nomor Kontak' },
  totalInvoices: { en: 'Total Invoices', id: 'Total keseluruhan invoice' },
  totalRemainingBill: { en: 'Total Remaining Bill', id: 'Total Sisa Tagihan' },
  salesInvoiceReport: { en: 'Sales Invoice Report', id: 'laporan Invoice penjualan' },
  salesInvoice: { en: 'Sales Invoice', id: 'Invoice penjualan' },
  purchaseInvoiceReport: { en: 'Purchase Invoice Report', id: 'laporan invoice pembelian' },
  statusPayment: { en: 'Status Payment', id: 'Status Pembayaran' },
  to: { en: 'To', id: 'Sampai' },
  ceilingAmount: { en: 'Ceiling Amount', id: 'Plafon' },
  paymentDue: { en: 'Payment Due', id: 'Jatuh tempo' },
  addressInformation: { en: 'Address Information', id: 'Informasi alamat' },
  address: { en: 'Address', id: 'Alamat' },
  postalCode: { en: 'Postal Code', id: 'Kode Pos' },
  normal: { en: 'Normal', id: 'Normal' },
  notes: { en: 'Notes', id: 'Catatan' },
  paymentSuccessful: { en: 'Payment Successful', id: 'Berhasil melakukan pembayaran' },
  cancel: { en: 'Cancel', id: 'Batal' },
  save: { en: 'Save', id: 'Simpan' },
  refresh: { en: 'Refresh', id: 'Memuat Ulang' },
  totalBill: { en: 'Total bill', id: 'Total Tagihan' },
  outstanding: { en: 'Outstanding', id: 'Sisa Tagihan' },
  discount: { en: 'Discount', id: 'Diskon' },
  areYouSureYouDeleteThisData: { en: 'Are you sure you delete this data?', id: 'Apakah kamu yakin menghapus data ini?' },
  salesOrderissuccessfulconverttoInvoiceSJ: {
    en: 'Sales Order is successful convert to Invoice & SJ',
    id: 'Sales order berhasil di Konversi menjadi Invoice dan Surat Jalan'
  },
  addPartner: { en: 'Add Partner', id: 'Tambah Mitra' },
  addPartnerSuccessful: { en: 'Add Partner Successful', id: 'Mitra berhasil ditambahkan' },
  AreyousurewanttoconvertSalesOrdertoInvoiceSJ: {
    en: 'Are you sure want to convert Sales Order to Invoice & SJ?',
    id: ' Apakah anda yakin ingin mengkonversi Sales Order menjadi Invoice dan Surat Jalan?'
  },
  ConfirmationConverttoInvoice: { en: 'Confirmation Convert to Invoice & SJ', id: 'Konfirmasi Konversi Invoice dan Surat Jalan' },
  information: { en: 'Information', id: 'Informasi' },
  addressDetail: { en: 'Address Detail', id: 'Detail alamat' },
  partnerID: { en: 'Partner ID', id: 'ID Mitra' },
  phoneNo: { en: 'Phone No.', id: 'No. Telepon' },
  addressNote: { en: 'Address Note', id: 'Remark alamat' },
  editData: { en: 'Edit Data', id: 'Edit data' },
  amountOutstanding: { en: 'Amount Outstanding', id: 'Jumlah terhutang' },
  status: { en: 'Status', id: 'Status' },
  invoiceDate: { en: 'Invoice Date', id: 'Tgl invoice' },
  amountDue: { en: 'Amount Due', id: 'Total tertagih' },
  totalOutstanding: { en: 'Total Outstanding', id: 'Total terhutang' },
  dataNotAvailable: { en: 'Data Not Available', id: 'Data tidak tersedia' },
  partnerSuccessfullyUpdated: { en: 'Partner Successfully Updated', id: 'Mitra berhasil diperbaharui' },
  confirm: { en: 'Confirm', id: 'Konfirmasi' },
  fullyPaid: { en: 'Fully Paid', id: 'Lunas' },
  unpaid: { en: 'Unpaid', id: 'Belum lunas' },
  cancelled: { en: 'Cancelled', id: 'Batal' },
  namecannotbeempty: { en: 'Name cannot be empty', id: 'Nama tidak boleh kosong' },
  productStok: { en: 'Product & Stock', id: 'Produk & Stok' },
  orders: { en: 'Orders', id: 'Order' },
  invoices: { en: 'Invoices', id: 'Invoice' },
  product: { en: 'Product', id: 'Produk' },
  addProduct: { en: 'Add Product', id: 'Tambah Produk' },
  allProducts: { en: 'All Products', id: 'Semua produk' },
  productCategory: { en: 'Product Category', id: 'Kategori Produk' },
  sortBy: { en: 'SortBy:', id: 'Urutkan:' },
  showing: { en: 'Showing', id: 'Menampilkan' },
  sortByLatest: { en: 'Latest', id: 'Terbaru' },
  sortByAtoZ: { en: 'A to Z', id: 'A ke Z' },
  sortByRestock: { en: 'Re-stock', id: 'Re-stok' },
  searchProductName: { en: 'Search Product Name', id: 'Cari nama produk' },
  code: { en: 'Code', id: 'Kode' },
  sellingStock: { en: 'Selling Stock', id: 'Stok Jual' },
  customersPrice: { en: 'Customers Price', id: 'Harga Pelanggan' },
  resellersPrice: { en: 'Resellers Price', id: 'Harga Reseller' },
  new: { en: 'New', id: 'Baru' },
  addNewProduct: { en: 'Add New Product', id: 'Tambah produk baru' },
  selectProductCategory: { en: 'Select Product Category', id: 'Pilih kategori produk' },
  productName: { en: 'Product Name', id: 'Nama produk' },
  productCode: { en: 'Product Code', id: 'Kode produk' },
  selectProductUnit: { en: 'Select Product Unit', id: 'Pilih satuan barang' },
  categorycannotbeempty: { en: 'Category cannot be empty', id: 'Kategori tidak boleh kosong' },
  productnamecannotbeempty: { en: 'Product name cannot be empty', id: 'Nama produk tidak boleh kosong' },
  productcodecannotbeempty: { en: 'Product code cannot be empty', id: 'Kode produk tidak boleh kosong' },
  productunitcannotbeempty: { en: 'Product unit cannot be empty', id: 'Satuan produk tidak boleh kosong' },
  customerspricecannotbeempty: { en: 'Customers price cannot be empty', id: 'Harga customer tidak boleh kosong' },
  resellerspricecannotbeempty: { en: 'Resellers price cannot be empty', id: 'Harga reseller tidak boleh kosong' },
  addProductSuccessful: { en: 'Add Product Successful', id: 'Produk berhasil ditambahkan' },
  unit: { en: 'Unit', id: 'Satuan' },
  purchasePrice: { en: 'Purchase Price', id: 'Harga beli' },
  description: { en: 'Description', id: 'Deskripsi' },
  stockInformation: { en: 'Stock Information', id: 'Keterangan stok' },
  totalNetStock: { en: 'Total Net Stock', id: 'Total stok bersih' },
  totalSellingStock: { en: 'Total Selling Stock', id: 'Total stok jual' },
  stockOnHold: { en: 'Stock On Hold', id: 'Stok tertahan' },
  totalGrossStock: { en: 'Total Gross Stock', id: 'Total stok kotor' },
  from: { en: 'from', id: 'dari' },
  damagedGoodsinWarehouse: { en: 'Damaged Goods in Warehouse (from edit stock)', id: 'Barang rusak gudang (dari edit stok)' },
  allCategory: { en: 'All Category', id: 'Semua kategori' },
  category: { en: 'Category', id: 'Kategori' },
  mainCategory: { en: 'Main Category', id: 'Kategori induk' },
  addCategory: { en: 'Add Category', id: 'Tambah Kategori' },
  categoryName: { en: 'Category Name', id: 'Nama kategori' },
  codecannotbeempty: { en: 'Code cannot be empty', id: 'Kode tidak boleh kosong' },
  addNewCategory: { en: 'Add New Category', id: 'Tambah kategori baru' },
  addCategorySuccessful: { en: 'Add Category Successful', id: 'Kategori berhasil ditambahkan' },
  updateCategory: { en: 'Update Category', id: 'Perbaharui kategori' },
  deleteCategory: { en: 'Delete Category', id: 'Hapus kategori' },
  updateCategoryFailed: { en: 'Update Category Failed', id: 'Kategori gagal diperbaharui' },
  updateCategorySuccessful: { en: 'Update Category Successful', id: 'Kategori berhasil diperbaharui' },
  deletingData: { en: 'Deleting Data', id: 'Menghapus data' },
  confirmation: { en: 'Confirmation', id: 'Konfirmasi' },
  deleteCategorySuccessful: { en: 'Delete Category Successful', id: 'Kategori berhasil dihapus' },
  allStocks: { en: 'All Stocks', id: 'Semua stok' },
  allWarehouses: { en: 'All Warehouses', id: 'Semua gudang' },
  damagedStocksinWarehouse: { en: 'Damaged Stocks in Warehouse', id: 'Stok rusak gudang' },
  warehouse: { en: 'Warehouse', id: 'Gudang' },
  date: { en: 'Date', id: 'Tanggal' },
  time: { en: 'Time', id: 'Jam' },
  reason: { en: 'Reason', id: 'Alasan' },
  editSellingStock: { en: 'Edit Selling Stock', id: 'Edit stok jual' },
  editDamagedStock: { en: 'Edit Damaged Stock', id: 'Edit stok rusak' },
  editDataStockSuccessful: { en: 'Edit Data Stock Successful', id: 'Data stok berhasil diubah' },
  success: { en: 'Success', id: 'Sukses' },
  close: { en: 'Close', id: 'Tutup' },
  stockAdjustment: { en: 'Stock Adjustment', id: 'Stok perubahan' },
  reasonForAdjustment: { en: 'Reason For Adjustment', id: 'Alasan perubahan' },
  selling: { en: 'Selling', id: 'Penjualan' },
  allSales: { en: 'All Sales', id: 'Semua penjualan' },
  resellerSales: { en: 'Reseller Sales', id: 'Penjualan reseller' },
  customerSales: { en: 'Customer Sales', id: 'Penjualan Customer' },
  salesOrders: { en: 'Sales Orders', id: 'Order penjualan' },
  orderNo: { en: 'Order No.', id: 'Nomor Order' },
  orderDate: { en: 'Order Date', id: 'Tgl Order' },
  customerName: { en: 'Customer Name', id: 'Nama customer' },
  totalPrice: { en: 'Total Price', id: 'Total harga' },
  selectStatus: { en: 'Select Status', id: 'Pilih status' },
  pending: { en: 'Pending', id: 'Tertunda' },
  add: { en: 'Add', id: 'Tambah' },
  selectPartner: { en: 'Select Partner', id: 'Pilih mitra' },
  selectCustomer: { en: 'Select Customer', id: 'Pilih Customer' },
  addToCart: { en: 'Add To Cart', id: 'Masuk keranjang' },
  allProductsinCart: { en: 'All Products in Cart', id: 'Semua produk di keranjang' },
  addOtherProduct: { en: 'Add Other Product', id: 'Tambah produk lainnya' },
  unitPrice: { en: 'Unit Price', id: 'Satuan harga' },
  sellingPrice: { en: 'Selling Price', id: 'Harga jual' },
  quantity: { en: 'Quantity', id: 'Kuantitas' },
  deleteItem: { en: 'Delete Item', id: 'Hapus item' },
  areyousureaboutdeletingthisitem: { en: 'Are you sure about deleting this item?', id: 'Apakah kamu yakin ingin menghapus item ini?' },
  subtotal: { en: 'Sub total', id: 'Sub total' },
  inputDeliveryFee: { en: 'Input Delivery Fee', id: 'Input ongkos kirim' },
  inputTotalDiscount: { en: 'Input Total Discount', id: 'Input total diskon' },
  otherDeductions: { en: 'Other Deductions', id: 'Potongan lainnya' },
  totalAmount: { en: 'Total Amount', id: 'Total nominal' },
  createSalesOrders: { en: 'Create Sales Orders', id: 'Buat order penjualan' },
  salesOrdersCreated: { en: 'Sales Orders Created', id: 'Order berhasil dibuat' },
  chooseAction: { en: 'Choose Action', id: 'Pilih tindakan' },
  salesOrdersID: { en: 'Sales Orders ID', id: 'ID penjualan' },
  companyName: { en: 'Company Name', id: 'Nama perusahaan' },
  phone: { en: 'Phone', id: 'Telp' },
  salesOrdersFrom: { en: 'Sales Orders From', id: 'Order penjualan dari' },
  deliveryFee: { en: 'Delivery Fee', id: 'Ongkos kirim' },
  totalDiscount: { en: 'Total Discount', id: 'Total diskon' },
  editSalesOrders: { en: 'Edit Sales Orders', id: 'Edit penjualan' },
  deleteSalesOrders: { en: 'Delete Sales Orders', id: 'Hapus penjualan' },
  updateSalesOrdersSuccessful: { en: 'Update Sales Orders Successful', id: 'Sales order berhasil di update' },
  deleteSalesOrdersSuccessful: { en: 'Delete Sales Orders Successful', id: 'Orderan berhasil dihapus' },
  confirmationToConvertInvoice: { en: 'Confirmation To Convert Invoice', id: 'Konfirmasi convert invoice' },
  doyouwanttoconvertSalesOrderstoInvoice: {
    en: 'Do you want to convert Sales Orders to Invoice?',
    id: 'Apakah anda yakin ingin mengconvert Sales Order menjadi invoice?'
  },
  fromDate: { en: 'From Date', id: 'Dari tanggal' },
  supplierName: { en: 'Supplier Name', id: 'Nama supplier' },
  invoiceStatus: { en: 'Invoice Status', id: 'Status invoice' },
  purchaseOrder: { en: 'Purchase Order', id: 'Order pembelian' },
  itemReceivedDate: { en: 'Item Received Date', id: 'Tanggal terima barang' },
  deliveryOrderDate: { en: 'Delivery Order Date', id: 'Tanggal surat jalan' },
  selectSupplier: { en: 'Select Supplier', id: 'Pilih supplier' },
  logisticFee: { en: 'Logistic Fee', id: 'Biaya logistik' },
  selectWarehouse: { en: 'Select Warehouse', id: 'Pilih Gudang' },
  pleaseselectwarehousebeforeaddproduct: {
    en: 'Please select warehouse before add product',
    id: 'Harap memilih gudang terlebih dahulu untuk memasukan produk.'
  },
  totalItem: { en: 'Total Item', id: 'Total item' },
  quantitycannotbeempty: { en: 'Quantity cannot be empty', id: 'Kuantitas tidak boleh kosong' },
  productcannotbeempty: { en: 'Product cannot be empty', id: 'Produk tidak boleh kosong' },
  purchaseOrederCreatedSuccessfully: { en: 'Purchase Oreder Created Successfully', id: 'Order pembelian berhasil dibuat' },
  descriptions: { en: 'Descriptions', id: 'Keterangan' },
  purchaseOrderFrom: { en: 'Purchase Order From', id: 'Order pembelian dari' },
  purchaseOrderNo: { en: 'Purchase Order No.', id: 'Nomor PO' },
  purchaseOrderInvoices: { en: 'Purchase Order Invoices', id: 'Purchase Order Invoice' },
  searchCourierName: { en: 'Search Courier Name', id: 'Cari nama kurir disini' },
  print: { en: 'Print', id: 'Cetak' },
  invoiceNo: { en: 'Invoice No.', id: 'Nomor invoice' },
  payment: { en: 'Payment', id: 'Pembayaran' },
  issuedDate: { en: 'Issued Date', id: 'Tanggal terbit' },
  salesOrdersInvoice: { en: 'Sales Orders Invoice', id: 'Invoice Penjualan' },
  deliveryOrdersReference: { en: 'Delivery Orders Reference', id: 'Ref SJ' },
  salesOrdersReference: { en: 'Sales Orders Reference', id: 'Ref SO' },
  paymentMethod: { en: 'Payment Method', id: 'Metode pembayaran' },
  paymentDate: { en: 'Payment Date', id: 'Tanggal pembayaran' },
  fullPayment: { en: 'Full Payment', id: 'Bayar full' },
  partialPayment: { en: 'Partial Payment', id: 'Bayar sebagian' },
  payableAmount: { en: 'Payable Amount', id: 'Jumlah bayar' },
  back: { en: 'Back', id: 'Kembali' },
  pay: { en: 'Pay', id: 'Bayar' },
  exit: { en: 'Exit', id: 'Keluar' },
  remark: { en: 'Remark', id: 'Remark' },
  printInvoice: { en: 'Print Invoice', id: 'Cetak invoice' },
  receivedby: { en: 'Received by', id: 'Diterima oleh' },
  createNewInvoice: { en: 'Create New Invoice', id: 'Buat invoice baru' },
  newPurchaseInvoice: { en: 'New Purchase Invoice', id: 'Invoice pembelian baru' },
  selectPurchaseOrderNo: { en: 'Select Purchase Order No.', id: 'Pilih nomor PO' },
  invoiceNofromsupplier: { en: 'Invoice No. from supplier', id: 'No invoice dari supplier' },
  receivedDatebyWarehouse: { en: 'Received Date by Warehouse', id: 'Tanggal terima di gudang' },
  netCapital: { en: 'Net Capital', id: 'Modal bersih' },
  logisticsFeesarenotthesameasthetotalLogisticsFeesonthePurchaseOrder: {
    en: 'Logistics Fees are not the same as the total Logistics Fees on the Purchase Order',
    id: 'Biaya Logistik tidak sama dengan jumlah Biaya Logistik pada PO.'
  },
  pricecannotbeempty: { en: 'Price cannot be empty', id: 'Harga tidak boleh kosong' },
  createInvoicesuccessful: { en: 'Create Invoice successful', id: 'Invoice berhasil dibuat' },
  downloadPDF: { en: 'Download PDF', id: 'Download PDF' },
  suppliersInvoiceNo: { en: "Supplier's Invoice No.", id: 'Nomor invoice supplier' },
  purchaseNo: { en: 'Purchase No.', id: 'Nomor pembelian' },
  deliveryOrder: { en: 'Delivery Order', id: 'Surat jalan' },
  report: { en: 'Report', id: 'Laporan' },
  deliverOrderNo: { en: 'Deliver Order No.', id: 'Nomor surat jalan' },
  totalCrate: { en: 'Total Crate', id: 'Total koli' },
  courierName: { en: 'Courier Name', id: 'Nama kurir' },
  printDeliveryOrder: { en: 'Print Delivery Order', id: 'Cetak surat jalan' },
  thisDeliveryOrderhasbeenprintedpreviously: { en: 'This Delivery Order has been printed previously', id: 'Surat jalan ini pernah dicetak' },

  //Next week
  printedDate: { en: 'Printed Date', id: 'Tanggal dicetak' },
  crate: { en: 'Crate', id: 'Koli' },
  page: { en: 'Page:', id: 'Hal:' },
  operationalCost: { en: 'Operational Cost', id: 'Biaya Operasional' },
  searchOperationalHere: { en: 'Search Operational Here', id: 'Cari operasional disini' },
  addCost: { en: 'Add Cost', id: 'Tambah biaya' },
  attachment: { en: 'Attachment', id: 'Lampiran' },
  addOperationalCost: { en: 'Add Operational Cost', id: 'Tambah biaya operasional' },
  viewAttachment: { en: 'View Attachment', id: 'Lihat lampiran' },
  areyousureaboutdeletingthisoperationalcost: {
    en: 'Are you sure about deleting this operational cost?',
    id: 'Apakah kamu yakin ingin menghapus biaya operation ini?'
  },
  income: { en: 'Income', id: 'Pendapatan' },
  incomeDetails: { en: 'Income Details', id: 'Rincian Pendapatan' },
  generalIncome: { en: 'General Income', id: 'Pendapatan Umum' },
  totalShippingCost: { en: 'Total Shipping Cost', id: 'Total Ongkos Kirim' },
  totalOtherDiscounts: { en: 'Total Other Discounts', id: 'Total Potongan Lainya' },
  totalSalesDiscount: { en: 'Total Sales Discount', id: 'Total Diskon Penjualan' },
  totalIncome: { en: 'Total Income', id: 'Total Pendapatan' },
  deleteOperationalCost: { en: 'Delete Operational Cost', id: 'Hapus biaya operation' },
  courierCommission: { en: 'Courier Commission', id: 'Komisi kurir' },
  incomeStatement: { en: 'Income Statement', id: 'Laporan Laba Rugi' },
  incomeStatementDetail: {
    en: 'Income statement based on income and expenses in a certain period',
    id: 'Laporan laba rugi berdasarkan dari pemasukan dan pengeluaran pada periode tertentu'
  },
  reportOrder: { en: 'Report Order', id: 'Laporan Laba Rugi' },
  reportOrderDetail: {
    en: 'View a more detailed summary of sales and purchases reports based on a certain period',
    id: 'Lihat rekapan laporan penjualan dan pembelian lebih rinci berdasarkan periode tertentu'
  },

  commission: { en: 'Commission', id: 'Komisi' },
  lastCalculation: { en: 'Last Calculation', id: 'Kalkulasi terakhir' },
  deliveryHistory: { en: 'Delivery History', id: 'Riwayat pengantaran' },
  commissionCalculation: { en: 'Commission Calculation', id: 'Kalkulasi Komisi' },
  createCommissionCalculation: { en: 'Create Commission Calculation', id: 'Buat Kalkulasi Komisi' },
  courierDeliveryHistory: { en: 'Courier Delivery History', id: 'Riwayat pengantaran kurir' },
  periode: { en: 'Periode: September 2022', id: 'Periode: September 2022' },
  selectCourier: { en: 'Select Courier', id: 'Pilih kurir' },
  selectMonthandYear: { en: 'Select Month and Year', id: 'Pilih bulan dan tahun' },
  commissionDetail: { en: 'Commission Detail', id: 'Detail komisi' },
  user: { en: 'User', id: 'Pengguna' },
  usersettingsandaccessrights: { en: 'User settings and access rights', id: 'Pengaturan pengguna dan hak akses pengguna' },
  company: { en: 'Company', id: 'Perusahaan' },
  companyinformationincludenameLogoandaddress: {
    en: 'Company information include name, logo and address',
    id: 'Informasi perusahaan terkait nama, logo dan alamat'
  },
  warehouseStockSettings: { en: 'Warehouse Stock Settings', id: 'Pengaturan stok pergudangan' },
  search: { en: 'Search', id: 'Pencarian' },
  addNewUser: { en: 'Add New User', id: 'Tambah user baru' },
  fullName: { en: 'Full Name', id: 'Nama lengkap' },
  personalInformation: { en: 'Personal Information', id: 'Personal informasi' },
  level: { en: 'Level', id: 'Level' },
  purchaseHistory: { en: 'Purchase History', id: 'Riwayat pembelian' },
  companyInformation: { en: 'Company Information', id: 'Info Perusahaan' },
  companyWebsite: { en: 'Company Website', id: 'Website perusahaan' },
  companyEmail: { en: 'Company E-mail', id: 'Email perusahaan' },
  companyAddress: { en: 'Company Address', id: 'Alamat perusahaan' },
  companyBankAccountNo: { en: 'Company Bank Account No.', id: 'Rekening perusahaan' },
  bankName: { en: 'Bank Name', id: 'Nama bank' },
  accountName: { en: 'Account Name', id: 'Nama Akun' },
  companyLogo: { en: 'Company Logo', id: 'Logo perusahaan' },
  noLogoFound: { en: 'No Logo Found', id: 'Tidak ada logo' },
  addBankAccountNo: { en: 'Add Bank Account No.', id: 'Tambah akun bank' },
  bankCode: { en: 'Bank Code', id: 'Kode bank' },
  accountNo: { en: 'Account No.', id: 'Nomor rekening' },
  uploadLogo: { en: 'Upload Logo', id: 'Unggah logo' },
  warehouseName: { en: 'Warehouse Name', id: 'Nama gudang' },
  setting: { en: 'Setting', id: 'Pengaturan' },
  addWarehouse: { en: 'Add Warehouse', id: 'Tambah Gudang' },
  addWarehouseSuccessful: { en: 'Add Warehouse Successful', id: 'Gudang berhasil ditambahkan' },
  updateWarehouse: { en: 'Update Warehouse', id: 'Perbaharui gudang' },
  deleteWarehouse: { en: 'Delete Warehouse', id: 'Hapus gudang' },
  searchDescription: { en: 'Search Description', id: 'Cari deskripsi' },

  areyousureyouwanttologout: { en: 'Are you sure you want to logout?', id: 'Apakah yakin anda ingin keluar ?' },
  email: { en: 'Email', id: 'Alamat email' },
  password: { en: 'Password', id: 'Kata sandi' },
  login: { en: 'Login', id: 'Masuk' },
  logout: { en: 'Log Out', id: 'Keluar' },
  applicationLogs: { en: 'Application Logs', id: 'Log Aplikasi' },
  forgotPassword: { en: 'Forgot Password', id: 'Lupa Kata Sandi' },
  resetPassword: { en: 'Reset Password', id: 'Atur ulang kata sandi' },
  backtoLogin: { en: 'Back to Login', id: 'Kembali Ke halaman Login' },
  pleasecheckyouremailLinktoresetpasswordhasbeensent: {
    en: 'Please check your email, link to reset password has been sent.',
    id: 'Mohon cek email Anda. Tautan untuk reset password sudah dikirm ke email'
  },
  inputEmailPassword: { en: 'Input Email & Password', id: 'Tolong masukkan Email & kata sandi.' },
  emailorpasswordnotcorrect: { en: 'Email or password not correct.', id: 'Email atau kata sandi anda salah.' },
  expenses: { en: 'Expenses', id: 'biaya' },
  dateFilter: { en: 'Date Filter', id: 'Filter tanggal' },
  changePassword: { en: 'Change Password', id: 'Ubah Kata Sandi' },
  addresscannotbeempty: { en: '	Address cannot be empty	', id: '	Alamat tidak boleh kosong	' },
  confirmPassword: { en: 'Confirm Password', id: 'Konfirmasi Kata Sandi' }
} as const;

export default languages;
