import React, { FC, Fragment, useContext } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { makeStyles, Table, TableBody, TableRow, withStyles, Typography, Divider, TableContainer } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
import { YELLOW } from 'constants/colors';
interface Props {
  product: ProductModel;
  isLoading: boolean;
  isSales: boolean;
}

const useStyles = makeStyles({
  cell: {
    width: '50%',
    paddingLeft: 0
  },
  label: {
    color: '#828282',
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 4
  },
  text: {
    fontWeight: 700,
    color: '#000000',
    fontSize: 15
  },
  price: {
    fontWeight: 700,
    color: YELLOW,
    fontSize: 15
  },
  tableDetail: {
    marginTop: 10
  },
  titleDetail: {
    marginTop: 20
  },
  priceTable: {
    maxHeight: 400
  }
});

const TableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);

const ProductDetail: FC<Props> = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const classes = useStyles();
  const { translate } = useLanguage();
  const { product, isLoading, isSales } = props;

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERADMIN]
  });

  return (
    <Fragment>
      <Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('productName')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.productName}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                {translate('unit')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.typeUnit}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('productCode')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.productCode}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                {translate('purchasePrice')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? (
                  <Skeleton variant='text' width='100%' />
                ) : (
                  <NumberFormat value={product.purchasePrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                )}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('productCategory')}
              </Typography>
              <Typography component='p' className={classes.text}>
                {product.Category ? product.Category.name : '-'}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                {translate('description')}
              </Typography>
              {isLoading ? <Skeleton variant='text' width='100%' /> : <div dangerouslySetInnerHTML={{ __html: product.description }}></div>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                {translate('customersPrice')}
              </Typography>

              <Typography component='p' className={classes.price}>
                {isLoading ? (
                  <Skeleton variant='text' width='100%' />
                ) : (
                  <NumberFormat value={product.customerPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                )}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                {translate('resellersPrice')}
              </Typography>
              <Typography component='p' className={classes.price}>
                {isLoading ? (
                  <Skeleton variant='text' width='100%' />
                ) : (
                  <NumberFormat value={product.resellerPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                )}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Divider />
      <Typography component='p' variant='h5' className={classes.titleDetail}>
        {translate('stockInformation')}
      </Typography>

      <Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell>
              <p style={{ borderBottom: '1px solid lightgrey' }}>
                <b>{translate('stockInformation')}</b>{' '}
                <span style={{ float: 'right' }}>
                  <b>{product.netStock || 0}</b>
                </span>
                <br />
              </p>
              <p>
                - {translate('totalSellingStock')} <span style={{ float: 'right' }}> ({product.salesStock || 0})</span>
              </p>
              <p>
                - {translate('stockOnHold')} <span style={{ float: 'right' }}>({product.holdItem || 0})</span>
                <br />
              </p>
              <p style={{ borderBottom: '1px solid lightgrey' }}>
                <b>{translate('totalGrossStock')}</b>{' '}
                <span style={{ float: 'right' }}>
                  <b>{product.totalStock}</b>
                </span>
              </p>
              <p>
                - {translate('totalSellingStock')} <span style={{ float: 'right' }}>({product.salesStock || 0})</span>
              </p>

              <p>
                - {translate('stockOnHold')} <span style={{ float: 'right' }}>({product.holdItem || 0})</span>
                <br />
              </p>

              <p style={{ color: YELLOW }}>
                - {translate('damagedGoodsinWarehouse')} <span style={{ float: 'right' }}>({product.totalDamageEdit || 0})</span>
                <br />
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default ProductDetail;
