import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { Fragment, ReactNode } from 'react';
import { useLanguage } from 'contexts/LanguageContext';

const useStyles = makeStyles({
  container: {
    rowGap: '1em',
    paddingLeft: '2.6em',
    background: '',
    animation: `$show 300ms ease`
  },
  '@keyframes show': {
    '0%': {
      transform: 'scaleY(0)'
    },
    '100%': {
      transform: 'scaleY(1)'
    }
  }
});
interface Props {
  items: { title: string; totalPrice: ReactNode }[];
}
const ContentItem = ({ items }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <Grid container xs={12} className={classes.container}>
      {items.map((item, index) => (
        <Fragment key={index}>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12} container justify='space-between'>
            <Typography>{item.title}</Typography>
            {item.totalPrice}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

export default ContentItem;
