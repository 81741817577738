import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, Search, Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import FlexBox from 'components/FlexBox';
import { OPERATIONAL_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { dummyMetaData, dummyProductImage } from 'utils/dummy';
import OperationalCarousel from './components/OperationalCarousel';
import OperasionalCreate from './components/OperationalCreate';
import OperationalDelete from './components/OperationalDelete';
import OperationalTable from './components/OperationalTable';

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: Operasional[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  }
}));

const OperationalPage = () => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const handleOpenCreate = () => setIsCreate(true);
  const handleCloseCreate = useCallback(() => setIsCreate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [openCarousel, setOpenCarousel] = useState<{ isOpen: boolean; id: number }>({
    isOpen: false,
    id: 0
  });
  const [isAction, setIsAction] = useState<{ isOpen: boolean; isLoading: boolean; id: number; action: 'delete' | 'update' }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete'
  });

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const handleOpenCarousel = (isOpen: boolean = true, id: number) => setOpenCarousel(prev => ({ ...prev, isOpen, id }));

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' = 'delete') =>
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

  const fetchData = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('page', currentPage.toString());
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setOperasionalResource(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate, currentPage]);

  useEffect(() => {
    fetchData();
    return () => source.cancel('Cancel Unmounted');
  }, [fetchData]);

  const reFetchData = () => {
    setIsCreate(false);
    fetchData();
  };

  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${OPERATIONAL_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title='Biaya Operasional'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Operasional
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container className={classes.container}>
            <Grid xs={12} container>
              <Grid xs={6}>
                <FormControl fullWidth variant='outlined' hiddenLabel size='small'>
                  <OutlinedInput
                    id='keyword'
                    placeholder={translate('searchOperationalHere')}
                    autoComplete='off'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton size='small'>
                          <Search fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <FlexBox columnGap={1} xs={6} justify='flex-end'>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='primary' variant='outlined' onClick={handleCalendarFilterClick}>
                    <Today />
                  </Button>
                </Tooltip>
                <Tooltip title='Tambah data' placement='top'>
                  <Button onClick={handleOpenCreate} size='small' startIcon={<Add fontSize='small' />}>
                    {translate('addCost')}
                  </Button>
                </Tooltip>
              </FlexBox>
            </Grid>
            <Grid xs={12}>
              <Typography variant='h6'>
                {`${translate('showing')} ${meta.total || 0} ${translate('operationalCost')} (${meta.from || 0} - ${meta.to || 0} ${translate(
                  'from'
                )} ${meta.total || 0})`}
              </Typography>

              <Typography color='primary'>
                {translate('fromDate')} {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <OperationalTable operasionalResource={operasionalResource} handleAction={handleAction} handleOpenCarousel={handleOpenCarousel} />
            </Grid>
            {operasionalResource.data.length > 0 && (
              <Grid xs={12} container>
                <Pagination
                  count={meta.last_page}
                  onChange={(event, page) => setCurrentPage(page)}
                  page={meta.current_page}
                  boundaryCount={2}
                  variant='outlined'
                  shape='rounded'
                />
              </Grid>
            )}
            <DateRangeFilter
              openCalendarFilter={openCalendarFilter}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleClose={() => setOpenCalendarFilter(false)}
            />
          </Grid>

          <OperasionalCreate open={isCreate} handleCloseCreate={handleCloseCreate} reFetchData={reFetchData} />
          <OperationalDelete
            open={isAction.isOpen && isAction.action.includes('delete')}
            handleClose={() => handleAction(false, 0, 'delete')}
            handleSubmit={handleDelete}
            isLoading={isAction.isLoading}
          />
          <OperationalCarousel
            open={openCarousel.isOpen}
            handleClose={() => setOpenCarousel(prev => ({ ...prev, isOpen: false }))}
            images={
              operasionalResource.data.filter(operasionalResource => operasionalResource.id === openCarousel.id).length > 0
                ? operasionalResource.data.filter(operasionalResource => operasionalResource.id === openCarousel.id)[0].image
                : [dummyProductImage]
            }
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default OperationalPage;
