import React, { FC, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Checkbox,
  ListItemText,
  Divider
} from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { dummyPartner } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  partners: PartnerModel[];
  isDelete: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  name: string;
  zones: ZoneModel[];
  zone: number[];
  setZone: React.Dispatch<React.SetStateAction<number[]>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  partnerId: string;
  setPartnerId: React.Dispatch<React.SetStateAction<string>>;
  cellPhoneNumber: string;
  setCellPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  order: 'asc' | 'desc';
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
}

const PartnerTable: FC<Props> = props => {
  const { translate } = useLanguage();
  const {
    isLoadingData,
    partners,
    order,
    orderBy,

    name,
    isDelete,
    zones,
    zone,
    setZone,
    setName,
    isAdmin,
    isSuperAdmin,
    type,
    setType,
    partnerId,
    setPartnerId,
    cellPhoneNumber,
    setCellPhoneNumber,
    setOrder,
    setOrderBy,
    handleConfirmationDelete
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCell: any[] = [
    { id: 'partnerId', label: 'ID', sort: true },
    { id: 'name', label: translate('partnersName'), sort: true },
    { id: 'partnerType', label: translate('partnersType'), align: 'center', sort: true },
    { id: 'cellPhoneNumber', label: 'NO. HANDPHONE', sort: true },
    { id: 'action', label: translate('action') }
  ];

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headers={headerCell} />
          <TableRow>
            <TableCellHead variant='head'>
              <TextField id='partner Id' placeholder='ID' value={partnerId} onChange={event => setPartnerId(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head'>
              <TextField id='name' placeholder={translate('partners')} value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>

            <TableCellHead variant='head'>
              {isSuperAdmin && (
                <FormControl fullWidth variant='outlined' size='small'>
                  <Select id='type' value={type as string} placeholder='Tipe' onChange={event => setType(event.target.value as string)}>
                    <MenuItem key={1} value='ALL'>
                      {translate('allTypes')}
                    </MenuItem>
                    <MenuItem key={2} value='SUPPLIER'>
                      Supplier
                    </MenuItem>
                    <MenuItem key={3} value='CUSTOMER'>
                      Customer
                    </MenuItem>
                    <MenuItem key={4} value='RESELLER'>
                      Reseller
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </TableCellHead>

            <TableCellHead variant='head'>
              <TextField
                id='cellPhoneNumber'
                variant='outlined'
                placeholder='No HP'
                value={cellPhoneNumber}
                onChange={event => setCellPhoneNumber(event.target.value)}
                size='small'
              />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow key={index} isLoading={isLoadingData} partner={dummyPartner} isDelete={false} onDelete={handleConfirmationDelete(0)} />
            ))
          ) : partners && partners.length > 0 ? (
            partners.map((value, index) => (
              <BodyRow key={index} isLoading={isLoadingData} partner={value} isDelete={isDelete} onDelete={handleConfirmationDelete(value.id)} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align='center'>
                {translate('dataNotAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default PartnerTable;
