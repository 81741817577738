import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { dummyPurchaseInvoiceItem } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import PurchaseInvoiceItem from './components/PurchaseInvoiceItem';

interface Props {
  purchaseInvoiceItem: PurchaseInvoiceItemModel[];
  isLoading: boolean;
  handleSetValue: (value: PurchaseInvoiceItemModel) => void;
}

const PurchaseOrderCreateTable: FC<Props> = props => {
  const { translate } = useLanguage();
  const { purchaseInvoiceItem, isLoading, handleSetValue } = props;

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('productName')}</TableCell>
            <TableCell align='center'>Harga</TableCell>
            <TableCell align='center'>Qty</TableCell>
            <TableCell align='center'>Diskon</TableCell>
            <TableCell align='center'>{translate('totalPrice')}</TableCell>
            <TableCell align='center'>{translate('netCapital')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? [1, 2, 3, 4].map(value => <BodyRow key={value} item={dummyPurchaseInvoiceItem} isLoading={isLoading} />)
            : purchaseInvoiceItem.length > 0 &&
              purchaseInvoiceItem
                .filter(value => !value.isDeleted)
                .map(value => <PurchaseInvoiceItem item={value} handleSetValue={handleSetValue} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseOrderCreateTable;
