import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Container, makeStyles, Typography, Grid, Button, Tooltip, Tabs, Tab } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, a11yProps } from 'components';
import { PARTNER_BASE_URL, INVOICE_BASE_URL, PURCHASE_INVOICE_BASE_URL } from 'constants/url';
import { WHITE } from 'constants/colors';
import { dummyPartner } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import PartnerDetail from './components/PartnerDetail';
import PartnerWizard from './components/PartnerWizard';
import RefreshIcon from '@material-ui/icons/Refresh';
import useRole from 'hooks/useRole';
import useDebounced from 'hooks/useDebounced';
import TypeUser from 'typings/enum/TypeUser';
import InvoiceTable from './components/InvoiceTable';
import NumberFormat from 'react-number-format';
import PartnerType from 'typings/enum/PartnerType';
import ResellerDetail from './components/ResellerDetail';
import ResellerForm from './components/ResellerForm';

const useStyles = makeStyles({
  secondGrid: {
    marginTop: 10
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginBottom: '10px'
  },
  boxTitle: {
    marginBottom: 8
  }
});

const PartnerDetailPage: FC = () => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { currentUser } = useContext(CurrentUserContext);
  const { history } = useRouter();
  // eslint-disable-next-line
  const { match } = useRouter();
  const params = match.params.id;
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [resellerResource, setResellerResource] = useState<{ isLoading: boolean; data: PartnerModel[] }>({ isLoading: false, data: [] });
  const [id, setId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [loadInvoice, setLoadInvoice] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [resellerType, setResellerType] = useState<number>(0);
  const [customerResource, setCustomerResource] = useState<{ isOpen: boolean; isLoading: boolean; data: PartnerModel[] }>({
    isOpen: false,
    isLoading: false,
    data: [dummyPartner]
  });

  const [resellerDelete, setResellerDelete] = useState<{ isLoading: boolean; id: number; isOpen: boolean }>({
    isLoading: false,
    isOpen: false,
    id: 0
  });

  const tokenSource = axios.CancelToken.source();

  const isSuperVisorIsSales = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERVISOR, 'SALES']
  });

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}/${params}`);
      setPartner(data.data);
      setId(data.data.id);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };
  const fecthDataReseller = async () => {
    setResellerResource({ ...resellerResource, ['isLoading']: true });
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}/reseller/${params}`, {
        cancelToken: tokenSource.token
      });
      setResellerResource({ ...resellerResource, ['isLoading']: false, ['data']: data.data.map((val: PartnerModel) => ({ ...val, isEdit: false })) });
    } catch (error) {
      console.log('error:', error);
    }
  };
  const handleDeleteReseller = (id: number) => setResellerDelete(prev => ({ ...prev, id, isOpen: true }));

  const handleAddCustumerReseler = (action: string = 'open') =>
    setCustomerResource(prev => ({ ...prev, isOpen: action.includes('open') ? true : false }));

  const handleSubmitCustomerReseller = async (action: string = 'save') => {
    if (action.includes('save')) {
      setCustomerResource(prev => ({ ...prev, isLoading: true }));
      const customerReseller = customerResource.data.map(val => ({ ...val, areaCode: (val.Kecamatan && val.Kecamatan.code) || '' }));
      const dataFinal = {
        ...partner,
        customerReseller: customerReseller
      };

      try {
        const { data } = await axios.post(PARTNER_BASE_URL, dataFinal);
        setCustomerResource(prev => ({ ...prev, isLoading: false, isOpen: false }));
        setResellerResource({ ...resellerResource, data: data.data.customerReseller });
        setCustomerResource({ isOpen: false, isLoading: false, data: [dummyPartner] });
      } catch (error) {
        console.log(error);
      }
    } else {
      setCustomerResource(prev => ({ ...prev, isOpen: false }));
    }
  };
  const submitDeleteCustomer = async () => {
    setResellerDelete({ ...resellerDelete, isLoading: true, isOpen: false });
    try {
      await axios.delete(`${PARTNER_BASE_URL}/${resellerDelete.id}`);
      setResellerDelete(prev => ({ ...prev, isLoading: false }));
      setResellerResource(prev => ({ ...prev, data: prev.data.filter(val => val.id !== resellerDelete.id) }));
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateReseller = useCallback(
    (index: number, isEdit: boolean) => {
      const tempData = [...resellerResource.data];
      tempData[index].isEdit = isEdit;
      setResellerResource({ ...resellerResource, ['data']: tempData });
    },
    [resellerResource]
  );
  const handleSubmitUpdateReseller = useCallback(async () => {
    try {
      const tempData = resellerResource.data.find(val => val.isEdit);
      if (tempData) {
        const finalData = { ...tempData, regionName: tempData.Provinsi!.name + ', ' + tempData.Kabupaten!.name + ', ' + tempData.Kecamatan!.name };
        const { data } = await axios.post(PARTNER_BASE_URL, finalData);
        setResellerResource({
          ...resellerResource,
          ['data']: resellerResource.data.map(val => (finalData.id === val.id ? { ...val, isEdit: false } : { ...val }))
        });
      }
    } catch (error) {
      console.log('error :', error);
    }
  }, [resellerResource]);
  useEffect(() => {
    if (resellerType === 1) {
      fecthDataReseller();
    }
    return () => tokenSource.cancel('query is cancelled');
  }, [resellerType]);

  const fetchInvoice = async () => {
    const getQueryParams = () => {
      const param = new URLSearchParams();

      if (invoiceId) {
        param.append('number', invoiceId);
      }

      if (statusPayment) {
        param.append('status', statusPayment);
      }

      param.append('orderBy', orderBy);
      param.append('ordered', order);
      param.append('page', String(currentPage));
      param.append('partnerId', String(params));
      return param.toString();
    };

    setLoadInvoice(true);
    try {
      const { data } = await axios.get(
        `${
          partner.partnerType === PartnerType.CUSTOMER ||
          partner.partnerType === PartnerType.RESELLER ||
          partner.partnerType === PartnerType.CUSTOMER_RESELLER
            ? INVOICE_BASE_URL
            : PURCHASE_INVOICE_BASE_URL
        }?${getQueryParams()}`,
        {
          cancelToken: tokenSource.token
        }
      );
      setInvoices(data.data);
      setCount(data.meta.last_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadInvoice(false);
    }
  };

  const getData = async (id: number) => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
    }
  };

  const handleConfirmationDelete = () => {
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deletePartner = async () => {
    try {
      await axios.delete(`${PARTNER_BASE_URL}/${id}`);
      handleSnackBar(true, 'success', 'Mitra berhasil dihapus.');
      setDelete(true);
    } catch (err) {
      const error = err as { data: { message: string } };
      handleSnackBar(true, 'error', error.data.message);
    } finally {
      setConfirmationDelete(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    if (isDelete) {
      setDelete(false);
      history.push('/mitra');
    }
  };

  const handleOnSubmit = () => {
    setSubmit(true);
  };

  const handleResellerType = (event: React.ChangeEvent<{}>, newValue: number) => setResellerType(newValue);

  const handleSetUpdate = () => {
    setUpdate(true);
  };

  const handleCancelUpdate = () => {
    setUpdate(false);
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  useEffect(() => {
    setIsLoadingData(true);
    fetchData();
  }, []);

  const debounceHits = useDebounced(fetchInvoice, 500);

  useEffect(() => {
    debounceHits();
    return () => tokenSource.cancel('query is cancelled');
  }, [invoiceId, statusPayment, orderBy, currentPage, params]);

  return (
    <Page title={translate('partners')}>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> {translate('allPartners')} </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid xs={12} container>
          <Grid xs={6}>
            {partner.partnerType && partner.partnerType.includes(PartnerType.RESELLER) && (
              <Tabs value={resellerType} onChange={handleResellerType} indicatorColor='primary' textColor='primary' aria-label='tabs type reseller'>
                <Tab label='DATA RESELLER' {...a11yProps(0)} />
                <Tab label='DATA CUSTOMER' {...a11yProps(1)} />
              </Tabs>
            )}
          </Grid>
          <Grid xs={6} container direction='row' spacing={1} justify='flex-end'>
            {resellerType === 1 && (
              <Grid item>
                <Button color='primary' variant='contained' onClick={() => handleAddCustumerReseler()}>
                  Tambah Customer Reseller
                </Button>
              </Grid>
            )}
            {resellerType !== 1 && (
              <>
                <Grid item>
                  <Tooltip title='Refresh'>
                    <Button onClick={() => fetchData()} color='inherit'>
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                </Grid>

                {!isSuperVisorIsSales && (
                  <>
                    <Grid item>
                      <Button color='secondary' onClick={isUpdate ? handleCancelUpdate : handleConfirmationDelete}>
                        <Typography>{isUpdate ? translate('cancel') : translate('delete')}</Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={isUpdate ? handleOnSubmit : handleSetUpdate}>
                        <Typography>{isUpdate ? translate('save') : translate('editData')}</Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>

        {resellerType === 0 ? (
          <Grid xs={12}>
            {isUpdate ? (
              <PartnerWizard
                typeUser={(currentUser && currentUser.type) || 'SALES'}
                partner={partner}
                setPartner={setPartner}
                isSubmit={isSubmit}
                setUpdate={setUpdate}
                setSubmit={setSubmit}
                handleSnackBar={handleSnackBar}
              />
            ) : (
              <PartnerDetail partner={partner} isLoading={isLoadingData} />
            )}

            {(partner.partnerType === PartnerType.CUSTOMER || partner.partnerType === PartnerType.RESELLER) && (
              <Grid container direction='row' justify='space-between' className={classes.box}>
                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    Tipe Mitra
                  </Typography>
                  <Typography align='center' variant='body2'>
                    {partner.partnerType}
                  </Typography>
                </Grid>

                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    {translate('amountOutstanding')}
                  </Typography>
                  <Typography align='center' variant='body2'>
                    <NumberFormat value={partner.totalBill ? partner.totalBill : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>
            )}

            <InvoiceTable
              statusPayment={statusPayment}
              invoiceId={invoiceId}
              isLoadingData={loadInvoice}
              count={count}
              currentPage={currentPage}
              invoices={invoices}
              order={order}
              orderBy={orderBy}
              openCollapse={openCollapse}
              indexCollapse={indexCollapse}
              setInvoiceId={setInvoiceId}
              setStatusPayment={setStatusPayment}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              handleOpenCollapse={handleOpenCollapse}
              setCurrentPage={setCurrentPage}
            />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={handleCloseSnackbar}
              onConfirm={handleCloseSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={'danger'}
              titleMessage={'Delete'}
              message={'Apakah kamu yakin menghapus data ini?'}
              open={confirmationDelete}
              handleClose={handleCloseConfirmationDelete}
              onConfirm={deletePartner}
            />
          </Grid>
        ) : (
          <Grid xs={12}>
            <ResellerDetail
              resellerResource={resellerResource}
              handleUpdateReseller={handleUpdateReseller}
              resellerDelete={resellerDelete}
              setResellerResource={setResellerResource}
              handleDeleteReseller={handleDeleteReseller}
              handleSubmitUpdateReseller={handleSubmitUpdateReseller}
            />
          </Grid>
        )}
        <ResellerForm
          handleSubmitCustomerReseller={handleSubmitCustomerReseller}
          customerResource={customerResource}
          setCustomerResource={setCustomerResource}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={resellerDelete.isOpen}
          handleClose={() => setResellerDelete({ ...resellerDelete, isOpen: false })}
          onConfirm={submitDeleteCustomer}
        />
      </Container>
    </Page>
  );
};

export default PartnerDetailPage;
