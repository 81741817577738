import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';

interface Props {
  item: SalesOrderItemModel;
  checked: SalesOrderItemModel[];
  handleIndividualCheck: (item: SalesOrderItemModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { item, checked, handleIndividualCheck } = props;
  const { id } = item;

  let isChecked = checked.some(checkedValue => checkedValue.id === id);

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck({ ...item, id });
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        <Checkbox
          key={id}
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          edge='start'
          color='primary'
          checked={isChecked}
          tabIndex={-1}
          disableRipple
          disabled={checked.length > 0 && !isChecked}
          onChange={handleSetId}
        />
        {item.Product ? item.Product.productName : ''}
      </TableCellStart>
      <TableCellMiddle align='right'>
        <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCellMiddle>
      <TableCellMiddle align='center'> {item.totalItem + ' ' + item.typeUnit}</TableCellMiddle>
      <TableCellMiddle align='center'>{(item.warehouse && item.warehouse) || '-'}</TableCellMiddle>
      <TableCellMiddle align='right'>
        <NumberFormat value={item.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCellMiddle>
      <TableCellEnd align='right'>
        <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
