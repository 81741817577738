import { Button, CircularProgress, Container, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Breadcrumb, Page, PaperCustom } from 'components';
import React, { useContext, useEffect, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import useRouter from 'hooks/useRouter';
import { DELIVERY_ORDER_DETAILS, DELIVERY_ORDER_PRINT } from 'constants/url';
import Logo from 'images/logo-blue.png';
import DeliveryInfo from './DeliveryInfo';
import DeliveryOrderList from './DeliveryOrderList';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import axios from 'axios';
import { dummyDelivery } from 'utils/dummy';
import TypeUser from 'typings/enum/TypeUser';
import ModalPage from 'pages/DeliveryOrder/components/ModalPage';
import ROUTE_PATHS from 'constants/routePaths';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerBottom: {
    margin: '2em 0'
  },
  image: {
    objectFit: 'contain',
    height: '8em',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  imageQr: {
    objectFit: 'contain',
    height: '120px',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  linkInvoice: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));
const DeliveryOrdersDetail = () => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { match } = useRouter();
  const idDelivery = match.params.id;
  const [printWarning, setPrintWarning] = useState<boolean>(false);
  const [deliveryOrder, setDeliveryOrder] = useState<DeliveryOrders>(dummyDelivery);
  const [deliveryOrderItem, setDeliveryOrderItem] = useState<DeliveryOrderItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  const fecthDelivery = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(DELIVERY_ORDER_DETAILS(+idDelivery));
      setDeliveryOrder(data.data);
      setDeliveryOrderItem(data.data.DeliveryItem);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrintWarning = (isAction: string = 'close') => {
    setPrintWarning(false);
    if (isAction.includes('close')) return;
    deliveryPrint();
  };

  const handleDeliveryPrint = () => {
    if (deliveryOrder.printCount > 0) {
      setPrintWarning(true);
    } else {
      deliveryPrint();
    }
  };

  const deliveryPrint = async () => {
    setIsPrint(true);

    try {
      const { data } = await axios.get(DELIVERY_ORDER_PRINT(+idDelivery), { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setIsPrint(false);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      setDeliveryOrder(prev => {
        return {
          ...prev,
          ['printCount']: prev.printCount + 1
        };
      });
    } catch (error) {
      console.log('error :', error);
    }
  };
  const handleLinkInvoice = (id: number) => {
    window.open(`${ROUTE_PATHS.SalesOrderInvoice.path}/${id}`);
  };
  useEffect(() => {
    fecthDelivery();
  }, []);

  return (
    <Page title='Surat Jalan Detail'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Surat Jalan
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container className={classes.container}>
            <Grid xs={12} container>
              <Grid xs={6}>
                <img className={classes.image} src={Logo} alt='Company Logo' />
              </Grid>
              <Grid xs={6} container>
                <Grid xs={12}>
                  <Typography variant='h5' align='right'>
                    Surat Jalan
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  {deliveryOrder.invoice.map((val, key) => (
                    <Typography color='textSecondary' key={key}>
                      INV RUJUKAN {deliveryOrder.invoice.length > 1 && key + 1}
                    </Typography>
                  ))}
                </Grid>
                <Grid xs={9}>
                  {deliveryOrder.invoice.map((val, key) => (
                    <Typography className={classes.linkInvoice} variant='h6' align='right' onClick={() => handleLinkInvoice(val.id)} key={key}>
                      {val.invoiceNumber}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <DeliveryInfo deliveryOrder={deliveryOrder} isLoading={isLoading} />
            <Grid xs={12}>
              <Divider />
            </Grid>
            <DeliveryOrderList deliveryOrderItem={deliveryOrderItem} isLoading={isLoading} />
            <Grid xs={12} className={classes.containerBottom}>
              <Button variant='contained' fullWidth disabled={isPrint} color='primary' style={{ borderRadius: '20px' }} onClick={handleDeliveryPrint}>
                {isPrint ? <CircularProgress size={21} color='primary' /> : translate('printDeliveryOrder')}
              </Button>
            </Grid>
          </Grid>
          <ModalPage isOpen={printWarning} printResource={deliveryOrder} handlePrintWarning={handlePrintWarning} />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default DeliveryOrdersDetail;
