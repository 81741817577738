import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, Visibility } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import { BLACK, BLUE, WHITE } from 'constants/colors';
import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: BLUE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '18%'
  },
  cellMiddleDesc: {
    width: '30%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  }
}));
interface Props {
  operasionalResource: Operasional;
  handleOpenCarousel: (isOpen: boolean, id: number) => void;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update') => void;
}
const OperationalBody = ({ operasionalResource, handleAction, handleOpenCarousel }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <TableRowCustom key={operasionalResource.id}>
      <TableCellStart className={classes.cellStart}>{operasionalResource.id}</TableCellStart>
      <TableCellMiddle className={clsx(classes.cellMiddle, classes.link)}>{operasionalResource.itemName}</TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>
        <NumberFormat value={operasionalResource.nominal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>
        <Button
          variant='text'
          color='primary'
          size='small'
          startIcon={<Visibility fontSize='small' color='primary' />}
          onClick={() => handleOpenCarousel(true, operasionalResource.id)}
        >
          {translate('viewAttachment')}
        </Button>
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddleDesc}>
        <Tooltip
          classes={{ tooltip: classes.descriptionContainer }}
          title={
            <Typography align='justify' className={classes.description}>
              {(operasionalResource.description && operasionalResource.description) || 'Tidak ada keterangan'}
            </Typography>
          }
        >
          <Typography>
            {(operasionalResource.description &&
              operasionalResource.description.slice(0, 35) + (operasionalResource.description.length > 35 ? '...' : '')) ||
              'Tidak ada keterangan'}
          </Typography>
        </Tooltip>
      </TableCellMiddle>
      <TableCellEnd className={classes.cellEnd}>
        <Grid container xs={12} direction='row' justify='space-evenly'>
          <IconButton color='primary' aria-label='remove' size='small' onClick={() => handleAction(true, operasionalResource.id, 'delete')}>
            <Delete />
          </IconButton>
        </Grid>
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default OperationalBody;
