import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Error';
import { YELLOW } from 'constants/colors';
import { format } from 'date-fns';

interface Props {
  isOpen: boolean;
  printResource: DeliveryOrders | undefined;
  handlePrintWarning: (isAction?: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  icon: {
    fontSize: '4rem',
    color: YELLOW
  }
}));

const ModalPage = ({ isOpen, printResource, handlePrintWarning }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <Dialog open={isOpen} maxWidth='lg'>
      <DialogTitle>
        <Grid xs={12} container justify='center' alignItems='center'>
          <WarningIcon className={classes.icon} />
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction='column' xs={12} justify='center' className={classes.container}>
          <Grid xs={12}>
            <Typography variant='h4'>{translate('printDeliveryOrder')}?</Typography>
          </Grid>
          <Grid xs={12} container alignItems='center' direction='column'>
            <Typography variant='h6'>{translate('thisDeliveryOrderhasbeenprintedpreviously')}:</Typography>

            <Typography variant='h6'>
              Tanggal di Cetak : {format(new Date((printResource && printResource.printDate) || new Date()), 'dd MMM yyyy')}
            </Typography>
            <Typography variant='h6'>
              Di Cetak Oleh :{' '}
              {`${printResource && printResource.printBy && printResource.printBy.firstName} ${printResource &&
                printResource.printBy &&
                printResource.printBy.lastName} `}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid xs={12} container justify='space-around' alignItems='center'>
          <Grid xs={5}>
            <Button disableElevation fullWidth variant='contained' color='secondary' onClick={() => handlePrintWarning()}>
              {translate('cancel')}
            </Button>
          </Grid>
          <Grid xs={5}>
            <Button disableElevation fullWidth variant='contained' onClick={() => handlePrintWarning('submit')}>
              {translate('print')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPage;
