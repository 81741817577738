import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Dialog, DialogContent, Button, TableContainer, Table, TableBody, TableRow, TableCell, Grid, makeStyles, Theme } from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import useRouter from 'hooks/useRouter';
import NumberFormat from 'react-number-format';

interface Props {
  partners: PartnerModel;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  addButton: {
    color: '#FFFFFF'
  },
  root: {
    flexGrow: 1,
    maxWidth: 700
  },
  gridRolesHidden: {
    display: 'none'
  }
}));

const PartnerModal: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { partners, open, setOpen, onCancel } = props;
  const { location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='customized-dialog-title'>Info Customer</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>Nama</TableCell>
                <TableCell colSpan={2} align='right'>
                  {partners.name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>Route</TableCell>
                <TableCell colSpan={2} align='right'>
                  {partners.Zone ? partners.Zone.name : ''}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>{translate('ceilingAmount')}</TableCell>
                <TableCell colSpan={2} align='right'>
                  <NumberFormat value={partners.plafon ? partners.plafon : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>{translate('paymentDue')}</TableCell>
                <TableCell colSpan={2} align='right'>
                  {partners.dueDate}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>{translate('amountOutstanding')}</TableCell>
                <TableCell colSpan={2} align='right'>
                  <NumberFormat value={partners.totalBill ? partners.totalBill : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel}>
            Oke
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PartnerModal;
