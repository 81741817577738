import { TLanguages } from 'typings/Language';

export type TPathKey =
  | 'Root'
  | 'Add'
  | 'Partners'
  | 'Product'
  | 'Category'
  | 'AllStocks'
  | 'SalesOrder'
  | 'PurchaseOrder'
  | 'SalesOrderInvoice'
  | 'PurchaseOrderInvoice'
  | 'DeliveryOrder'
  | 'OperationalCost'
  | 'CourierCommission'
  | 'Report'
  | 'ReportIncomeStatement'
  | 'ReportOrder'
  | 'Setting'
  | 'ApplicationLogs';

const ROUTE_PATHS: Record<TPathKey, TLanguages & { path: string }> = {
  Root: {
    path: '/',
    en: '',
    id: ''
  },
  Partners: {
    path: '/partners',
    en: 'Partners',
    id: 'Mitra'
  },
  Product: {
    path: '/product',
    en: 'Product',
    id: 'Produk'
  },
  Category: {
    path: '/category',
    en: 'Category',
    id: 'Kategori'
  },
  AllStocks: {
    path: '/all-stocks',
    en: 'All Stocks',
    id: 'Semua Stok'
  },
  SalesOrder: {
    path: '/sales-order',
    en: 'Sales Order',
    id: 'Penjualan'
  },
  PurchaseOrder: {
    path: '/purchase-order',
    en: 'Purchase Order',
    id: 'Pembelian'
  },
  SalesOrderInvoice: {
    path: '/sales-order-invoice',
    en: 'Sales Order Invoice',
    id: 'Invoice Penjualan'
  },
  PurchaseOrderInvoice: {
    path: '/purchase-order-invoice',
    en: 'Purchase Order Invoice',
    id: 'Invoice Penjualan'
  },
  DeliveryOrder: {
    path: '/delivery-order',
    en: 'Delivery Order',
    id: 'Surat Jalan'
  },
  OperationalCost: {
    path: '/operational-cost',
    en: 'Operational Cost',
    id: 'Biaya Operasional'
  },
  CourierCommission: {
    path: '/courier-commission',
    en: 'Courier Commission',
    id: 'Komisi Kurir'
  },
  Report: {
    path: '/report',
    en: 'Report',
    id: 'Laporan'
  },
  ReportIncomeStatement: {
    path: '/report-income-statement',
    en: 'Report Income Statement',
    id: 'Laporan Laba Rugi'
  },
  ReportOrder: {
    path: '/report-order',
    en: 'Report Order',
    id: 'Laporan Orderan'
  },

  Setting: {
    path: '/setting',
    en: 'Setting',
    id: 'Pengaturan'
  },
  ApplicationLogs: {
    path: '/application-logs',
    en: 'Application Logs',
    id: 'Log Aplikasi'
  },
  Add: {
    path: '/add',
    en: 'Add',
    id: 'Tambah'
  }
} as const;

export default ROUTE_PATHS;
