import { Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { PaperCustom } from 'components';
import { GREY } from 'constants/colors';
import ContentData from './ContentData';
import Empty from 'images/icon/EmptyIcon.svg';
import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TcourierSalary } from '..';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerEmpty: {
    minHeight: '15em',
    borderTop: `1px solid ${GREY}`
  },
  titleContainer: {
    padding: '0 1em'
  }
}));
interface Props {
  courierSalaryResource: TcourierSalary<UserDetailsModel, CourierSalary[]>;
  handleOpenDelivery: (id: number) => void;
}
const ContentDetail = ({ courierSalaryResource, handleOpenDelivery }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <PaperCustom>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={12} className={classes.container} container direction='column'>
          <Typography variant='h6'>{translate('commissionDetail')}</Typography>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} container className={classes.titleContainer}>
          <Grid xs={4}>
            <Typography color='textSecondary'>{translate('date')}</Typography>
          </Grid>
          <Grid xs={4}>
            <Typography color='textSecondary'>{translate('deliveryHistory')}</Typography>
          </Grid>
          <Grid xs={4}>
            <Typography color='textSecondary'>{translate('totalAmount')}</Typography>
          </Grid>
        </Grid>
        {courierSalaryResource.isLoading ? (
          <Grid xs={12}>
            {[...new Array(5)].map((val, key) => (
              <Grid xs={12}>
                <Skeleton variant='text' animation='wave' height={60} key={key} />
              </Grid>
            ))}
          </Grid>
        ) : courierSalaryResource.data.courierSalaryItem.length > 0 ? (
          courierSalaryResource.data.courierSalaryItem.map(courierSalary => (
            <ContentData courierSalary={courierSalary} handleOpenDelivery={handleOpenDelivery} />
          ))
        ) : (
          <Grid xs={12} container direction='column' justify='center' alignItems='center' className={clsx(classes.container, classes.containerEmpty)}>
            <img src={Empty} alt='Empty Logo' />
            <Typography variant='h6' color='primary'>
              {translate('dataNotAvailable')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </PaperCustom>
  );
};

export default ContentDetail;
