import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { PRIMARY_SECONDARY, RED, WHITE, YELLOW } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, TableRowCollapse } from 'components';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import clsx from 'clsx';
import PaymentStatus from 'typings/enum/PaymentStatus';
import ROUTE_PATHS from 'constants/routePaths';

interface Props {
  purchaseInvoice: PurchaseInvoiceModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  collapseLoading: boolean;
  onDelete: React.MouseEventHandler;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: RED,
      width: 30
    },
    tableCellAction: {
      width: 30
    },
    customerName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      lineHeight: '22px'
    },
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    paymentStatus: {
      color: WHITE,
      borderRadius: '1.2em',
      padding: '.4em .4em'
    },
    paidColor: {
      background: PRIMARY_SECONDARY
    },
    unPaidColor: {
      background: YELLOW
    }
  })
);

const BodyRow: FC<Props> = props => {
  const { purchaseInvoice, isLoading, openCollapse, index, indexCollapse, collapseLoading, onDelete, handleOpenCollapse } = props;
  const { history } = useRouter();
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();

  const handleLink = () => {
    history.push(`${ROUTE_PATHS.PurchaseOrderInvoice.path}/detail`, { id: purchaseInvoice.id });
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>
        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={60} height={25} /> : '#' + purchaseInvoice.invoiceNumber}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={150} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {purchaseInvoice.partnerName}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='right' onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : (
            <NumberFormat value={purchaseInvoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink} align='center'>
          {isLoading ? <Skeleton variant='text' width={120} height={25} /> : purchaseInvoice.totalItem}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={120} height={25} /> : format(new Date(purchaseInvoice.dueDate), 'dd MMM yyyy')}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : (
            <Typography
              align='center'
              className={clsx(classes.paymentStatus, purchaseInvoice.statusPayment === PaymentStatus.PAID ? classes.paidColor : classes.unPaidColor)}
            >
              {purchaseInvoice.statusPayment === PaymentStatus.PAID ? translate('fullyPaid') : translate('unpaid')}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellEnd align='center' className={classes.tableCellAction}>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <Tooltip title={translate('delete')}>
              <IconButton size='small' onClick={onDelete}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </TableCellEnd>
      </TableRowCustom>
      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={8}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='center'>{translate('quantity')}</TableCell>
              <TableCell align='right'>Harga</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {collapseLoading && (
              <TableRow key={index}>
                <TableCell colSpan={3}>
                  <Skeleton variant='text' width='200%' height={25} />
                </TableCell>
              </TableRow>
            )}

            {purchaseInvoice.PurchaseInvoiceItem &&
              purchaseInvoice.PurchaseInvoiceItem.map((value: PurchaseInvoiceItemModel, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellCollapse}>{value.Product ? value.Product.productName : '-'}</TableCell>
                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.Product ? value.totalItem + ' ' + value.typeUnit : 0}
                  </TableCell>
                  <TableCell align='right' className={classes.cellCollapse}>
                    <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
