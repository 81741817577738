import React, { FC, Fragment } from 'react';
import { Drawer, IconButton, Theme, useTheme, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WHITE, PRIMARY_GRADIENT } from 'constants/colors';
import logo from 'images/logo-white.png';
import DrawerList from './components/DrawerList';
import useRouter from 'hooks/useRouter';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

interface Props {
  openDrawer: boolean;
  currentUserData: CurrentUser | undefined;
  handleDrawerClose(): void;
  openMobile: boolean;
  handleDrawerCloseMobile: () => void;
  useNotification?: boolean;
  totalNotification?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    background: PRIMARY_GRADIENT,
    width: 280,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  logoContainer: {
    flex: 0.75,
    display: 'flex',
    paddingLeft: '.9em'
  },
  title: {
    color: WHITE,
    fontWeight: 'bold'
  },
  iconHidden: {
    flex: 0.25,
    display: 'grid',
    placeItems: 'center'
  },
  logo: {
    objectFit: 'contain',
    height: '2.4em',
    cursor: 'pointer'
  },
  chevron: {
    float: 'right',
    color: WHITE
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9)
    }
  }
}));

const AppDrawer: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const { openDrawer, currentUserData, handleDrawerClose, openMobile, handleDrawerCloseMobile, useNotification, totalNotification } = props;
  const theme = useTheme();

  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={openMobile}
          onClose={handleDrawerCloseMobile}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <div className={classes.toolbarIcon}>
            <div className={classes.logoContainer}>
              <img src={logo} alt='Company Logo' className={classes.logo} />
            </div>
            <div className={classes.iconHidden}>
              <IconButton onClick={handleDrawerCloseMobile}>
                <ChevronLeftIcon className={classes.chevron} fontSize='small' />
              </IconButton>
            </div>
          </div>
          <DrawerList
            handleDrawerClose={handleDrawerCloseMobile}
            useNotification={useNotification}
            totalNotification={totalNotification}
            currentUserData={currentUserData}
          />
        </Drawer>
      </Hidden>

      <Hidden mdDown implementation='css'>
        <Drawer
          open
          variant='permanent'
          classes={{
            paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose)
          }}
        >
          <div className={classes.toolbarIcon}>
            <div className={classes.logoContainer}>
              <img src={logo} alt='logo image' className={classes.logo} onClick={() => history.push('/')} />
            </div>

            <div className={classes.iconHidden}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon className={classes.chevron} fontSize='small' />
              </IconButton>
            </div>
          </div>

          <DrawerList
            useNotification={useNotification}
            totalNotification={totalNotification}
            handleDrawerClose={() => console.log('')}
            currentUserData={currentUserData}
          />
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

export default AppDrawer;
