import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Error';
import { orange } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

interface Props {
  open: boolean;
  isLoading?: boolean;
  messageDate?: string;
  messageUser?: string;
  handleClose(): void;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  warnigAvatarIcon: {
    fontSize: 60,
    color: orange[500]
  },
  cancelButton: {
    marginRight: 4,
    color: '#FFFF'
  },
  okButton: {
    marginLeft: 4,
    backgroundColor: '#02A9EA',
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  dialogActions: {
    marginBottom: theme.spacing(2)
  },
  titleText: {
    paddingBottom: 2,
    marginBottom: 2
  },
  messageText: {
    paddingBottom: theme.spacing(4)
  },
  messageTextInfo: {
    color: blue[700]
  },
  iconGrid: {
    marginTop: 4
  }
}));

const PrintConfirm: FC<Props> = props => {
  const classes = useStyles(props);
  const { translate } = useLanguage();
  const { messageDate, messageUser, open, isLoading, handleClose, onConfirm } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container justify='center' alignItems='center' className={classes.iconGrid}>
          <WarningIcon className={classes.warnigAvatarIcon} />
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.titleText}>
        <Typography variant='h4' component='p' align={'center'}>
          {translate('printInvoice')}?
        </Typography>
      </DialogContent>

      {messageDate && messageUser && (
        <DialogContent className={classes.messageText}>
          <Typography variant='h6' component='p' align={'center'}>
            Invoice ini sudah pernah dicetak:
          </Typography>
          <Typography variant='h6' component='p' align={'center'} className={classes.messageTextInfo}>
            {translate('date')} {messageDate}
          </Typography>
          <Typography variant='h6' component='p' align={'center'} className={classes.messageTextInfo}>
            Oleh {messageUser}
          </Typography>
        </DialogContent>
      )}

      <DialogActions className={classes.dialogActions}>
        <Grid container justify='center' alignItems='center'>
          <Button disableElevation variant='contained' color='secondary' disabled={isLoading} className={classes.cancelButton} onClick={handleClose}>
            {translate('cancel')}
          </Button>
          <Button disableElevation variant='contained' disabled={isLoading} className={classes.okButton} onClick={onConfirm}>
            {isLoading ? <CircularProgress size={20} color='inherit' /> : translate('print')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PrintConfirm;
