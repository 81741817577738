import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Theme,
  Typography
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import NumberFormat from 'react-number-format';
import { TcourierSalary } from '..';

interface Props {
  courierSalary: TcourierSalary;
  handleSubmit: (action: 'submit' | 'cancel') => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    gap: '1em'
  }
}));

const InfoModal = ({ courierSalary, handleSubmit }: Props) => {
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <Dialog
      maxWidth={'sm'}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      aria-labelledby='customized-dialog-title'
      open={courierSalary.isOpen}
    >
      <DialogTitle>
        <Typography variant='h5'>{translate('commissionCalculation')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.container}>
          <Grid xs={12} container>
            <Grid xs={5} className={classes.container} container direction='column'>
              <Typography>{translate('courierName')}</Typography>
              <Typography>Dari Tanggal</Typography>
              <Typography>Total Pengantaran</Typography>
              <Typography>{translate('totalAmount')}</Typography>
            </Grid>
            <Grid xs={7} className={classes.container} container direction='column'>
              <Typography>: {courierSalary.data.name}</Typography>
              <Typography>
                : {`${format(courierSalary.data.startDate, 'dd-MM-yyyy')} s/d ${format(courierSalary.data.endDate, 'dd-MM-yyyy')}`}
              </Typography>
              <Typography>: {courierSalary.data.totalDelivery}</Typography>
              <Typography variant='h6'>
                : <NumberFormat value={courierSalary.data.totalNominal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={() => handleSubmit('cancel')}>
              Batalkan
            </Button>
            <Button onClick={() => handleSubmit('submit')} disabled={courierSalary.isLoadingSubmit}>
              {courierSalary.isLoadingSubmit ? <CircularProgress color='primary' size={20} /> : translate('confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
