import React, { FC, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Dialog, DialogContent, Button, DialogActions, createStyles, makeStyles, Theme } from '@material-ui/core';
import ProductPackageWizard from './components/ProductPackageWizard';
import { DialogTitle } from 'components/Dialog';

interface Props {
  product: ProductModel;
  open: boolean;
  setProduct: React.Dispatch<React.SetStateAction<ProductModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string, link?: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPackage: {
      margin: '0 auto',
      width: 859,
      height: 523,
      padding: 24
    }
  })
);

const ProductPackage: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { product, open, setOpen, setProduct, handleSnackBar } = props;
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOnSubmit = () => {
    setSubmit(true);
    setOpen(false);
  };

  return (
    <Dialog classes={{ paper: classes.dialogPackage }} maxWidth={false} disableBackdropClick={true} open={open}>
      <DialogTitle>Tambah Paket</DialogTitle>
      <DialogContent>
        <ProductPackageWizard isSubmit={isSubmit} setSubmit={setSubmit} product={product} setProduct={setProduct} handleSnackBar={handleSnackBar} />
      </DialogContent>

      <DialogActions>
        <Button disabled={isSubmit} color='secondary' onClick={handleCancel}>
          {translate('cancelled')}
        </Button>
        <Button disabled={isSubmit} onClick={handleOnSubmit}>
          {translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductPackage;
