import { Grid, LinearProgress, makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { IFilter, IReport } from 'pages/ReportOrderPage';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { EventRounded } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

interface Props {
  partners: IReport<PartnerModel>;
  filter: IFilter;
  tab: number;
  handleChangeFilter: <T>(key: string, value: T) => void;
}

const useStyles = makeStyles({
  container: {
    columnGap: '1em'
  },
  box: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: ' 0px 4px 24px rgba(50, 50, 50, 0.16)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
});

const FilterSection = ({ partners, filter, handleChangeFilter, tab }: Props): JSX.Element => {
  const classes = useStyles();
  const { translate } = useLanguage();

  return (
    <Grid xs={12} container className={classes.container} alignItems='center'>
      <Grid xs={3}>
        <Autocomplete
          inputValue={filter.partnerName}
          onChange={(event, value, reason) => {
            if (reason === 'clear') {
              handleChangeFilter<number>('partnerId', 0);
              handleChangeFilter<string>('partnerName', '');
            }
            if (value) {
              handleChangeFilter<number>('partnerId', value.id);
              handleChangeFilter<string>('partnerName', value.name);
            }
          }}
          loading={partners.isLoading}
          loadingText={<LinearProgress />}
          disableListWrap
          fullWidth
          getOptionLabel={option => option.name}
          options={partners.data}
          PaperComponent={({ children }) => <Grid className={classes.box}>{children}</Grid>}
          renderInput={params => (
            <TextField
              {...params}
              required
              label={tab === 0 ? translate('selectCustomer') : translate('selectPartner')}
              variant='outlined'
              onChange={e => handleChangeFilter<string>('partnerName', e.target.value)}
            />
          )}
        />
      </Grid>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid xs={2}>
          <KeyboardDatePicker
            disableToolbar
            format='dd/MM/yyyy'
            margin='normal'
            required
            fullWidth
            label={translate('from')}
            cancelLabel={translate('cancel')}
            okLabel='Filter'
            value={filter.from}
            onChange={date => {
              if (date) {
                handleChangeFilter<Date>('from', date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            keyboardIcon={<EventRounded fontSize='small' />}
          />
        </Grid>
        <Grid xs={2}>
          <KeyboardDatePicker
            disableToolbar
            format='dd/MM/yyyy'
            margin='normal'
            required
            fullWidth
            label={translate('to')}
            cancelLabel={translate('cancel')}
            okLabel='Filter'
            value={filter.to}
            onChange={date => {
              if (date) {
                handleChangeFilter<Date>('to', date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            keyboardIcon={<EventRounded fontSize='small' />}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid xs={2}>
        <TextField
          select
          value={filter.statusPayment}
          onChange={event => handleChangeFilter<string>('statusPayment', event.target.value)}
          fullWidth
          label={translate('statusPayment')}
        >
          <MenuItem selected key={1} value='' disabled>
            {translate('selectPayment')}
          </MenuItem>
          <MenuItem key={2} value='PAID'>
            {translate('fullyPaid')}
          </MenuItem>
          <MenuItem key={3} value='UNPAID'>
            {translate('unpaid')}
          </MenuItem>
          <MenuItem key={4} value='CANCEL'>
            {translate('cancelled')}
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default FilterSection;
