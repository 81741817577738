import React, { FC, useContext } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Grid, TableBody, TableHead, TableRow, TableCell, TextField, TableContainer, makeStyles, MenuItem } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyInvoice } from 'utils/dummy';
import { PaperCustom } from 'components';
import { Pagination } from '@material-ui/lab';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  invoices: InvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  handleClose?: () => void;
  statusPayment: string;
  invoiceId: string;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles({
  cellStartId: {
    width: '10%'
  },
  cellTable3: {
    width: '13%'
  },
  cellStatus: {
    width: '200px'
  }
});

const InvoiceTable: FC<Props> = props => {
  const { currentUser } = useContext(CurrentUserContext);

  const classes = useStyles();
  const { translate } = useLanguage();
  const {
    isLoadingData,
    invoices,
    order,
    orderBy,
    count,
    indexCollapse,
    openCollapse,
    invoiceId,
    handleClose,
    statusPayment,
    currentPage,
    setStatusPayment,
    setInvoiceId,
    setOrder,
    setOrderBy,
    handleOpenCollapse,
    setCurrentPage
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SUPERVISOR',
    allowed: ['SUPERVISOR']
  });

  return (
    <PaperCustom>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'id', label: 'ID', sort: true },
                { id: 'statusPayment', label: 'STATUS', sort: true },
                { id: 'dueDate', label: translate('invoiceDate'), sort: true },
                { id: 'totalPrice', label: translate('amountDue'), sort: true },
                { id: 'totalPrice', label: translate('totalOutstanding'), sort: true },
                { label: '' }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head' className={classes.cellStartId} colSpan={2}>
                <TextField id='invoiceId' value={invoiceId} onChange={event => setInvoiceId(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable3}>
                <TextField
                  select
                  id='statusPayment'
                  value={statusPayment}
                  onChange={event => setStatusPayment(event.target.value as string)}
                  className={classes.cellStatus}
                >
                  <MenuItem selected key={1} value=''>
                    {translate('selectStatus')}
                  </MenuItem>
                  <MenuItem key={1} value='PAID'>
                    {translate('fullyPaid')}
                  </MenuItem>
                  <MenuItem key={2} value='UNPAID'>
                    Belum Lunas
                  </MenuItem>
                  {!isSuperVisor && (
                    <MenuItem key={4} value='CANCEL'>
                      {translate('cancelled')}
                    </MenuItem>
                  )}
                </TextField>
              </TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow
                  key={index}
                  index={index}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  isLoading={isLoadingData}
                  invoice={dummyInvoice}
                  handleOpenCollapse={handleOpenCollapse(index, 0)}
                />
              ))
            ) : invoices.length > 0 ? (
              invoices.map((value, index) => (
                <BodyRow
                  key={index}
                  index={index}
                  handleClose={handleClose}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  isLoading={isLoadingData}
                  invoice={value}
                  handleOpenCollapse={handleOpenCollapse(index, value.id)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  {translate('dataNotAvailable')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>

      <Grid container justify='center' item xl={12} md={12} sm={12}>
        <Grid item>
          {invoices.length > 0 && (
            <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
          )}
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default InvoiceTable;
