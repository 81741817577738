import React, { FC, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';
import { Container, Grid, makeStyles, Theme, Avatar, Paper, Typography, TextField, Snackbar, Button } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTheme } from '@material-ui/styles';
import axios from 'axios';
import { FORGOT_PASSWORD_URL } from 'constants/url';
import useRouter from 'hooks/useRouter';
import { Alert } from '@material-ui/lab';
import { blue } from '@material-ui/core/colors';
import { PRIMARY } from 'constants/colors';

interface Props {
  user: UserDetailsModel[];
  users: UserDetailsModel | null;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    marginTop: theme.spacing(18),
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  linkButton: {
    textAlign: 'center',

    padding: theme.spacing(1)
  },

  alert: {
    marginBottom: 540
  }
}));

const ForgotPasswordPage: FC<Props> = props => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const { translate } = useLanguage();
  const { history } = useRouter();
  const [email, setEmail] = useState<string>('');
  const [isEmptyEmail, setEmptyEmail] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const onSubmitHandler: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!email) {
      setEmptyEmail(true);

      return;
    }

    resetPassword();
  };

  const resetPassword = async () => {
    setLoading(true);
    setError(false);

    try {
      await axios.post(FORGOT_PASSWORD_URL, { username: email });

      history.push('/');
    } catch (err) {
      setError(true);
    }

    setLoading(false);
  };

  const getHelperText = (): string => {
    if (isEmptyEmail) {
      return 'Please enter your email.';
    }

    if (isError) {
      return 'User does not exist.';
    }

    return '';
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Paper className={classes.root}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Atur Sandi
          </Typography>
          <form className={classes.form} onSubmit={onSubmitHandler} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label={translate('email')}
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={event => setEmail(event.target.value)}
              error={isEmptyEmail || isError}
              helperText={getHelperText()}
            />
            <Button fullWidth variant='contained' color='primary' style={{ margin: theme.spacing(2, 0, 2) }} onClick={handleClick} type='submit'>
              {translate('resetPassword')}
            </Button>
            <Grid container>
              <Grid item xs className={classes.linkButton}>
                <Link to='/' style={{ textDecoration: 'none', color: PRIMARY }}>
                  Kembali Kehalaman Login?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' className={classes.alert}>
          {translate('pleasecheckyouremailLinktoresetpasswordhasbeensent')}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPasswordPage;
