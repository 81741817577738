import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';

import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  makeStyles,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyStokAllItem } from 'utils/dummy';
import BodyRow from './component/BodyRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CloseRounded, SearchRounded } from '@material-ui/icons';
import { GREY, RED } from 'constants/colors';
interface Props {
  isLoadingData: boolean;
  isKeyWord: boolean;
  allStocks: StockAllItemModel[];
  keyWordResource: { productName: string }[];
  order: 'asc' | 'desc';
  keyWord: string;
  orderBy: string;
  searchTemp: string;
  productName: string;
  category: CategoryModel;
  categories: CategoryModel[];
  anchorEl: null | HTMLElement;
  openOptions: boolean;
  stockId: number;
  setStockId: React.Dispatch<React.SetStateAction<number>>;
  handleCloseOption: React.MouseEventHandler;
  handleOpenOption: React.MouseEventHandler;
  handleStockSales: React.MouseEventHandler;
  handleStockDamaged: React.MouseEventHandler;
  loadCategory: boolean;
  setCategory: React.Dispatch<React.SetStateAction<CategoryModel>>;
  setSearchTemp: React.Dispatch<React.SetStateAction<string>>;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
  setKeyWord: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setIsKeyWord: React.Dispatch<React.SetStateAction<boolean>>;
  handleSearchCategory: (value: string) => void;
  indexCollapse: number;
  openCollapse: boolean;
  loadingItem: boolean;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  cellTable: {
    width: '20%'
  },
  containerSugestSearch: {
    position: 'absolute',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    borderRadius: '0 0 0.5em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    top: 48,
    zIndex: 2,
    width: '196%',
    maxHeight: '30vh'
  },
  iconClose: {
    position: 'absolute',
    right: 55,
    top: 15,
    cursor: 'pointer',
    color: RED,
    fontWeight: 500,
    backgroundColor: '#fff',
    borderRadius: '1em',
    border: '2px solid ' + GREY,
    zIndex: 5
  }
});

const AllStockTable: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const {
    isLoadingData,
    allStocks,
    stockId,
    setStockId,
    order,
    orderBy,
    category,
    categories,
    searchTemp,
    setSearchTemp,
    anchorEl,
    openOptions,
    handleCloseOption,
    handleOpenOption,
    setIsKeyWord,
    setKeyWord,
    keyWordResource,
    isKeyWord,
    loadCategory,
    setCategory,
    handleStockSales,
    handleStockDamaged,
    setProductName,
    setOrder,
    setOrderBy,
    indexCollapse,
    openCollapse,
    loadingItem,
    handleOpenCollapse,
    handleSearchCategory
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setProductName(searchTemp);
      setIsKeyWord(false);
    }
  };

  const handleClose = () => {
    setSearchTemp('');
    setIsKeyWord(false);
    setProductName('');
  };
  const handleSearchProduct = () => {
    setIsKeyWord(false);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#' },
              { id: 'productName', label: translate('productName'), sort: true },
              { id: 'CategoryId', label: translate('category'), sort: true, align: 'center' },
              { id: 'saleStock', label: translate('sellingStock'), align: 'center', sort: true },
              { label: translate('stockOnHold'), align: 'center' },
              { label: translate('allWarehouses'), align: 'center' },
              { label: 'Stok Bersih', align: 'center' },
              { label: translate('warehouse'), sort: true, align: 'center' },
              { label: translate('action'), sort: true, align: 'center' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellTable} style={{ position: 'relative' }} colSpan={2}>
              {searchTemp !== '' && <CloseRounded fontSize='small' className={classes.iconClose} onClick={handleClose} />}
              <TextField
                id='salesOrderId'
                placeholder={translate('productName')}
                value={searchTemp}
                onChange={event => {
                  setSearchTemp(event.target.value);
                  setKeyWord(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              {isKeyWord && keyWordResource.length > 0 && (
                <Grid className={classes.containerSugestSearch}>
                  <List>
                    {keyWordResource.map((value, key) => (
                      <ListItem
                        button
                        key={key}
                        onClick={() => {
                          handleSearchProduct();
                          setIsKeyWord(false);
                          setSearchTemp(value.productName);
                          setProductName(value.productName);
                        }}
                      >
                        <SearchRounded style={{ paddingRight: '0.3em', color: 'grey' }} fontSize='default' />
                        <ListItemText primary={value.productName} style={{ color: '#000' }} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <Autocomplete
                id='warehouse'
                fullWidth
                value={category}
                options={categories}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event: any, value: any) => value && setCategory(value)}
                onOpen={e => handleSearchCategory('')}
                loading={loadCategory}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder={translate('category')}
                    onChange={e => handleSearchCategory(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loadCategory && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      )
                    }}
                  />
                )}
              />{' '}
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                index={index}
                isLoading={isLoadingData}
                allStock={dummyStokAllItem}
                handleCloseOption={handleCloseOption}
                handleOpenOption={handleOpenOption}
                stockId={stockId}
                handleStockSales={handleStockSales}
                handleStockDamaged={handleStockDamaged}
                setStockId={setStockId}
                openOptions={openOptions}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, index)}
                loadingItem={loadingItem}
                anchorEl={anchorEl}
              />
            ))
          ) : allStocks && allStocks.length > 0 ? (
            allStocks.map((value, index) => (
              <BodyRow
                index={index}
                isLoading={isLoadingData}
                allStock={value}
                stockId={stockId}
                setStockId={setStockId}
                handleStockSales={handleStockSales}
                handleStockDamaged={handleStockDamaged}
                handleCloseOption={handleCloseOption}
                handleOpenOption={handleOpenOption}
                openOptions={openOptions}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, value.id)}
                loadingItem={loadingItem}
                anchorEl={anchorEl}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                {translate('dataNotAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default AllStockTable;
