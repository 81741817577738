import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import PartnerType from 'typings/enum/PartnerType';
import { getTotal } from 'utils/getTotal';

type ProductCartContextProps = {
  getItemQuantity: (ProductId: number) => number;
  getSalesStock: (ProductId: number) => number;
  changeQuantity: (ProductId: number, quantity: number) => void;
  changeQuantityDetail: (ProductId: number, quantity: number) => void;
  deleteProductCartInformation: () => void;
  deleteProductCart: () => void;
  increaseCartQuantity: (
    ProductId: number,
    productName: string,
    Category: { id: number; name: string },
    customerPrice: number,
    resellerPrice: number,
    typeUnit: string,
    salesStock: number
  ) => void;
  decreaseCartQuantity: (id: number) => void;
  removeFromCart: (id: number) => void;
  setProductCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
  setProductCartInformation: React.Dispatch<React.SetStateAction<CartItemInformation>>;
  productCart: CartItem[];
  cartQuantity: number;
  handleChangeInformation: (key: string, value: any) => void;
  productCartInformation: CartItemInformation;
};

const ProductCartContext = createContext({} as ProductCartContextProps);

export const useProductCart = () => useContext(ProductCartContext);

type ProductCartProviderProps = {
  children: ReactNode;
};

export type CartItem = {
  id: number;
  ProductId: number;
  quantity: number;
  salesStock: number;
  productName: string;
  Category: { id: number; name: string };
  customerPrice: number;
  resellerPrice: number;
  totalPrice: number;
  typeUnit: string;
};
type CartItemInformation = {
  PartnerId: number;
  partnerName: string;
  partnerType: PartnerType.CUSTOMER | PartnerType.SUPPLIER;
  resellerCustomerId: number;
  resellerCustomerName: string;
  kurirId: number;
  courierName: string;
  deduction: number;
  totalDiscount: number;
  resellerCustomer: PartnerModel[];
  shippingFee: number;
  subTotal: number;
  isPlafon: boolean;
  totalPrice: number;
  notes: string;
};

const dummyProductCartInformation: CartItemInformation = {
  PartnerId: 0,
  partnerName: '',
  partnerType: PartnerType.CUSTOMER,
  resellerCustomerId: 0,
  resellerCustomerName: '',
  resellerCustomer: [],
  kurirId: 0,
  courierName: '',
  deduction: 0,
  shippingFee: 0,
  totalDiscount: 0,
  totalPrice: 0,
  isPlafon: false,
  notes: '',
  subTotal: 0
};

export const ProductCartProvider = ({ children }: ProductCartProviderProps) => {
  const [productCart, setProductCart] = useLocalStorage<CartItem[]>('product-cart', []);

  const [productCartInformation, setProductCartInformation] = useLocalStorage<CartItemInformation>(
    'product-cart-information',
    dummyProductCartInformation
  );

  const cartQuantity = productCart.reduce((quantity, item) => quantity + item.quantity, 0);

  const deleteProductCartInformation = () => {
    localStorage.removeItem('product-cart-information');
    setProductCartInformation(dummyProductCartInformation);
  };

  const deleteProductCart = () => {
    localStorage.removeItem('product-cart');
    setProductCart([]);
  };

  const getSubtotal = useMemo(() => getTotal(productCart.map(val => val.totalPrice)), [productCart]);
  const handleChangeInformation = (key: string, value: any) =>
    setProductCartInformation(currItems => {
      return {
        ...currItems,
        [key]: value
      };
    });
  useEffect(
    () =>
      setProductCartInformation(currItems => {
        return {
          ...currItems,
          subTotal: +getSubtotal.toFixed(),
          totalPrice: +(getSubtotal + currItems.shippingFee - currItems.totalDiscount - currItems.deduction).toFixed()
        };
      }),

    [
      getSubtotal,
      productCartInformation.subTotal,
      productCartInformation.deduction,
      productCartInformation.totalDiscount,
      productCartInformation.shippingFee
    ]
  );

  const getItemQuantity = (ProductId: number) => {
    if (productCart.filter(val => val.ProductId === ProductId).length > 0) {
      return productCart.filter(val => val.ProductId === ProductId)[0].quantity;
    } else {
      return 0;
    }
  };

  const getSalesStock = (ProductId: number) => {
    if (productCart.filter(val => val.ProductId === ProductId).length > 0) {
      return productCart.filter(val => val.ProductId === ProductId)[0].salesStock;
    } else {
      return 0;
    }
  };
  const increaseCartQuantity = (
    ProductId: number,
    productName: string,
    Category: { id: number; name: string },
    customerPrice: number,
    resellerPrice: number,
    typeUnit: string,
    salesStock: number
  ) => {
    setProductCart(currItems => {
      if (productCart.find(item => item.ProductId == ProductId) == null) {
        return [
          ...currItems,
          {
            id: 0,
            ProductId,
            productName,
            Category,
            customerPrice,
            salesStock,
            resellerPrice,
            totalPrice: productCartInformation.partnerType.includes(PartnerType.CUSTOMER) ? customerPrice : resellerPrice,
            quantity: 1,
            typeUnit
          }
        ];
      } else {
        return currItems.map(item => {
          if (item.ProductId === ProductId) {
            return {
              ...item,
              quantity: item.quantity + 1,
              totalPrice: productCartInformation.partnerType.includes(PartnerType.CUSTOMER)
                ? item.customerPrice * (item.quantity + 1)
                : productCartInformation.partnerType.includes(PartnerType.RESELLER)
                ? item.resellerPrice * (item.quantity + 1)
                : 0
            };
          } else {
            return item;
          }
        });
      }
    });
  };
  const changeQuantityDetail = (ProductId: number, quantity: number) =>
    setProductCart(currItems => {
      return currItems.map(item => {
        if (item.ProductId === ProductId) {
          return {
            ...item,
            quantity: quantity,
            totalPrice: productCartInformation.partnerType.includes(PartnerType.CUSTOMER)
              ? item.customerPrice * quantity
              : productCartInformation.partnerType.includes(PartnerType.RESELLER)
              ? item.resellerPrice * quantity
              : 0
          };
        } else {
          return item;
        }
      });
    });
  const changeQuantity = (ProductId: number, quantity: number) =>
    setProductCart(currItems => {
      if (quantity === 0) {
        return currItems.filter(item => item.ProductId !== ProductId);
      }
      return currItems.map(item => {
        if (item.ProductId === ProductId) {
          return {
            ...item,
            quantity: quantity,
            totalPrice: productCartInformation.partnerType.includes(PartnerType.CUSTOMER)
              ? item.customerPrice * quantity
              : item.resellerPrice
              ? item.resellerPrice * quantity
              : 0
          };
        } else {
          return item;
        }
      });
    });

  const decreaseCartQuantity = (ProductId: number) =>
    setProductCart(currItems => {
      if (currItems.filter(item => item.ProductId === ProductId)[0].quantity === 1) {
        return currItems.filter(item => item.ProductId !== ProductId);
      } else {
        return currItems.map(item => {
          if (item.ProductId === ProductId) {
            return {
              ...item,
              quantity: item.quantity - 1,
              totalPrice: productCartInformation.partnerType.includes(PartnerType.CUSTOMER)
                ? item.customerPrice * (item.quantity - 1)
                : productCartInformation.partnerType.includes(PartnerType.RESELLER)
                ? item.resellerPrice * (item.quantity - 1)
                : 0
            };
          } else {
            return item;
          }
        });
      }
    });

  const removeFromCart = (ProductId: number) => setProductCart(currItems => currItems.filter(item => item.ProductId !== ProductId));

  return (
    <ProductCartContext.Provider
      value={{
        handleChangeInformation,
        productCartInformation,
        getItemQuantity,
        getSalesStock,
        increaseCartQuantity,
        decreaseCartQuantity,
        setProductCart,
        setProductCartInformation,
        removeFromCart,
        changeQuantity,
        changeQuantityDetail,
        deleteProductCartInformation,
        deleteProductCart,
        productCart,
        cartQuantity
      }}
    >
      {children}
    </ProductCartContext.Provider>
  );
};
