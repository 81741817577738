import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  makeStyles,
  TableContainer,
  CircularProgress,
  Select,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyPurchaseOrder } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  purchaseOrders: PurchaseOrderModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  poId: string;
  statusPo: string;
  name: string;
  setPoId: React.Dispatch<React.SetStateAction<string>>;
  setStatusPo: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  warehouses: WareHouseModel[];
  loadWarehouse: boolean;
  handleSearchWarehouse: (value: string) => void;
  setWarehos: React.Dispatch<React.SetStateAction<number[]>>;
  warehos: number[];
}

const useStyles = makeStyles({
  cellStartId: {
    width: '10%'
  },
  cellTable: {
    width: '17%'
  },
  cellTable3: {
    width: '13%'
  },
  selectStatus: {
    width: '150px'
  }
});

const PurchasePageTable: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const {
    isLoadingData,
    purchaseOrders,
    order,
    orderBy,
    poId,
    setPoId,
    setOrder,
    setOrderBy,
    statusPo,
    setStatusPo,
    name,
    setName,
    handleConfirmationDelete,
    setWarehos,
    warehouses,
    warehos
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'poId', label: 'ID', sort: true },
              { id: 'name', label: translate('supplierName'), sort: true },
              { id: 'statusPo', label: translate('invoiceStatus'), sort: true, align: 'center' },
              { id: 'orderDate', label: 'TANGGAL ORDER', sort: true, align: 'center' },
              { id: 'wareHouseId', label: translate('warehouse'), sort: true },
              { label: '' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellStartId}>
              <TextField placeholder='ID' id='poId' variant='outlined' value={poId} onChange={event => setPoId(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField placeholder={translate('supplierName')} id='name' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellStartId}>
              <TextField
                select
                id='statusOrder'
                value={statusPo}
                onChange={event => setStatusPo(event.target.value as string)}
                className={classes.selectStatus}
              >
                <MenuItem selected key={1} value=''>
                  {translate('selectStatus')}
                </MenuItem>
                <MenuItem key={2} value='PENDING'>
                  {translate('pending')}
                </MenuItem>
                <MenuItem key={3} value='CONFIRMATION'>
                  {translate('confirm')}
                </MenuItem>
              </TextField>
            </TableCellHead>
            <TableCellHead></TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <Select
                id='demo-mutiple-checkbox'
                margin='dense'
                multiple
                variant='outlined'
                fullWidth
                value={warehos}
                onChange={e => setWarehos(e.target.value as number[])}
                renderValue={selected =>
                  `${warehouses
                    .filter(value => (selected as number[]).includes(value.id as number))
                    .map(value => value.name)
                    .join(',')
                    .substr(0, 8)}...`
                }
              >
                {warehouses.map(value => (
                  <MenuItem key={value.id} value={value.id}>
                    <Checkbox color='primary' checked={warehos.indexOf(value.id) > -1} />
                    <ListItemText primary={value.name} />
                  </MenuItem>
                ))}
              </Select>
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow key={index} isLoading={isLoadingData} purchaseOrder={dummyPurchaseOrder} onDelete={handleConfirmationDelete(0)} />
            ))
          ) : purchaseOrders && purchaseOrders.length > 0 ? (
            purchaseOrders.map((value, index) => (
              <BodyRow key={index} isLoading={isLoadingData} purchaseOrder={value} onDelete={handleConfirmationDelete(value.id)} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                {translate('dataNotAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default PurchasePageTable;
