import React, { FC, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button,
  Chip
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { RED, BLACK, GREEN, PRIMARY } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusOrder, TableRowCollapse } from 'components';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { DeleteOutline } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ItemStatus from 'typings/enum/ItemStatus';
import useRouter from 'hooks/useRouter';
interface Props {
  salesOrder: SalesOrderModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
  openCollapse: boolean;
  handleOpenCollapse: React.MouseEventHandler;
  index: number;
  indexCollapse: number;
  loadingItem: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: RED,
      width: 30
    },
    tableCell: {
      width: 100
    },
    tableCellAction: {
      width: 30
    },
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    customerName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      lineHeight: '22px'
    },
    pending: {
      color: BLACK
    },
    reject: {
      color: RED
    },
    confirm: {
      color: PRIMARY
    },
    success: {
      color: GREEN
    }
  })
);

const BodyRow: FC<Props> = props => {
  const { salesOrder, isLoading, onDelete, openCollapse, indexCollapse, handleOpenCollapse, index, loadingItem } = props;
  const classes = useStyles();
  const { translate } = useLanguage();
  const { history } = useRouter();

  const handleLink = (id: number) => {
    history.push(`/review-stock/${id}`);
  };

  const handleStatus = (item: SalesOrderItemModel) => {
    if (item.status === ItemStatus.CONFIRM) {
      return (
        <Typography variant='body2' className={classes.confirm}>
          Disetujui
        </Typography>
      );
    } else if (item.status === ItemStatus.REJECT) {
      return (
        <Typography variant='body2' className={classes.reject}>
          Ditolak
        </Typography>
      );
    } else if (item.status === ItemStatus.HAS_INVOICE) {
      return (
        <Typography variant='body2' className={classes.success}>
          Invoice Telah <br /> Dibuat
        </Typography>
      );
    } else {
      return (
        <Typography variant='body2' className={classes.pending}>
          Menunggu
        </Typography>
      );
    }
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart onClick={() => handleLink(salesOrder.id)}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : '#' + salesOrder.orderNumber}
        </TableCellStart>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {salesOrder.partnerName || '-'}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)} align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : <StatusOrder status={salesOrder.statusOrder} />}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell} align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrder.zoneName || '-'}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrder.salesName || '-'}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : format(new Date(salesOrder.orderDate), 'dd MMM yyyy')}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={salesOrder.totalOrder || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
      </TableRowCustom>
    </Fragment>
  );
};

export default BodyRow;
