import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Grid, TableBody, TableHead, TableRow, TableCell, TableContainer, Typography } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyInvoice } from 'utils/dummy';
import { PaperCustom } from 'components';
import { Pagination } from '@material-ui/lab';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  invoices: InvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  statusPayment: string;
  invoiceId: string;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loadingItem: boolean;
}

const UserTable: FC<Props> = props => {
  const { translate } = useLanguage();
  const {
    isLoadingData,
    invoices,
    order,
    orderBy,
    count,
    indexCollapse,
    openCollapse,
    currentPage,

    setOrder,
    setOrderBy,
    handleOpenCollapse,
    setCurrentPage,
    loadingItem
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <PaperCustom>
      <Typography variant='h5'>{translate('purchaseHistory')}</Typography>
      <br />
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#', sort: true, align: 'right' },
                { id: translate('partners'), label: translate('partners'), sort: true, align: 'center' },
                { id: 'statusPayment', label: 'STATUS', sort: true, align: 'center' },
                { id: 'paymentDue', label: 'TGL PELUNASAN', sort: true, align: 'center' },
                { id: 'totalPrice', label: translate('amountDue'), sort: true, align: 'left' },
                { label: '' }
              ]}
            />
          </TableHead>
          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow
                  key={index}
                  loadingItem={loadingItem}
                  index={index}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  isLoading={isLoadingData}
                  invoice={dummyInvoice}
                  handleOpenCollapse={handleOpenCollapse(index, 0)}
                />
              ))
            ) : invoices.length > 0 ? (
              invoices.map((value, index) => (
                <BodyRow
                  loadingItem={loadingItem}
                  key={index}
                  index={index}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  isLoading={isLoadingData}
                  invoice={value}
                  handleOpenCollapse={handleOpenCollapse(index, value.id)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  {translate('dataNotAvailable')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>

      <Grid container justify='center' item xl={12} md={12} sm={12}>
        <Grid item>
          {invoices.length > 0 && (
            <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
          )}
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default UserTable;
