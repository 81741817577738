import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Button, Switch, FormControlLabel } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import useRouter from 'hooks/useRouter';

interface Props {
  isLoadingData: boolean;
  isUpdateStatus: boolean;
  user: UserDetailsModel;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  onEditUser: React.MouseEventHandler;
  handleStatus: (id: number) => void;
}

const BodyRow: FC<Props> = props => {
  const { isLoadingData, isUpdateStatus, user, onEditUser, handleStatus } = props;
  const { history } = useRouter();

  const handleLink = () => {
    history.push('/users/detail', { id: user.id });
  };

  return (
    <TableRowCustom>
      <TableCellStart onClick={handleLink}>{isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : user.id}</TableCellStart>
      <TableCellMiddle onClick={handleLink}>{isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : user.type}</TableCellMiddle>
      <TableCellMiddle onClick={handleLink}>
        {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : user.firstName}
      </TableCellMiddle>
      <TableCellMiddle onClick={handleLink}>{isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : user.name}</TableCellMiddle>
      <TableCellMiddle onClick={handleLink}>{isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : user.email}</TableCellMiddle>
      <TableCellMiddle onClick={handleLink}>
        {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : user.contactNumber}
      </TableCellMiddle>

      <TableCellMiddle>
        {isLoadingData ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <FormControlLabel
            control={<Switch disabled={isUpdateStatus} color='primary' checked={user.isActive} onChange={() => handleStatus(user.id)} />}
            label={user.isActive ? 'Aktif' : 'Non Aktif'}
          />
        )}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoadingData ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Button variant='text' size='small' color='primary' onClick={onEditUser}>
            Edit
          </Button>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
