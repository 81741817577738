import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Theme, Card, CardActionArea, CardContent, CardMedia, InputBase } from '@material-ui/core';
import useRouter from 'hooks/useRouter';
import { GREEN, PRIMARY, PRIMARY_SECONDARY, YELLOW } from 'constants/colors';
import { Skeleton } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import ROUTE_PATHS from 'constants/routePaths';

interface Props {
  handleOpenImage: (imagePath: string) => void;
  productResource: ProductModel;
  productLoading: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '0.5rem',
    position: 'relative',
    transition: 'transform 10s ease-in',
    '&:hover': {
      boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.007), 0 4px 7px 0 ' + PRIMARY_SECONDARY,
      transform: 'scale(1,1)'
    }
  },

  media: {
    height: 200,
    display: 'grid',
    placeItems: 'center',
    padding: '0 1.2rem',
    backgroundColor: '#e0e0e0',
    '& .MuiCardMedia-img ': {
      objectFit: 'cover'
    }
  },

  containerNotif: {
    position: 'absolute',
    display: 'flex',
    top: 10,
    right: 10,
    flexDirection: 'column',
    gap: '0.6em',
    zIndex: 1
  },
  isNew: {
    background: PRIMARY,
    border: '1px solid #fff',
    padding: '0.1em 0.3em',
    fontSize: '0.8rem',
    color: '#fff',
    borderRadius: '0.4em'
  },
  isReStock: {
    background: GREEN,
    border: '1px solid #fff',
    padding: '0.1em 0.5em',
    fontSize: '0.8rem',
    color: '#fff',
    borderRadius: '0.4em'
  },
  price: {
    color: YELLOW
  },
  fontSize: {
    fontSize: '.9rem'
  }
}));
const CardItem = ({ productResource, handleOpenImage, productLoading }: Props) => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { history } = useRouter();

  return (
    <Grid xs={12} lg={11} xl={11}>
      <Card className={classes.root}>
        {
          <Grid xs={12} className={classes.containerNotif}>
            {productResource.new && (
              <Typography className={classes.isNew} align='center' variant='h6'>
                {translate('new')}
              </Typography>
            )}
            {productResource.reStock && (
              <Typography className={classes.isReStock} align='center' variant='h6'>
                {translate('sortByRestock')}
              </Typography>
            )}
          </Grid>
        }
        <CardActionArea>
          {productLoading ? (
            <Skeleton animation='wave' variant='rect' width={300} height={200} />
          ) : (
            <CardMedia
              className={classes.media}
              image={(productResource.ProductImage && productResource.ProductImage.url) || ''}
              title='gambar-produk'
              onClick={() => !productLoading && handleOpenImage((productResource.ProductImage && productResource.ProductImage.url) || '')}
            >
              {!(productResource.ProductImage && productResource.ProductImage.url) && (
                <Typography variant='h6' style={{ color: '#000000' }}>
                  {productResource.productName}
                </Typography>
              )}
            </CardMedia>
          )}

          {productLoading ? (
            <CardContent>
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
            </CardContent>
          ) : (
            <CardContent onClick={() => history.push(`${ROUTE_PATHS.Product.path}/${productResource.id}`)}>
              <Typography
                gutterBottom
                component='h6'
                variant='h6'
                style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
              >
                {productResource.productName}
              </Typography>
              <Grid xs={12} container>
                <Grid xs={6}>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    {translate('code')}
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    {translate('category')}
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    {translate('sellingStock')}
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    {translate('customersPrice')}
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    {translate('resellersPrice')}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={classes.fontSize}>: {productResource.productCode}</Typography>
                  <Typography className={classes.fontSize}>: {productResource.Category!.name}</Typography>
                  <Typography className={classes.fontSize}>: {`${productResource.salesStock} ${productResource.typeUnit}`}</Typography>
                  <Typography className={clsx(classes.price, classes.fontSize)}>
                    :{' '}
                    <NumberFormat
                      value={productResource.customerPrice ? productResource.customerPrice : 0}
                      prefix={'Rp'}
                      thousandSeparator={true}
                      displayType='text'
                    />
                  </Typography>
                  <Typography className={clsx(classes.price, classes.fontSize)}>
                    :{' '}
                    <NumberFormat
                      value={productResource.resellerPrice ? productResource.resellerPrice : 0}
                      prefix={'Rp'}
                      thousandSeparator={true}
                      displayType='text'
                    />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CardItem;
