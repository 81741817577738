import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableContainer,
  makeStyles,
  MenuItem,
  Theme,
  Checkbox,
  Select,
  ListItemText
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummySalesOrder } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  loadingItem: boolean;
  salesOrders: SalesOrderModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  salesName: string;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  statusOrder: string;
  setStatusOrder: React.Dispatch<React.SetStateAction<string>>;
  salesOrderId: string;
  setSalesOrderId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  indexCollapse: number;
  openCollapse: boolean;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650
  },
  cellStartId: {
    width: '10%'
  },
  cellTable: {
    width: '17%'
  },
  cellTable3: {
    width: '13%'
  }
}));

const SalesOrderTable: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const {
    isLoadingData,
    salesOrders,
    order,
    orderBy,
    name,
    setName,
    statusOrder,
    setStatusOrder,
    salesOrderId,
    setSalesOrderId,
    setOrder,
    setOrderBy,
    handleConfirmationDelete,
    salesName,
    setSalesName,
    indexCollapse,
    openCollapse,
    loadingItem,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'salesOrderId', label: translate('orderNo'), sort: true },
              { id: 'name', label: translate('customerName'), sort: true },
              { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
              { id: 'zone', label: 'RUTE', sort: true, align: 'center' },
              { id: 'nameSales', label: 'NAMA SALES', sort: true },
              { id: 'orderDate', label: translate('orderDate'), sort: true },
              { id: 'totalAmount', label: translate('totalPrice'), sort: true },
              { label: '' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellStartId}>
              <TextField
                id='salesOrderId'
                placeholder='No Order'
                autoComplete='off'
                value={salesOrderId}
                onChange={event => setSalesOrderId(event.target.value)}
              />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='name' placeholder={translate('name')} value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead align='center' variant='head' className={classes.cellTable3}>
              <TextField
                select
                id='statusOrder'
                value={translate('pending')}
                autoComplete='off'
                onChange={event => setStatusOrder(event.target.value as string)}
              >
                <MenuItem key={1} value={translate('pending')}>
                  {translate('pending')}
                </MenuItem>
                <MenuItem key={2} value='PENDING'>
                  Peninjauan
                </MenuItem>
              </TextField>
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='salesName' placeholder='Sales' value={salesName} onChange={event => setSalesName(event.target.value)} />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                salesOrder={dummySalesOrder}
                onDelete={handleConfirmationDelete(0)}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, 0)}
                loadingItem={loadingItem}
              />
            ))
          ) : salesOrders && salesOrders.length > 0 ? (
            salesOrders.map((value, index) => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                salesOrder={value}
                onDelete={handleConfirmationDelete(value.id)}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, value.id)}
                loadingItem={loadingItem}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align='center'>
                {translate('dataNotAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default SalesOrderTable;
