export const PRIMARY_GRADIENT = 'linear-gradient(to left,  #0258AF, #014496)';
export const PRIMARY = '#0258AF';
export const PRIMARY_SECONDARY = '#014496';
export const NEUTRAL = '#A4A4A4';
export const NEUTRAL_SECONDARY = '#7C7C7C';
export const RED = '#EB5757';
export const WHITE = '#FFFFFF';
export const WHITE_2 = '#F2F2F2';
export const BLACK = '#333333';
export const BLACK_2 = '#2E384D';
export const BLACK_3 = '#222B45';
export const GREY = '#E0E0E0';
export const GREY_SECONDARY = '#B6B6B6';
export const GRAY_3 = '#828282';
export const GRAY_4 = '#6D6D6D';
export const GREY_6 = '#F2F2F2';
export const GREEN_LIGHT = '#06ec06';
export const BACKGROUND = '#F7F9FC';
export const RED_SECONDARY = '#D42222';
export const WHITESEARCH = '#F7F9FC';
export const PENDING_COLOR = '#F2C94C';
export const CONFIRMATION_COLOR = '#27AE60';
export const BLUE = '#1877f2';
export const ORANGE = '#EFA001';
export const GREEN = '#27AE60';
export const YELLOW = '#EFA001';
