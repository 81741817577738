import React, { FC, useState, Fragment } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios from 'axios';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, makeStyles } from '@material-ui/core';
import { PRODUCT_BASE_URL } from 'constants/url';
import { dummyPurchaseItem, dummyProduct } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import PurchaseOrderItem from './components/PurchaseOrderItem';

interface Props {
  purchaseOrderItem: PurchaseOrderItemModel[];
  wareHouse: number;
  isUpdate: boolean;
  loadUpdate: boolean;
  zone: number[];
  setPurchaseOrderItem: React.Dispatch<React.SetStateAction<PurchaseOrderItemModel[]>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteItem: (index: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  addProductCell: {
    border: 'none'
  }
});

const PurchaseOrderCreateTable: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { purchaseOrderItem, isUpdate, loadUpdate, wareHouse, zone, setPurchaseOrderItem, setUpdate, handleDeleteItem } = props;
  const [totalItem, setTotalItem] = useState<number>(0);
  const [typeUnit, setTypeUnit] = useState<string>('');
  const [product, setProduct] = useState<ProductModel>(dummyProduct);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [productMessage, setProductMessage] = useState<string>('');
  const [totalItemMessage, setTotalItemMessage] = useState<string>('');

  const validation = () => {
    let valid = true;
    resetValidation();

    if (!product || product.id === 0) {
      setProductMessage(translate('productcannotbeempty'));
      valid = false;
    }

    if (!totalItem || totalItem === 0) {
      setTotalItemMessage(translate('quantitycannotbeempty'));
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setProductMessage('');
    setTotalItemMessage('');
  };

  const handleAddItem = () => {
    if (!validation()) {
      return;
    }

    setPurchaseOrderItem([
      ...purchaseOrderItem,
      {
        ...dummyPurchaseItem,
        typeUnit,
        totalItem,
        ProductId: product.id,
        productName: product.productName,
        productCode: product.productCode
      }
    ]);
    reset();
  };

  const handleUpdateItem = (index: number): React.MouseEventHandler => () => {
    const item = purchaseOrderItem[index];
    setUpdate(true);
    setProduct({
      id: item.ProductId,
      productName: item.productName || '',
      productCode: item.productCode || '',
      sellingPrice: 0,
      purchasePrice: 0,
      typeUnit: '',
      totalStock: 0,
      salesStock: 0,
      customerPrice: 0,
      resellerPrice: 0,
      description: '',
      isReminder: false,
      minimumStock: 0
    });
    setCurrentIndex(index);
    setTotalItem(item.totalItem);
    setTypeUnit(item.typeUnit);
  };

  const handleSaveUpdate = (index: number): React.MouseEventHandler => () => {
    if (!validation()) {
      return;
    }
    const item: PurchaseOrderItemModel = {
      ...purchaseOrderItem[index],
      typeUnit,
      totalItem,
      ProductId: product.id,
      productName: product.productName,
      productCode: product.productCode
    };

    purchaseOrderItem[index] = item;
    setPurchaseOrderItem(purchaseOrderItem);
    setUpdate(false);
    setCurrentIndex(-1);
    reset();
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
    setCurrentIndex(-1);
    reset();
  };

  const reset = () => {
    setProduct(dummyProduct);
    setTotalItem(0);
  };

  const handleSearchProduct = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('isProductPackage', 'false');
    params.append('route', zone.join(','));
    params.append('WareHouseId', wareHouse.toString());
    setLoadProduct(true);
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`);
      setProducts(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadProduct(false);
    }
  };

  return (
    <Fragment>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{translate('productName')}</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadUpdate
              ? [1, 2, 3, 4].map(value => (
                  <BodyRow
                    key={value}
                    index={value}
                    currentIndex={currentIndex}
                    item={dummyPurchaseItem}
                    isLoading={loadUpdate}
                    handleUpdateItem={handleUpdateItem}
                    handleDeleteItem={handleDeleteItem}
                  />
                ))
              : purchaseOrderItem.length > 0 &&
                purchaseOrderItem
                  .filter(value => !value.isDeleted)
                  .map((value, index) =>
                    isUpdate && index === currentIndex ? (
                      <PurchaseOrderItem
                        product={product}
                        products={products}
                        totalItem={totalItem}
                        typeUnit={typeUnit}
                        isUpdate={isUpdate}
                        index={index}
                        currentIndex={currentIndex}
                        loadProduct={loadProduct}
                        productMessage={productMessage}
                        totalItemMessage={totalItemMessage}
                        hasWarehouse={wareHouse === 0}
                        setProduct={setProduct}
                        setTotalItem={setTotalItem}
                        setTypeUnit={setTypeUnit}
                        handleSearchProduct={handleSearchProduct}
                        handleSaveUpdate={handleSaveUpdate}
                        handleCloseUpdate={handleCloseUpdate}
                      />
                    ) : (
                      <BodyRow
                        key={index}
                        index={index}
                        currentIndex={currentIndex}
                        item={value}
                        isLoading={loadUpdate}
                        handleUpdateItem={handleUpdateItem}
                        handleDeleteItem={handleDeleteItem}
                      />
                    )
                  )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table size='small'>
          <TableBody>
            {!isUpdate && (
              <PurchaseOrderItem
                product={product}
                products={products}
                totalItem={totalItem}
                typeUnit={typeUnit}
                index={0}
                currentIndex={0}
                loadProduct={loadProduct}
                isUpdate={isUpdate}
                productMessage={productMessage}
                totalItemMessage={totalItemMessage}
                hasWarehouse={wareHouse === 0}
                setProduct={setProduct}
                setTotalItem={setTotalItem}
                setTypeUnit={setTypeUnit}
                handleSearchProduct={handleSearchProduct}
                handleSaveUpdate={handleSaveUpdate}
                handleCloseUpdate={handleCloseUpdate}
              />
            )}

            {!isUpdate && (
              <TableRow>
                <TableCell align='right' colSpan={3} className={classes.addProductCell}>
                  <Button variant='text' onClick={handleAddItem}>
                    {translate('addProduct')}
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default PurchaseOrderCreateTable;
