import React, { FC, useState, useEffect } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Button, Container, Grid, makeStyles, Theme, Typography, ButtonGroup } from '@material-ui/core';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import DetailOrder from './components/DetailOrder';
import { Page, StandardConfirmationDialog, Breadcrumb, FormPinDialog } from 'components';
import { PRIMARY, WHITE } from 'constants/colors';
import { GET_PURCHASE_ORDER_DETAIL_BASE_URL, COMPANY_BASE_URL, PURCHASE_ORDER_BASE_URL, GET_PURCHASE_ORDER_ITEM_BASE_URL } from 'constants/url';
import { dummyPurchaseOrder, dummyCompany } from 'utils/dummy';
import DeleteIcon from '@material-ui/icons/Delete';
import useConfirmationPin from 'hooks/useConfirmationPin';
import RefreshIcon from '@material-ui/icons/Refresh';
import Skeleton from '@material-ui/lab/Skeleton';
import ROUTE_PATHS from 'constants/routePaths';

const useStyles = makeStyles((theme: Theme) => ({
  ButtonNew: {
    color: PRIMARY,
    backgroundColor: WHITE
  }
}));

const PurchaseOrderDetailPage: FC = () => {
  const { history, match } = useRouter();
  // eslint-disable-next-line
  const params = match.params.id;
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();

  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderModel>(dummyPurchaseOrder);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);

  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);

  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: confirmationDelete,
    isCompletePin: isComplete,
    handleOnDelete: deletePurchaseOrder,
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const fetchData = async () => {
    setIsLoadingData(true);

    Promise.all([
      axios.get(GET_PURCHASE_ORDER_DETAIL_BASE_URL(Number(params))),
      axios.get(GET_PURCHASE_ORDER_ITEM_BASE_URL(Number(params))),
      axios.get(COMPANY_BASE_URL)
    ])
      .then(result => {
        const order = result[0].data.data;
        setPurchaseOrder(order);
        setId(order.id);

        const item = result[1].data.data;
        setPurchaseOrder(prevState => ({ ...prevState, PurchaseOrderItem: item }));

        const cmpny = result[2].data.data;
        setCompany(cmpny);
      })
      .catch(error => console.log('error:', error))
      .finally(() => setIsLoadingData(false));
  };

  const printPdf = async () => {
    try {
      const { data } = await axios.get(`${PURCHASE_ORDER_BASE_URL}/print/${id}`, { responseType: 'blob' });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow && window.focus) {
        newwindow.focus();
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
    if (isDelete) {
      setDelete(false);
      history.push(ROUTE_PATHS.PurchaseOrder.path);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleDelete = () => {
    setConfirmationDelete(true);
  };

  async function deletePurchaseOrder() {
    try {
      await axios.delete(GET_PURCHASE_ORDER_DETAIL_BASE_URL(id));
      handleSnackBar(true, 'success', 'Order pembelian berhasil dihapus.');
      setDelete(true);
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'success', 'Order pembelian berhasil dihapus.');
    } finally {
      setConfirmationDelete(false);
    }
  }

  const handleLink = () => {
    history.push(`${ROUTE_PATHS.PurchaseOrder.path}/edit`, { id: purchaseOrder.id });
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title='Purchase Order'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'>Pembelian</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Breadcrumb />
          </Grid>

          <Grid container item lg={6} sm={6} md={6} xs={6} justify='flex-end'>
            {isLoadingData ? (
              <Skeleton variant='text' color='inherit' width='50%' />
            ) : (
              <ButtonGroup size='small'>
                <Button onClick={() => fetchData()} className={classes.ButtonNew}>
                  <RefreshIcon />
                </Button>
                <Button disabled={isLoadingData} onClick={printPdf} className={classes.ButtonNew}>
                  {translate('print')}
                </Button>
                {purchaseOrder.statusOrder === 'PENDING' && (
                  <Button className={classes.ButtonNew} onClick={handleLink}>
                    {translate('editData')}
                  </Button>
                )}
                <Button onClick={handleDelete} className={classes.ButtonNew}>
                  <DeleteIcon />
                </Button>
              </ButtonGroup>
            )}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={2} justify='space-between'>
          <DetailOrder purchaseOrder={purchaseOrder} company={company} isLoadingData={isLoadingData} />
        </Grid>
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          noCancelButton={true}
        />
        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={translate('deletingData')}
          message={'Apakah kamu yakin akan menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={handleOpenConfirmation}
          isLoading={loadingDelete}
        />
        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />
      </Container>
    </Page>
  );
};

export default PurchaseOrderDetailPage;
