import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableContainer, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { PaperCustom } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  sales: UserDetailsModel;
  isLoading: boolean;
}
const SalesDetail: FC<Props> = props => {
  const { translate } = useLanguage();
  const { sales, isLoading } = props;
  const routesName: any = [];
  sales.SalesRoute &&
    sales.SalesRoute.map(route => {
      if (route.Zone) {
        routesName.push(route.Zone.name);
      }
    });

  const zoneNames = routesName ? routesName.join(', ') : '-';

  return (
    <PaperCustom>
      <Typography variant='h5'>{translate('personalInformation')}</Typography>
      <br />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Level</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No. Handphone</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.contactNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rute</TableCell>
              <TableCell align='right'>
                {isLoading ? <Skeleton variant='text' width='100%' /> : sales.SalesRoute && <Typography>{zoneNames}</Typography>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PaperCustom>
  );
};

export default SalesDetail;
