import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { PaperCustom } from 'components';
import clx from 'clsx';
import { Grid, makeStyles, Theme, Typography, Table, TableRow, TableCell, TableBody, Hidden, TableContainer } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import Logo from 'images/logo-blue.png';
import ListProduct from '../ListProduct';
import { BLACK } from 'constants/colors';
import { format } from 'date-fns';

interface Props {
  purchaseOrder: PurchaseOrderModel;
  isLoadingData: boolean;
  company: CompanyModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',
    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },
  img: {
    width: '236px',
    height: '236'
  },
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: '4px 59px 4px 4px',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {
    marginRight: 65
  },
  cellFinalLeft: {
    marginLeft: 65
  },
  logo: {
    objectFit: 'cover',
    height: '8em'
  }
}));

const DetailOrder: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { purchaseOrder, isLoadingData, company } = props;

  return (
    <Grid container direction='row' item lg={12} sm={12} md={12} xs={12}>
      <PaperCustom>
        <Grid container direction='row' spacing={2} item lg={12} sm={12} md={12} xs={12}>
          <Hidden smDown xsDown>
            <Grid item lg={7} sm={12} md={7} xs={12}>
              <img src={Logo} alt='company' className={classes.logo} />
            </Grid>
          </Hidden>

          <Grid container item lg={5} sm={12} md={5} xs={12} justify='flex-end'>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell align='right' colSpan={2} className={classes.cellOrder}>
                    <Typography variant='h1' className={classes.titleOrder}>
                      Order Pembelian
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>{translate('purchaseOrderNo')}</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : purchaseOrder.orderNumber}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Tanggal Order</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : purchaseOrder.orderDate ? (
                      format(new Date(purchaseOrder.orderDate), 'dd-MM-yyyy')
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>{translate('deliveryOrderDate')}</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : purchaseOrder.orderDate ? (
                      format(new Date(purchaseOrder.travelDate), 'dd-MM-yyyy')
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>{translate('logisticFee')}</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <NumberFormat value={purchaseOrder.logisticPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <Typography variant='h5' align='left'>
              {translate('companyName')}
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.address}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : `${translate('phone')}: ` + company.phoneNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (company.cellPhoneNumber || '-')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <Typography variant='h5' align='right'>
              {translate('purchaseOrderFrom')}
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='right' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : purchaseOrder.supplierName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : `${translate('phone')}: ` + (purchaseOrder.phoneNumber || '-')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (purchaseOrder.cellPhoneNumber || '-')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (purchaseOrder.email || '-')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <ListProduct isLoadingData={isLoadingData} purchaseOrderItem={purchaseOrder.PurchaseOrderItem ? purchaseOrder.PurchaseOrderItem : []} />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='body2'>
              {translate('totalItem')} : {purchaseOrder.PurchaseOrderItem ? purchaseOrder.PurchaseOrderItem.length : ''}
            </Typography>
            <br />
          </Grid>
          <Grid container item lg={6} sm={12} md={6} xs={12}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Typography variant='h6'>{translate('descriptions')}</Typography>
              <div dangerouslySetInnerHTML={{ __html: purchaseOrder.notes || '-' }}></div>
            </Grid>
          </Grid>
        </Grid>
      </PaperCustom>
    </Grid>
  );
};

export default DetailOrder;
