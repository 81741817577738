import React, { FC, useState, useEffect } from 'react';
import { Button, makeStyles, Grid, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { GREEN, WHITE } from 'constants/colors';
import { format } from 'date-fns';
import { useLanguage } from 'contexts/LanguageContext';

interface Props {
  openCalendarFilter: boolean;
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    padding: '8px',
    overflow: 'auto'
  },
  datePicker: {
    width: 250
  }
});

const DateRangeFilter: FC<Props> = props => {
  const classes = useStyles();
  const { translate } = useLanguage();
  const { openCalendarFilter, startDate, endDate, setStartDate, setEndDate, handleClose } = props;

  const [selectedStartDate, setSelectedStartDate] = useState<string>(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<string>(endDate);
  const [startErrorMessage, setStartErrorMessage] = useState<string>('');
  const [endErrorMessage, setEndErrorMessage] = useState<string>('');

  const handleClearError = () => {
    setStartErrorMessage('');
    setEndErrorMessage('');
  };

  const handleSubmit = () => {
    validation(new Date(selectedStartDate), new Date(selectedEndDate));

    if (startErrorMessage || endErrorMessage) {
      return;
    }

    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    handleClose();
  };

  const handleStartDateChange = (selectedStartDate: Date | null) => {
    if (selectedStartDate) {
      setSelectedStartDate(format(selectedStartDate, 'yyyy-MM-dd'));
    }

    validation(selectedStartDate, new Date(selectedEndDate));
  };

  const handleEndDateChange = (selectedEndDate: Date | null) => {
    if (selectedEndDate) {
      setSelectedEndDate(format(selectedEndDate, 'yyyy-MM-dd'));
    }

    validation(new Date(selectedStartDate), selectedEndDate);
  };

  const validation = (startDateSelected: Date | null, endDateSelected: Date | null) => {
    handleClearError();

    if (!startDateSelected) {
      setStartErrorMessage('Tanggal mulai tidak boleh kosong.');
      return;
    }

    if (!endDateSelected) {
      setEndErrorMessage('Tanggal akhir tidak boleh kosong.');
      return;
    }

    if (startDateSelected > endDateSelected) {
      setStartErrorMessage('Tanggal mulai tidak boleh lebih dari tgl akhir');
      setEndErrorMessage('Tanggal akhir tidak boleh kurang dari tgl mulai');
      return;
    }
  };

  useEffect(() => {
    handleClearError();
  }, [openCalendarFilter]);

  return (
    <Dialog open={openCalendarFilter} onClose={handleClose} maxWidth='sm' classes={{ paper: classes.dialogContent }}>
      <DialogTitle>{translate('dateFilter')}</DialogTitle>
      <DialogContent style={{ padding: '8px' }}>
        <Grid container justify='space-between' spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              label={translate('from')}
              className={classes.datePicker}
              value={selectedStartDate}
              onChange={e => handleStartDateChange(new Date(e.target.value))}
              type='date'
              required
              error={startErrorMessage !== ''}
              helperText={startErrorMessage}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              label={translate('to')}
              className={classes.datePicker}
              value={selectedEndDate}
              onChange={e => handleEndDateChange(new Date(e.target.value))}
              type='date'
              required
              error={endErrorMessage !== ''}
              helperText={endErrorMessage}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          {translate('exit')}
        </Button>
        <Button disabled={startErrorMessage !== '' && endErrorMessage === ''} onClick={handleSubmit}>
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeFilter;
