import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';
interface Props {
  isCanceledInvoice: boolean;
  isLoadingData: boolean;
  invoiceItem: InvoiceItemModel[];
  isRequestEdit: boolean;
  item: { id: number; notes: string; isRequestEdit: boolean }[];
  indexCollapse: number;
  indexEdit: number;
  type: string;
  loadingEdit: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  onDeleteItem: (id: number) => void;
  onUpdate: () => void;
  individualEdit: (item: InvoiceItemModel) => void;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
  handleEdit: (index: number, type: string) => void;
  handleRequestNote: (item: { id: number; notes: string }) => void;
  handleRequestCheck: (item: { id: number; notes: string; isRequestEdit: boolean }) => void;
}

const ListProduct: FC<Props> = props => {
  const { translate } = useLanguage();
  const {
    isCanceledInvoice,
    isLoadingData,
    invoiceItem,
    isRequestEdit,
    indexCollapse,
    indexEdit,
    item,
    type,
    isAdmin,
    isSuperAdmin,
    loadingEdit,
    onDeleteItem,
    onUpdate,
    individualEdit,
    handleEdit,
    handleRequestNote,
    handleRequestCheck,
    handleOpenCollapse
  } = props;

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('productName')}</TableCell>
            <TableCell align='center'>{translate('unitPrice')}</TableCell>
            <TableCell align='center'>{translate('sellingPrice')}</TableCell>
            <TableCell align='center'>{translate('quantity')}</TableCell>
            <TableCell align='center'>Diskon</TableCell>
            <TableCell align='center'>{translate('totalPrice')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItem.length > 0 &&
            invoiceItem.map((value, index) => (
              <BodyRow
                key={index}
                isCanceledInvoice={isCanceledInvoice}
                isRequestEdit={isRequestEdit}
                isLoading={isLoadingData}
                invoiceItem={value}
                indexCollapse={indexCollapse}
                indexEdit={indexEdit}
                loadingEdit={loadingEdit}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                onDeleteItem={onDeleteItem}
                individualEdit={individualEdit}
                handleEdit={handleEdit}
                onUpdate={onUpdate}
                handleOpenCollapse={handleOpenCollapse(value.id)}
                index={index}
                item={item}
                type={type}
                handleRequestNote={handleRequestNote}
                handleRequestCheck={handleRequestCheck}
              />
            ))}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
