import React, { FC, Fragment, useEffect, useState, useContext } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import PurchaseOrderCreateTable from './components/PurchaseOrderCreateTable';
import ReactQuill from 'react-quill';
import NumberFormatMask from 'components/NumberFormatMask';
import { Grid, Container, Typography, makeStyles, Button, TextField, CircularProgress, FormHelperText, MenuItem } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import {
  PURCHASE_ORDER_BASE_URL,
  PARTNER_BASE_URL,
  GET_PURCHASE_ORDER_DETAIL_BASE_URL,
  GET_PURCHASE_ORDER_NUMBER_BASE_URL,
  WAREHOUSE_BASE_URL,
  GET_PURCHASE_ORDER_ITEM_BASE_URL
} from 'constants/url';
import { format } from 'date-fns';
import { dummyPartner } from 'utils/dummy';
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import ROUTE_PATHS from 'constants/routePaths';

const useStyles = makeStyles({
  editorNotes: {
    marginBottom: '10px'
  }
});

const PurchaseOrderCreatePage: FC = () => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { history, location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;

  const { currentUser } = useContext(CurrentUserContext);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [travelDate, setTravelDate] = useState<Date | null>(new Date());
  const [logisticPrice, setLogisticPrice] = useState<number>(0);

  const [orderDateMessage, setOrderDateMessage] = useState<string>('');
  const [travelDateMessage, setTravelDateMessage] = useState<string>('');

  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [loadPartner, setLoadPartner] = useState<boolean>(false);

  const [wareHouse, setWareHouse] = useState<number>(0);
  const [wareHouses, setWareHouses] = useState<WareHouseModel[]>([]);
  const [warehouseMessage, setWareHouseMessage] = useState<string>('');
  const [loadWareHouse, setLoadWareHouse] = useState<boolean>(false);

  const [id, setId] = useState<number>(0);
  const [purchaseOrderItem, setPurchaseOrderItem] = useState<PurchaseOrderItemModel[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [loadUpdate, setLoadUpdate] = useState<boolean>(false);
  const [purchaseOrderItemMessage, setPurchaseOrderItemMessage] = useState<string>('');
  const [orderNumberLoad, setOrderNumberLoad] = useState<boolean>(false);

  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];

  const fetchData = async () => {
    setLoadUpdate(true);

    Promise.all([axios.get(GET_PURCHASE_ORDER_DETAIL_BASE_URL(params.id)), axios.get(GET_PURCHASE_ORDER_ITEM_BASE_URL(params.id))])
      .then(result => {
        const order = result[0].data.data;

        setId(order.id);
        setOrderDate(new Date(order.orderDate));
        setTravelDate(new Date(order.travelDate));
        setLogisticPrice(order.logisticPrice);
        setOrderNumber(order.orderNumber);
        setNotes(order.notes);
        setPartner(prevState => ({ ...prevState, id: order.PartnerId, name: order.supplierName, partnerType: 'SUPPLIER' }));
        setPartners([partner]);
        setWareHouse(order.WareHouseId);

        const item = result[1].data.data;
        setPurchaseOrderItem(item);
      })
      .catch(error => {
        console.log('error :', error);
      })
      .finally(() => {
        setLoadUpdate(false);
      });
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('partnerType', 'SUPPLIER');
    setLoadPartner(true);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
    }
  };

  const fetchWareHouse = async () => {
    setLoadWareHouse(true);
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWareHouses(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWareHouse(false);
    }
  };

  const handleOrderNumber = async () => {
    setOrderNumberLoad(true);
    try {
      const { data } = await axios.get(GET_PURCHASE_ORDER_NUMBER_BASE_URL);
      setOrderNumber(data.data.orderNumber);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setOrderNumberLoad(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push(`${ROUTE_PATHS.PurchaseOrder.path}/${id}`);
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(PURCHASE_ORDER_BASE_URL, {
        id,
        orderNumber,
        orderDate,
        PartnerId: partner!.id,
        totalItem: purchaseOrderItem.filter(value => !value.isDeleted).length,
        items: purchaseOrderItem,
        WareHouseId: wareHouse,
        notes,
        travelDate,
        logisticPrice
      });

      setId(data.data.id);
      handleSnackBar(true, 'success', translate('purchaseOrederCreatedSuccessfully'));
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Order Pembelian gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (!partner || partner.id === 0) {
      setPartnerMessage('Supllier tidak boleh kosong.');
      valid = false;
    }

    if (!orderDate) {
      setOrderDateMessage('Tanggal Order tidak boleh kosong.');
      valid = false;
    }

    if (!travelDate) {
      setTravelDateMessage('Tanggal Surat Jalan tidak boleh kosong.');
      valid = false;
    }

    if (purchaseOrderItem.length === 0 || !purchaseOrderItem) {
      setPurchaseOrderItemMessage('Item tidak boleh kosong.');
      valid = false;
    }

    if (wareHouse === 0) {
      setWareHouseMessage('Gudang Tidak Boleh Kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setPartnerMessage('');
    setOrderDateMessage('');
    setPurchaseOrderItemMessage('');
    setWareHouseMessage('');
    setTravelDateMessage('');
  };

  const handleCancel = () => {
    history.push(ROUTE_PATHS.PurchaseOrder.path);
  };

  const handleDeleteItem = (index: number): React.MouseEventHandler => () => {
    let item: PurchaseOrderItemModel = purchaseOrderItem[index];

    if (item.id === 0) {
      setPurchaseOrderItem(purchaseOrderItem.filter(({}, idx) => idx !== index));
    } else {
      item = {
        ...item,
        isDeleted: true,
        deletedAt: new Date()
      };
      purchaseOrderItem[index] = item;
      setPurchaseOrderItem([...purchaseOrderItem]);
    }
  };

  useEffect(() => {
    setPurchaseOrderItemMessage(wareHouse === 0 ? translate('pleaseselectwarehousebeforeaddproduct') : '');
  }, [wareHouse]);

  useEffect(() => {
    if (!params) {
      handleOrderNumber();
      return;
    }
    fetchData();
  }, []);

  useEffect(() => {
    fetchWareHouse();
  }, []);

  return (
    <Page title='Pembelian'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pembelian </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h5'> {translate('invoiceStatus')} </Typography>
            </Grid>

            <Grid container direction='row' justify='space-between' spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='orderNumber'
                  fullWidth
                  label='No Order'
                  placeholder='No Order Pembelian'
                  value={orderNumber}
                  onChange={e => setOrderNumber(e.target.value)}
                  disabled
                  InputProps={{
                    endAdornment: (loadUpdate || orderNumberLoad) && <CircularProgress color='inherit' size={20} />
                  }}
                />
              </Grid>
              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  id='orderDate'
                  required
                  fullWidth
                  type='date'
                  label={translate('itemReceivedDate')}
                  placeholder={translate('itemReceivedDate')}
                  value={orderDate && format(new Date(orderDate), 'yyyy-MM-dd')}
                  onChange={e => setOrderDate(new Date(e.target.value))}
                  error={orderDateMessage !== ''}
                  helperText={orderDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  id='travelDate'
                  required
                  fullWidth
                  type='date'
                  label={translate('deliveryOrderDate')}
                  value={travelDate && format(new Date(travelDate), 'yyyy-MM-dd')}
                  onChange={e => setTravelDate(new Date(e.target.value))}
                  error={travelDateMessage !== ''}
                  helperText={travelDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <Autocomplete
                  id='partner'
                  value={partner}
                  options={partners}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  onChange={(event: any, value: any) => value && setPartner(value)}
                  onOpen={e => handleSearchPartner('')}
                  loading={loadPartner}
                  onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                    if (reason === 'clear') {
                      setPartner(dummyPartner);
                      setPartners([dummyPartner]);
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label={translate('selectSupplier')}
                      onChange={e => handleSearchPartner(e.target.value)}
                      error={partnerMessage !== ''}
                      helperText={partnerMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadPartner && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  fullWidth
                  id='logisticPrice'
                  label={translate('logisticFee')}
                  value={logisticPrice}
                  onChange={e => setLogisticPrice(+e.target.value)}
                  InputProps={{
                    inputComponent: NumberFormatMask as any
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  fullWidth
                  select
                  id='warehouse'
                  value={wareHouse}
                  helperText={warehouseMessage}
                  error={warehouseMessage !== ''}
                  onChange={event => setWareHouse(+event.target.value)}
                >
                  <MenuItem key={0} value={0} disabled>
                    {translate('selectWarehouse')}
                  </MenuItem>

                  {wareHouses.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xl={12} md={12} sm={12}>
              <FormHelperText error={purchaseOrderItemMessage !== ''}>{purchaseOrderItemMessage}</FormHelperText>
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <Typography variant='body2'>
                {translate('totalItem')} : {purchaseOrderItem.length}
              </Typography>
              <PurchaseOrderCreateTable
                zone={salesRoute}
                purchaseOrderItem={purchaseOrderItem}
                isUpdate={isUpdate}
                loadUpdate={loadUpdate}
                wareHouse={wareHouse}
                setPurchaseOrderItem={setPurchaseOrderItem}
                setUpdate={setUpdate}
                handleDeleteItem={handleDeleteItem}
              />
            </Grid>

            <Grid item xl={8} lg={8} md={8} sm={8}>
              <ReactQuill
                id='notes'
                value={notes}
                onChange={(value: any) => setNotes(value)}
                placeholder={translate('notes')}
                className={classes.editorNotes}
              />
            </Grid>

            <Grid container direction='row' alignItems='center' alignContent='center' justify='flex-end' spacing={1} item xl={4} md={4} sm={12}>
              <Grid item>
                <Button onClick={handleCancel} color='secondary'>
                  {translate('cancelled')}
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={isSubmit || isUpdate || purchaseOrderItem.length === 0} onClick={handleOnSubmit}>
                  {isSubmit ? <CircularProgress color='inherit' size={20} /> : translate('save')}
                </Button>
              </Grid>
            </Grid>

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default PurchaseOrderCreatePage;
