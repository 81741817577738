import React, { FC, useEffect, useState } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, Button, Tooltip, makeStyles, Theme } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, PaperCustom, FormPinDialog } from 'components';
import { PURCHASE_ORDER_BASE_URL, WAREHOUSE_BASE_URL } from 'constants/url';
import { AddBox } from '@material-ui/icons';
import { PRIMARY, GREEN, WHITE } from 'constants/colors';
import { format, startOfMonth } from 'date-fns';
import useRouter from 'hooks/useRouter';
import PurchasePageTable from './components/PurchasePageTable';
import Pagination from '@material-ui/lab/Pagination';
import useConfirmationPin from 'hooks/useConfirmationPin';
import RefreshIcon from '@material-ui/icons/Refresh';
import DateRangeFilter from 'components/DateRangeFilter';
import CalendarIcon from '@material-ui/icons/EventNote';
import useDebounced from 'hooks/useDebounced';
import { dummyWareHouse } from 'utils/dummy';
import ROUTE_PATHS from 'constants/routePaths';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const PurchaseOrderPage: FC = () => {
  const classes = useStyles();
  const { languageCode, translate } = useLanguage();
  const { history } = useRouter();
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [poId, setPoId] = useState<string>('');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [statusPo, setStatusPo] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([]);
  const [warehouse, setWarehouse] = useState<number[]>([]);
  const [loadWarehouse, setLoadWarehouse] = useState<boolean>(false);

  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: confirmationDelete,
    isCompletePin: isComplete,
    handleOnDelete: deletePurchase,
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const fetchData = async () => {
    setIsLoadingData(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (statusPo) {
        params.append('statusOrder', statusPo);
      }

      if (poId) {
        params.append('keyword', poId);
      }

      if (name) {
        params.append('partnerName', name);
      }

      if (!name && !poId) {
        params.append('startDate', startDate);
        params.append('endDate', endDate);
      }

      if (warehouse.length > 0) {
        params.append('WareHouse', warehouse.join(',') || '');
      }

      params.append('orderBy', orderBy);
      params.append('ordered', order);
      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PURCHASE_ORDER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setPurchaseOrders(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const onRefresh = () => {
    setOrder('desc');
    setStartDate(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    setEndDate(format(new Date(), 'yyyy-MM-dd'));
    setPoId('');
    setStatusPo('');
    setName('');
    setCurrentPage(1);
    fetchData();
  };

  const handleSearchWarehouse = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadWarehouse(true);
    try {
      const { data } = await axios.get(`${WAREHOUSE_BASE_URL}?${params.toString()}`);
      setWarehouses(data.data);
      setLoadWarehouse(false);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWarehouse(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  async function deletePurchase() {
    try {
      await axios.delete(`${PURCHASE_ORDER_BASE_URL}/${selectedId}`);
      setPurchaseOrders(purchaseOrders.filter(value => value.id !== selectedId));
      setConfirmationDelete(false);
      handleSnackBar(true, 'success', 'Order pembelian berhasil dihapus.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Order pembelian gagal dihapus.');
    } finally {
      setLoadingDelete(false);
    }
  }

  const handleLink = () => {
    history.push(`${ROUTE_PATHS.PurchaseOrder.path}${ROUTE_PATHS.Add.path}`);
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleDateRange = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelTokenSource.cancel('No longer latest query');
  }, [orderBy, order, statusPo, poId, currentPage, warehouse, name, startDate, endDate]);

  useEffect(() => {
    handleSearchWarehouse('');
  }, []);

  return (
    <Page title='Order Pembelian Supplier'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pembelian </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Refresh'>
                <Button onClick={onRefresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <Typography variant='h6'>{`${translate('showing')} ${total || 0} ${translate('purchaseOrder')} (${from || 0} - ${to || 0} ${translate(
                'from'
              )} ${total || 0})`}</Typography>
              {startDate && endDate && (
                <Typography color='primary'>
                  {translate('fromDate')} {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                </Typography>
              )}
            </Grid>

            <Grid item container justify='flex-end' alignItems='center' spacing={1} xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='inherit' onClick={event => handleDateRange(event)}>
                    <CalendarIcon />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item>
                <Button disabled={isLoadingData} onClick={handleLink}>
                  <AddBox fontSize='small' /> &nbsp; Order Pembelian
                </Button>
              </Grid>
            </Grid>

            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <PurchasePageTable
                isLoadingData={isLoadingData}
                purchaseOrders={purchaseOrders}
                order={order}
                orderBy={orderBy}
                poId={poId}
                setPoId={setPoId}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                statusPo={statusPo}
                setStatusPo={setStatusPo}
                name={name}
                setName={setName}
                handleConfirmationDelete={handleConfirmationDelete}
                warehos={warehouse}
                warehouses={warehouses}
                setWarehos={setWarehouse}
                loadWarehouse={loadWarehouse}
                handleSearchWarehouse={handleSearchWarehouse}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12}>
              {purchaseOrders && purchaseOrders.length > 0 && (
                <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
              )}
            </Grid>
          </Grid>
        </PaperCustom>

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={handleOpenConfirmation}
          isLoading={loadingDelete}
        />

        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />
      </Container>
    </Page>
  );
};

export default PurchaseOrderPage;
