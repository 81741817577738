import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { Button, CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import { BLUE } from 'constants/colors';
const useStyles = makeStyles((theme: Theme) => ({
  cellContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '0.5em'
  },
  link: {
    color: BLUE,
    cursor: 'pointer'
  }
}));
interface Props {
  handleLink: React.MouseEventHandler;
  handleDeliveryPrint: React.MouseEventHandler;
  deliveryOrder: DeliveryOrders;
  isDeliveryPrint: { isLoading: boolean; id: number };
}

const BodyTable: FC<Props> = (props): JSX.Element => {
  const { deliveryOrder, handleLink, isDeliveryPrint, handleDeliveryPrint } = props;
  const classes = useStyles();
  const { translate } = useLanguage();
  return (
    <TableRowCustom key={deliveryOrder.id}>
      <TableCellStart onClick={handleLink} className={classes.link}>
        #{deliveryOrder.deliveryNumber}
      </TableCellStart>
      <TableCellMiddle>{deliveryOrder.partnerName}</TableCellMiddle>
      <TableCellMiddle>{format(new Date(deliveryOrder.createdAt), 'dd MMM yyyy')}</TableCellMiddle>
      <TableCellMiddle>{`${deliveryOrder.totalKoli} ${translate('crate')}`}</TableCellMiddle>
      <TableCellMiddle>{deliveryOrder.totalInvoice}</TableCellMiddle>
      <TableCellEnd>
        <Grid xs={12} className={classes.cellContainer}>
          {deliveryOrder.kurirName || '-'}
          <Button color='primary' variant='outlined' disabled={isDeliveryPrint.isLoading} onClick={handleDeliveryPrint}>
            {isDeliveryPrint.isLoading && isDeliveryPrint.id === deliveryOrder.id ? <CircularProgress size={20} color='primary' /> : 'Print SJ'}
          </Button>
        </Grid>
      </TableCellEnd>
    </TableRowCustom>
  );
};
export default BodyTable;
