import { Grid, Typography } from '@material-ui/core';
import { PaperCustom } from 'components';
import React, { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import NumberFormat from 'react-number-format';
import SalesOrderInfo from './components/SalesOrderInfo';
import SalesOrderList from './components/SalesOrderList';
interface Props {
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  company: CompanyModel;
  isLoadingData: boolean;
  handleOpenImage: (imagePath: string) => void;
}

const Content: FC<Props> = props => {
  const { translate } = useLanguage();
  const { salesOrder, isLoadingData, company, salesOrderItem, handleOpenImage } = props;

  return (
    <Grid container direction='row' item lg={12} sm={12} md={12} xs={12}>
      <PaperCustom>
        <Grid container direction='row' spacing={2} item lg={12} sm={12} md={12} xs={12}>
          <SalesOrderInfo salesOrder={salesOrder} isLoadingData={isLoadingData} company={company} />
          <SalesOrderList
            salesOrderItem={salesOrderItem}
            salesOrder={salesOrder}
            isLoadingData={isLoadingData}
            company={company}
            handleOpenImage={handleOpenImage}
          />
          <Grid xs={12} container>
            <Grid xs={6}>
              <Grid xs={8}>
                <Typography variant='h6'>Catatan : </Typography>
                <Typography>{salesOrder.notes}</Typography>
              </Grid>
            </Grid>
            <Grid xs={6} container justify='flex-end'>
              <Grid xs={6} container justify='space-between'>
                <Grid xs={6}>
                  <Typography>Subtotal</Typography>
                  <Typography>{translate('deliveryFee')}</Typography>
                  <Typography>Total Diskon</Typography>
                  <Typography>{translate('otherDeductions')}</Typography>
                  <Typography variant='h6'>{translate('totalAmount')}</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography align='right'>
                    <NumberFormat value={salesOrder.subTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />{' '}
                  </Typography>
                  <Typography align='right'>
                    <NumberFormat value={salesOrder.shippingFee} prefix={'Rp'} thousandSeparator={true} displayType='text' />{' '}
                  </Typography>
                  <Typography align='right'>
                    <NumberFormat value={salesOrder.totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />{' '}
                  </Typography>
                  <Typography align='right'>
                    <NumberFormat value={salesOrder.deduction} prefix={'Rp'} thousandSeparator={true} displayType='text' />{' '}
                  </Typography>
                  <Typography variant='h6' align='right'>
                    <NumberFormat value={salesOrder.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />{' '}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaperCustom>
    </Grid>
  );
};

export default Content;
